import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { IconButton } from "@mui/material";

// Icons
import Icon from "@mdi/react";
import { mdiArrowExpandAll } from "@mdi/js";

import WebMapArcgis from "./WebMapArcgis";

const ProjectLocation = ({ itemLeft }) => {
  const [expandMap, setExpandMap] = useState(false);

  const goToArcgisDashboard = () => {
    const url = process.env.REACT_APP_ARCGIS_URL;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <React.Fragment>
      <Card as={motion.div} variants={itemLeft} className='flex-fill'>
        <Card.Header className='pb-0'>
          <h4 className='text-uppercase' style={{ fontWeight: 700 }}>
            Peta Negeri Sabah
          </h4>
        </Card.Header>
        <Card.Body>
          <div className='position-relative' style={{ height: 520 }}>
            {!expandMap && (
              <div
                className='position-absolute esri-widget--button esri-widget esri-interactive'
                style={{ top: "16.5%", left: 15, zIndex: 9999 }}
                onClick={goToArcgisDashboard}
              >
                <Icon
                  path={mdiArrowExpandAll}
                  size='20px'
                  // onClick={() => setExpandMap(true)}
                />
              </div>
            )}

            <WebMapArcgis />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default ProjectLocation;
