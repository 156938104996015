import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import CurrencyInput from "react-currency-input-field";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";

// components
import UploadDocument from "./UploadDocument";

// redux
import { updateMicroProject } from "../../../../redux/slices/micro-project/microProjectSlice";
import ActionButton from "./ActionButton";
import ApprovalButton from "./ApprovalButton";
import ConfirmSend from "./ConfirmSend";
import ReturnButton from "./ReturnButton";
import ReturnApplicationForm from "./ReturnApplicationForm";
import ReturnApplication from "./ReturnApplication";

const ProjectInformation = ({ sendNotification }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeParams = useParams();

  const { id, ministry, type } = routeParams;

  const [isDraft, setIsDraft] = useState(true);
  const [dunOptions, setDunOptions] = useState([]);
  const [mainProjectValue, setMainProjectValue] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [hideSendButton, setHideSendButton] = useState(false);
  const [hideApproveButton, setHideApproveButton] = useState(false);
  const [hideReturnButton, setHideReturnButton] = useState(false);
  const [showConfirmApplication, setShowConfirmApplication] = useState(false);
  const [showReturnApplicationForm, setShowReturnApplicationForm] =
    useState(false);
  const [showReturnApplicationButton, setShowReturnApplicationButton] =
    useState(false);

  const {
    user,
    mainProjectOptions,
    districtOptions,
    parliamentOptions,
    project,
  } = useSelector((state) => ({
    mainProjectOptions: state.utilsReducer.dropdownItems.mainProjects,
    districtOptions: state.utilsReducer.dropdownItems.districtItems,
    parliamentOptions: state.utilsReducer.dropdownItems.parliamentItems,
    project: state.microProjectReducer.microProject,
    user: state.userReducer.user,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      main_project: project?.main_project || null,
      district: project?.district || null,
      parliament: project?.parliament || null,
      state_legislative_assembly: project?.state_legislative_assembly || null,
      name: project?.name || "",
      location: project?.location || "",
      site_status: project?.site_status || "",
      justifications: project?.justifications || "",
      cost_approved: project?.cost_approved || "",
      cost_requirement: project?.cost_requirement || "",
      remarks: project?.remarks || "",
      reference_no: project?.reference_no || "",
      district_office_reference_no: project?.district_office_reference_no || "",
      approved_application_remarks: project?.approved_application_remarks
        ? JSON.parse(project?.approved_application_remarks)
        : "",
    },
    onSubmit: async (values) => {
      let mainProjectId = null;

      if (ministry === "11") {
        mainProjectId = mainProjectValue?.id;
      } else {
        mainProjectId = values.main_project?.id;
      }

      if (isDraft) {
        const newValue = {
          id: id,
          main_project_id: mainProjectId,
          district_id: values.district.id || null,
          state_legislative_assembly_id:
            values.state_legislative_assembly?.id || null,
          parliament_id: values.parliament?.id || null,
          name: values.name,
          location: values.location,
          benefits: values.benefits,
          site_status: values.site_status,
          justifications: values.justifications,
          cost_requirement: values.cost_requirement,
          cost_approved: values.cost_approved,
          reference_no: values.reference_no,
          district_office_reference_no: values.district_office_reference_no,
          remarks: values.remarks,
          application_status: 1,
          application_stages: 1,
        };
        await dispatch(updateMicroProject(newValue));
        sendNotification();
      } else {
        const isDistrictLevel = user?.user_access_to_district;

        let application_status = 1;
        let application_stages = 1;

        if (!isEmpty(isDistrictLevel)) {
          if (
            project.application_status === 1 &&
            project.application_stages === 1
          ) {
            application_status = 2;
            application_stages = 2;
          }
          if (
            project.application_status === 2 &&
            project.application_stages === 2
          ) {
            application_status = 3;
            application_stages = 3;
          }
        } else {
          application_status = 4;
          application_stages = 3;
        }

        const newValue = {
          id: id,
          main_project_id: mainProjectId,
          district_id: values.district.id || null,
          state_legislative_assembly_id:
            values.state_legislative_assembly?.id || null,
          parliament_id: values.parliament?.id || null,
          name: values.name,
          location: values.location,
          benefits: values.benefits,
          site_status: values.site_status,
          justifications: values.justifications,
          cost_requirement: values.cost_requirement,
          cost_approved: values.cost_approved,
          reference_no: values.reference_no,
          district_office_reference_no: values.district_office_reference_no,
          remarks: values.remarks,
          application_status: application_status,
          application_stages: application_stages,
          approved_application_remarks: values.approved_application_remarks,
        };

        await dispatch(updateMicroProject(newValue));
        if (type === "kelulusan") {
          navigate(`/projek-mikro/status-senarai-projek`);
        } else {
          navigate(`/projek-mikro/senarai-projek`);
        }
      }
    },
  });

  const handleSelectMainProject = (value) => {
    formik.setFieldValue("main_project", value);
  };

  const handleSelectDistrict = (value) => {
    formik.setFieldValue("district", value);
  };

  const handleSelectDun = (value) => {
    formik.setFieldValue("state_legislative_assembly", value);
  };

  const handleSelectParliament = (value) => {
    formik.setFieldValue("parliament", value);
  };

  const handleSaveDraft = async (e) => {
    e.preventDefault();
    setIsDraft(true);
    formik.handleSubmit();

    return false;
  };

  const handleSendApplication = () => {
    setShowConfirmApplication(true);
  };

  const handleShowReturnApplicationForm = () => {
    setShowReturnApplicationForm(true);
  };

  useEffect(() => {
    const options = parliamentOptions
      ?.flatMap((item) => item.state_legislative_assemblies)
      ?.map((item) => ({ ...item, label: item.name, value: item.name }));

    setDunOptions(options);
  }, [parliamentOptions]);

  useEffect(() => {
    const mainProject = mainProjectOptions?.find(
      (item) => item?.code === "0001",
    );

    setMainProjectValue(mainProject);
  }, [ministry, mainProjectOptions]);

  useEffect(() => {
    if (isEmpty(project)) return false;

    const stages = project?.application_stages;
    const status = project?.application_status;

    if (stages === 1 && status === 1) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    if (stages === 3 && status === 3) {
      setHideSendButton(false);
    }

    if (stages === 1 && status === 1) {
      setHideSendButton(true);
    }

    if (
      stages === 2 &&
      status === 2 &&
      user?.roles?.includes("projek_mikro_status_senarai")
    ) {
      setHideSendButton(true);
    }

    if (
      stages === 3 &&
      status === 3 &&
      !isEmpty(user?.user_access_to_ministries)
    ) {
      setHideReturnButton(true);
    }

    if (stages === 3 && status === 3) {
      setHideApproveButton(true);
    }

    if (status !== 5) {
      setShowReturnApplicationButton(true);
    }

    if (status === 1) {
      setShowReturnApplicationButton(false);
    }

    if (status === 4) {
      setShowReturnApplicationButton(false);
    }

    if (
      status === 3 &&
      stages === 3 &&
      !isEmpty(user?.user_access_to_district)
    ) {
      setShowReturnApplicationButton(false);
    }

    const district = project?.district;
    handleSelectDistrict(district);
  }, [project]);

  useEffect(() => {
    if (isEmpty(user?.user_access_to_district)) return false;

    handleSelectDistrict(user?.user_access_to_district);
  }, [user]);

  return (
    <React.Fragment>
      <ConfirmSend
        show={showConfirmApplication}
        hide={() => setShowConfirmApplication(false)}
        confirm={(e) => {
          e.preventDefault();
          setIsDraft(false);
          formik.handleSubmit();
          return false;
        }}
      />

      <ReturnApplicationForm
        show={showReturnApplicationForm}
        onHide={() => {
          setShowReturnApplicationForm(false);
        }}
      />

      <Card className='flex-fill w-100'>
        <Form
          autoComplete='off'
          onSubmit={(e) => {
            e.preventDefault();
            setIsDraft(false);
            formik.handleSubmit();
            return false;
          }}
        >
          <Card.Body>
            <h4>Maklumat Projek</h4>

            <Form.Group className='mb-3'>
              <Form.Label>Projek Utama</Form.Label>
              {ministry === "11" && (
                <Form.Control
                  name='main_project'
                  type='text'
                  defaultValue={mainProjectValue?.value}
                  disabled
                />
              )}

              {ministry === "19" && (
                <Select
                  name='main_project'
                  className='react-select-container'
                  classNamePrefix='react-select'
                  isSearchable
                  isClearable
                  placeholder='Pilih Projek Utama'
                  options={mainProjectOptions}
                  value={formik.values?.main_project}
                  onChange={(value) => handleSelectMainProject(value)}
                  noOptionsMessage={() => "Tiada pilihan"}
                  isDisabled={isDisabled}
                />
              )}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Daerah</Form.Label>

              <Select
                name='district'
                className='react-select-container'
                classNamePrefix='react-select'
                isSearchable
                isClearable
                placeholder='Pilih Daerah'
                options={districtOptions}
                value={formik.values?.district}
                onChange={(value) => handleSelectDistrict(value)}
                noOptionsMessage={() => "Tiada pilihan"}
                isDisabled
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Parlimen</Form.Label>
              <Select
                name='parliament'
                className='react-select-container'
                classNamePrefix='react-select'
                isSearchable
                isClearable
                placeholder='Pilih Parlimen'
                options={parliamentOptions}
                value={formik.values?.parliament}
                onChange={(value) => handleSelectParliament(value)}
                noOptionsMessage={() => "Tiada pilihan"}
                isDisabled={isDisabled}
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Dewan Undangan Negeri (DUN)</Form.Label>
              <Select
                name='state_legislative_assembly'
                className='react-select-container'
                classNamePrefix='react-select'
                isSearchable
                isClearable
                placeholder='Pilih DUN'
                options={dunOptions}
                value={formik.values?.state_legislative_assembly}
                onChange={(value) => handleSelectDun(value)}
                noOptionsMessage={() => "Tiada pilihan"}
                isDisabled={isDisabled}
              />
            </Form.Group>

            <Row>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>No. Rujukan</Form.Label>
                  <Form.Control
                    type='text'
                    name='reference_no'
                    value={formik?.values?.reference_no}
                    onChange={formik.handleChange}
                    onBlur={formik?.handleBlur}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>No. Rujukan Pejabat Daerah</Form.Label>
                  <Form.Control
                    type='text'
                    name='district_office_reference_no'
                    value={formik?.values?.district_office_reference_no}
                    onChange={formik.handleChange}
                    onBlur={formik?.handleBlur}
                    disabled={isDisabled}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className='mb-3'>
              <Form.Label>Nama Projek</Form.Label>
              <Form.Control
                as='textarea'
                style={{ minHeight: 90, maxHeight: 150 }}
                name='name'
                value={formik?.values?.name}
                onChange={formik.handleChange}
                onBlur={formik?.handleBlur}
                disabled={isDisabled}
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Justifikasi Keperluan Projek</Form.Label>
              <Form.Control
                as='textarea'
                style={{ minHeight: 90, maxHeight: 150 }}
                name='justifications'
                value={formik?.values?.justifications}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isDisabled}
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Lokasi / Alamat Projek</Form.Label>
              <Form.Control
                as='textarea'
                style={{ minHeight: 90, maxHeight: 150 }}
                name='location'
                value={formik.values?.location}
                onChange={formik?.handleChange}
                onBlur={formik.handleBlur}
                disabled={isDisabled}
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Status Tapak Projek</Form.Label>
              <Form.Control
                as='textarea'
                style={{ minHeight: 90, maxHeight: 150 }}
                name='site_status'
                value={formik?.values?.site_status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isDisabled}
              />
            </Form.Group>

            <Row>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>Kos Projek Yang Dipohon (RM)</Form.Label>
                  <Form.Control
                    placeholder='0.00'
                    as={CurrencyInput}
                    min={0}
                    max={250000}
                    autoComplete='off'
                    decimalsLimit={2}
                    name='cost_requirement'
                    value={formik.values?.cost_requirement}
                    onChange={(e) => {
                      const numberString = e.target.value;
                      const number =
                        parseFloat(numberString.replace(/,/g, "")) || 0;

                      if (number <= 250000) {
                        formik.setFieldValue("cost_requirement", number);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    disabled={isDisabled}
                  />
                </Form.Group>
              </Col>
              <Col>
                {!isEmpty(user?.user_access_to_ministries) && (
                  <>
                    <Form.Group className='mb-3'>
                      <Form.Label>Kos Projek Yang Diluluskan (RM)</Form.Label>
                      <Form.Control
                        placeholder='0.00'
                        as={CurrencyInput}
                        min={0}
                        autoComplete='off'
                        decimalsLimit={2}
                        name='cost_approved'
                        value={formik.values?.cost_approved}
                        onChange={(e) => {
                          const numberString = e.target.value;
                          const number =
                            parseFloat(numberString.replace(/,/g, "")) || 0;
                          formik.setFieldValue("cost_approved", number);
                        }}
                        onBlur={formik.handleBlur}
                        disabled={project?.application_status === 4}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Catatan Kelulusan</Form.Label>
                      <Form.Control
                        name='approved_application_remarks'
                        type='text'
                        as='textarea'
                        style={{ height: 80, maxHeight: 120 }}
                        value={formik.values?.approved_application_remarks}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={project?.application_status === 4}
                      />
                    </Form.Group>
                  </>
                )}
              </Col>
            </Row>

            <Form.Group className='mb-3'>
              <Form.Label>Catatan</Form.Label>
              <Form.Control
                as='textarea'
                style={{ minHeight: 90, maxHeight: 150 }}
                name='remarks'
                value={formik?.values?.remarks}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isDisabled}
              />
            </Form.Group>

            <UploadDocument isDisabled={isDisabled} />
            {!isEmpty(project?.return_application_remarks) && (
              <ReturnApplication />
            )}
          </Card.Body>

          <Card.Footer className='d-flex justify-content-between gap-3'>
            <div>
              {showReturnApplicationButton && (
                <ReturnButton
                  returnApplication={handleShowReturnApplicationForm}
                />
              )}
            </div>
            <div className='d-flex gap-3'>
              {type === "kemas-kini" && hideSendButton && (
                <ActionButton
                  handleSaveDraft={handleSaveDraft}
                  sendApplication={handleSendApplication}
                />
              )}
              {type === "kelulusan" && (
                <ApprovalButton
                  user={user}
                  hideSendButton={hideSendButton}
                  hideApproveButton={hideApproveButton}
                />
              )}
            </div>
          </Card.Footer>
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default ProjectInformation;
