import React, { useEffect } from "react";
import { Badge, Card, Table } from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { FiveColumns } from "../../../../../../components/common/tableLoading";
import { PROGRESS_STATUS } from "../../../../../../constants";

// redux
import {
  setPage,
  setPerPage,
  setKeywords,
} from "../../../../../../redux/slices/dashboard/dashboardSlice";

// components
import ProjectPagination from "./ProjectPagination";
import Filters from "./Filters";

const Projects = ({ itemLeft }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { projects } = useSelector((state) => ({
    projects: state.dashboardReducer.overall.projects,
  }));
  const { isLoading, data, page, perPage, keywords } = projects;

  const filteredContent = data?.filter((item) => {
    let filteredPass = true;

    if (keywords) {
      const lowerCaseName = item.name.toLowerCase();
      const lowerCaseKeywords = keywords.toLowerCase();
      filteredPass = filteredPass && lowerCaseName.includes(lowerCaseKeywords);
    }

    return filteredPass;
  });

  const indexOfLastPage = page * perPage;
  const indexOfFirstPage = indexOfLastPage - perPage;
  const tableContent = filteredContent?.slice(
    indexOfFirstPage,
    indexOfLastPage,
  );

  // Change page
  const paginate = (pageNumber) => {
    dispatch(setPage(pageNumber));
  };

  const handleViewProject = (id) => {
    navigate(`/profil-projek/${id}`);
  };

  return (
    <React.Fragment>
      <Card as={motion.div} variants={itemLeft}>
        <Card.Header className='pb-0 d-flex justify-content-between'>
          <Filters />
        </Card.Header>
        <Card.Body>
          <div className='table-responsive'>
            <Table hover size='sm'>
              <thead
                className='text-uppercase custom-table-head border'
                style={{ fontSize: 11 }}
              >
                <tr>
                  <th className='text-center' style={{ width: "5%" }}>
                    Bil.
                  </th>
                  <th>Projek Utama dan Sub projek</th>
                  <th className='text-center'>Kementerian</th>
                  <th>Jabatan / Agensi</th>
                  <th className='text-center'>Status</th>
                </tr>
              </thead>
              {isLoading ? (
                <FiveColumns />
              ) : (
                <>
                  {isEmpty(tableContent) ? (
                    <tbody>
                      <tr>
                        <td colSpan={7} className='text-center'>
                          Tiada maklumat tersedia
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody style={{ fontSize: 12 }}>
                      {tableContent.map((project, idx) => {
                        const { progress_status } = project;

                        const badgeType = PROGRESS_STATUS.find(
                          (item) => item.id === progress_status,
                        );

                        return (
                          <tr
                            key={project.id}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleViewProject(project.id)}
                          >
                            <td className='text-center'>
                              {indexOfFirstPage + idx + 1}
                            </td>
                            <td>
                              <div
                                className='text-uppercase'
                                style={{ fontWeight: 700 }}
                              >
                                {project.main_project?.name}
                              </div>
                              <div className='text-muted card-subtitle'>
                                {project.name}
                              </div>
                            </td>
                            <td className='text-center'>
                              {project.ministry.short_form}
                            </td>
                            <td>{project.department?.name}</td>
                            <td className='text-center'>
                              <Badge
                                bg=''
                                className={`container-fluid`}
                                style={{ backgroundColor: badgeType.color }}
                              >
                                {badgeType.label}
                              </Badge>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </>
              )}
            </Table>
          </div>
          <ProjectPagination
            perPage={perPage}
            totalProjects={filteredContent?.length}
            currentPage={page}
            paginate={paginate}
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default Projects;
