import React, { useRef } from "react";
import {
  useFormik,
  FieldArray,
  Form as FormikForm,
  Formik,
  Field,
  ErrorMessage,
  useFormikContext,
} from "formik";
import { Button, Form } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import usePalette from "../../../../../hooks/usePalette";
import { isEmpty } from "lodash";

// Icons
import Icon from "@mdi/react";
import { MdCancel } from "react-icons/md";

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <Form.Control
    {...field}
    {...props}
    type='text'
    as='textarea'
    style={{ minHeight: 60, maxHeight: 85 }}
  />
);

const Closing = () => {
  const formRef = useRef();
  const palette = usePalette();
  const routeParams = useParams();

  const { values, submitForm } = useFormikContext();

  return (
    <React.Fragment>
      <div className='my-4'>
        <h5 className='fw-bold text-uppercase'>
          <span className='me-4'>
            {routeParams.category !== "pindahan-siling" && "7.0"}
            {routeParams.category === "pindahan-siling" && "10.0"}
          </span>
          Penutup
        </h5>
        <FieldArray name='closing'>
          {({ insert, remove, push }) => (
            <React.Fragment>
              {values?.closing?.map((item, index) => {
                return (
                  <div key={index} className='d-flex flex-row ps-4'>
                    <div className='me-3'>9.{index + 1}</div>
                    <Form.Group className='position-relative mb-3 w-100'>
                      <Field
                        name={`closing.${index}.comment`}
                        component={CustomInputComponent}
                      />
                      {index !== 0 && (
                        <div
                          className='position-absolute'
                          style={{ top: -11, right: -8 }}
                        >
                          <Tooltip placement='top' title='Padam' arrow>
                            <Link to='#' onClick={() => remove(index)}>
                              <MdCancel size={20} color={palette.danger} />
                            </Link>
                          </Tooltip>
                        </div>
                      )}
                    </Form.Group>
                  </div>
                );
              })}
              <div className='d-flex justify-content-center'>
                <Button
                  size='sm'
                  variant='secondary'
                  onClick={() => push({ comment: "" })}
                >
                  + Komen
                </Button>
              </div>
            </React.Fragment>
          )}
        </FieldArray>
      </div>
    </React.Fragment>
  );
};

export default Closing;
