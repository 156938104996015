import React from "react";
import { Col, Row } from "react-bootstrap";

// Components
import ProjectInformation from "./components/cards/ProjectInformation";
import ProjectTimeline from "./components/charts/ProjectTimeline";
import ExecutingAgency from "./components/cards/ExecutingAgency";
import StartDate from "./components/cards/StartDate";

import CompletionDate from "./components/cards/CompletionDate";
import LineGraph from "./components/charts/LineGraph";
import FinancialReportSummary from "./components/cards/FinancialReportSummary";
import RadialBar from "./components/charts/RadialBar";
import ProjectLocation from "./components/maps/ProjectLocation";

const PageContent = () => {
  return (
    <React.Fragment>
      <Row>
        <Col md={6} className='d-flex'>
          <ProjectInformation />
        </Col>
        <Col md={6} className='d-flex'>
          <Row className='flex-fill'>
            <Col sm={12} className='d-flex'>
              <ExecutingAgency />
            </Col>
            <Col sm={6} className='d-flex'>
              <StartDate />
            </Col>
            <Col sm={6} className='d-flex'>
              <CompletionDate />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col md={5} className='d-flex'>
          <RadialBar />
        </Col>
        <Col md={7} className='d-flex'>
          <LineGraph />
        </Col>
      </Row>

      <FinancialReportSummary />

      <ProjectTimeline />

      {/* <ProjectLocation /> */}
    </React.Fragment>
  );
};

export default PageContent;
