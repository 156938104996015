import React, { useEffect, useState } from "react";
import numeral from "numeral";
import { Card, Tab, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { format } from "date-fns";
import { ms } from "date-fns/locale";

const DataByRmk = ({ eventKey }) => {
  const [approvedCeilingFlow, setApprovedCeilingFlow] = useState([]);
  const [revisedCeiling, setRevisedCeiling] = useState(0);
  const [approvedCost, setApprovedCost] = useState(0);
  const [ceilingAmount, setCeilingAmount] = useState({
    approved: 0,
    revised: 0,
  });

  const [previousRMK, setPreviousRMK] = useState([]);
  const [jppnData, setJppnData] = useState([]);

  const { project, currentRmk } = useSelector((state) => ({
    project: state.projectReducer.project,
    currentRmk: state.rmkReducer.activeRmk,
  }));

  useEffect(() => {
    if (!isEmpty(project) && !isEmpty(currentRmk)) {
      const { first_year, second_year, third_year, fourth_year, fifth_year } =
        currentRmk;
      const years = [
        first_year,
        second_year,
        third_year,
        fourth_year,
        fifth_year,
      ];

      const ceilingApproved = project?.financial_details.flatMap(
        (item) => item.ceiling_flow_details,
      );

      const groupedByYear = ceilingApproved?.reduce(
        (group, item) => {
          const { year } = item;

          if (year === first_year) {
            group[first_year].push(item);
          } else if (year === second_year) {
            group[second_year].push(item);
          } else if (year === third_year) {
            group[third_year].push(item);
          } else if (year === fourth_year) {
            group[fourth_year].push(item);
          } else if (year === fifth_year) {
            group[fifth_year].push(item);
          }
          return group;
        },
        {
          [first_year]: [],
          [second_year]: [],
          [third_year]: [],
          [fourth_year]: [],
          [fifth_year]: [],
        },
      );

      const result = Object.entries(groupedByYear).map(([year, data]) => ({
        year: year,
        data,
      }));

      setApprovedCeilingFlow(result);

      const initialCeilingByRmk = project?.financial_details?.reduce(
        (acc, item) => (acc += parseFloat(item.ceiling)),
        0,
      );

      const revisedCeilingByRmk = project?.financial_details?.reduce(
        (acc, item) =>
          (acc += parseFloat(item.revised_ceiling || item.ceiling)),
        0,
      );

      const cost = project?.financial_details.reduce(
        (acc, item) => (acc += parseFloat(item.cost_required)),
        0,
      );

      const financialActivities = project?.s3p_data?.flatMap(
        (item) => item.financial_activities,
      );

      const virementInFund = financialActivities
        .filter((item) => item.type === "VI")
        .reduce((acc, item) => acc + parseFloat(item.amount), 0);

      const virementOutFund = financialActivities
        .filter((item) => item.type === "VO")
        .reduce((acc, item) => acc + parseFloat(item.amount), 0);

      const sumInitialAllocation = project?.s3p_data?.reduce(
        (acc, obj) => (acc += parseFloat(obj.state_fund)),
        0,
      );

      const lejar = project?.s3p_data
        ?.flatMap((item) => item.monthly_info)
        ?.reduce((acc, item) => (acc += parseFloat(item.lejar)), 0);

      const sumRevisedAllocation = lejar + virementInFund - virementOutFund;

      const calcRevisedCeiling = revisedCeilingByRmk - sumRevisedAllocation;

      setApprovedCost(cost);
      setCeilingAmount({
        ...ceilingAmount,
        approved: initialCeilingByRmk,
        revised: calcRevisedCeiling,
      });

      const virementActivities = project?.virement_activities?.filter(
        (item) => item?.virement_ceiling_details?.is_approved !== null,
      );

      const combineActivities = virementActivities?.map((item) => {
        const newValues = {
          ...item,
          jppn_category: "Pindahan Siling",
          reference_no: item?.virement_ceiling_details?.reference_no,
        };
        return newValues;
      });

      setJppnData(combineActivities);
    }
  }, [project, currentRmk]);

  return (
    <Tab.Pane eventKey={eventKey}>
      <div className='mb-3'>
        <h5 className='text-uppercase'>Maklumat Siling RMK Lepas</h5>
        <div className='table-responsive'>
          <Table bordered size='sm' style={{ width: "100%" }}>
            <thead
              className='custom-table-head text-uppercase'
              style={{ fontSize: 12 }}
            >
              <tr>
                <th className='text-center' style={{ width: "5%" }}>
                  Bil.
                </th>
                <th className='text-center' style={{ width: "10%" }}>
                  RMK
                </th>
                <th className='text-center'>
                  <div>Kos Projek Diluluskan</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>Kos Projek Diluluskan Disemak</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>Siling Asal</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>Siling Disemak</div>
                  <div>(RM)</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isEmpty(previousRMK) ? (
                <tr>
                  <td colSpan={6} className='text-center'>
                    Tiada maklumat tersedia
                  </td>
                </tr>
              ) : (
                <>
                  {previousRMK?.map((item, idx) => {
                    return (
                      <tr>
                        <td className='text-center'>{idx + 1}</td>
                        <td className='text-center'>-</td>
                        <td className='text-end'>
                          {numeral(previousRMK?.approved).format("0,0.00")}
                        </td>
                        <td className='text-end'>
                          {numeral(0).format("0,0.00")}
                        </td>
                        <td className='text-end'>
                          {numeral(previousRMK?.approved).format("0,0.00")}
                        </td>
                        <td className='text-end'>
                          {numeral(0).format("0,0.00")}
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </Table>
        </div>
      </div>

      <div className='mb-3'>
        <h5>
          <span className='text-uppercase me-1'>
            Maklumat Siling RMK Semasa
          </span>
          ({project?.rmk?.short_form})
        </h5>
        <div className='table-responsive'>
          <Table bordered size='sm' style={{ width: "100%" }}>
            <thead
              className='custom-table-head text-uppercase'
              style={{ fontSize: 12 }}
            >
              <tr>
                <th rowSpan={2} className='text-center' style={{ width: "5%" }}>
                  Bil.
                </th>
                <th
                  rowSpan={2}
                  className='text-center'
                  style={{ width: "10%" }}
                >
                  <div>Kos Projek Diluluskan</div>
                  <div>(RM)</div>
                </th>
                <th rowSpan={2} className='text-center'>
                  <div>Siling Asal</div>
                  <div>(RM)</div>
                </th>
                <th rowSpan={2} className='text-center'>
                  <div>Siling Disemak</div>
                  <div>(RM)</div>
                </th>
                <th
                  colSpan={approvedCeilingFlow.length}
                  className='text-center'
                >
                  <div>Aliran Siling</div>
                </th>
              </tr>
              <tr>
                {approvedCeilingFlow.map((item, idx) => {
                  return (
                    <th key={idx} className='text-center'>
                      {item.year}
                      <div>(RM)</div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='text-center'>1</td>
                <td className='text-end'>
                  {numeral(approvedCost).format("0,0.00")}
                </td>
                <td className='text-end'>
                  {numeral(ceilingAmount.approved).format("0,0.00")}
                </td>
                <td className='text-end'>
                  {numeral(ceilingAmount.revised).format("0,0.00")}
                </td>
                {approvedCeilingFlow.map((item, idx) => {
                  const sum = item?.data?.reduce(
                    (acc, obj) =>
                      (acc += parseFloat(obj.revised_amount || obj.amount)),
                    0,
                  );
                  return (
                    <td key={idx} className='text-end'>
                      {numeral(sum).format("0,0.00")}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      <div className='mb-3'>
        <h5 className='text-uppercase'>Maklumat JPPN</h5>
        <div className='table-responsive'>
          <Table bordered size='sm' style={{ width: "100%" }}>
            <thead
              className='custom-table-head text-uppercase'
              style={{ fontSize: 12 }}
            >
              <tr>
                <th rowSpan={2} className='text-center' style={{ width: "5%" }}>
                  Bil.
                </th>
                <th
                  rowSpan={2}
                  className='text-center'
                  style={{ width: "10%" }}
                >
                  No. Rujukan
                </th>
                <th rowSpan={2} className='text-center'>
                  <div>Jenis</div>
                  <div>JPPN</div>
                </th>
                <th rowSpan={2} className='text-center'>
                  Sumber
                </th>
                <th
                  colSpan={approvedCeilingFlow.length}
                  className='text-center'
                >
                  <div>Jumlah</div>
                  <div>(RM)</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isEmpty(jppnData) ? (
                <tr>
                  <td colSpan={5} className='text-center'>
                    Tiada maklumat tersedia
                  </td>
                </tr>
              ) : (
                <>
                  {jppnData.map((item, idx) => {
                    let virementSources = [];
                    let operator = "";

                    if (item.virement_type === "VI") {
                      operator = "(+)";
                      virementSources =
                        item?.virement_ceiling_details?.virement_projects?.filter(
                          (obj) => obj.virement_type === "VO",
                        );
                    } else {
                      operator = "(-)";
                      virementSources =
                        item?.virement_ceiling_details?.virement_projects?.filter(
                          (obj) => obj.virement_type === "VI",
                        );
                    }

                    return (
                      <tr>
                        <td className='text-center'>{idx + 1}</td>
                        <td className='text-center'>{item?.reference_no}</td>
                        <td className='text-center'>{item?.jppn_category}</td>
                        <td className='text-center'>
                          {virementSources?.map((obj) => {
                            return (
                              <div>{obj.project?.sub_project_full_code}</div>
                            );
                          })}
                        </td>
                        <td className='text-end'>
                          {`${operator} ${numeral(
                            item?.total_virement_amount,
                          ).format("0,0.00")}`}
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </Tab.Pane>
  );
};

export default DataByRmk;
