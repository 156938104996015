import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card } from "react-bootstrap";
import numeral from "numeral";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

const LineGraph = () => {
  const [yearNow, setYearNow] = useState(new Date().getFullYear());
  const [fiftyPercent, setFiftyPercent] = useState(0);
  const [series, setSeries] = useState([]);

  const { project } = useSelector((state) => ({
    project: state.reportReducer.project,
  }));

  const options = {
    chart: {
      height: 350,
      type: "line",
      id: "areachart-2",
      toolbar: { show: false },
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.4,
      },
    },
    legend: {
      position: "top",
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    // annotations: {
    //   yaxis: [
    //     {
    //       // y: initialAllocation / 2,
    //       borderColor: "#009688",
    //       label: {
    //         borderColor: "#009688",
    //         textAnchor: "start",
    //         position: "left",
    //         style: {
    //           color: "#fff",
    //           background: "#009688",
    //         },
    //         text: "50 %",
    //       },
    //     },
    //   ],
    // },
    stroke: {
      curve: "straight",
      width: 2,
    },
    grid: {
      padding: {
        right: 30,
        left: 20,
      },
    },
    yaxis: {
      show: true,
      title: {
        text: "Perbelanjaan (RM)",
        rotate: -90,
        style: {
          cssClass: "apexcharts-yaxis-label text-uppercase",
        },
      },
      labels: {
        show: true,
        align: "right",
        minWidth: 90,
        maxWidth: 160,
        style: {
          colors: [],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label text-uppercase",
        },
        formatter: (value) => {
          return numeral(value).format("0.0 a");
        },
      },
      // max: initialAllocation,
    },
    xaxis: {
      categories: Array.from({ length: 12 }, (e, i) => {
        return new Date(null, i + 1, null).toLocaleDateString("ms", {
          month: "short",
        });
      }),
    },
    colors: ["#388e3c", "#303f9f", "#e64a19", "#ffb300"],
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      formatter: (value, opts) => {
        if (value === null || value === undefined) {
          return "";
        }
        const newValue = `RM ${numeral(value).format("0.0 a")}`;
        return newValue;
      },
      offsetY: -20,
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 3,
    },
  };

  useEffect(() => {
    if (!isEmpty(project)) {
      const { reports, s3p_data } = project;
      const currentYear = new Date().getFullYear();

      const expensesReportByMinistryCurrentYear = reports?.filter(
        (item) => item.year === currentYear,
      );

      const groupDataByMonth = () => {
        return expensesReportByMinistryCurrentYear.reduce((acc, curr) => {
          const month = curr.month;
          if (!acc[month]) {
            acc[month] = [];
          }
          acc[month].push(curr);
          return acc;
        }, {});
      };

      const groupedData = groupDataByMonth();

      const sumExpensesByMonth = () => {
        const totals = new Array(12).fill(0);

        Object.keys(groupedData).forEach((month) => {
          let total = 0;

          groupedData[month].forEach((item) => {
            // Parse the expense as integer before adding to total
            const expense = parseFloat(
              item.total_expenses_report_by_agency || 0,
            );

            total += expense;
          });

          totals[parseInt(month) - 1] = total;
        });

        const result = totals.map((item) => {
          if (item === 0) {
            return null;
          } else {
            return item;
          }
        });

        return result;
      };

      const expensesByMonth = sumExpensesByMonth();

      const s3pReportCurrentYear = s3p_data?.find(
        (item) => item.year === currentYear,
      );

      const bendahari = s3pReportCurrentYear?.monthly_info
        ?.toSorted((a, b) => a.month - b.month)
        .map((item) => {
          if (numeral(item.bendahari).value() === 0) {
            return null;
          } else {
            return parseFloat(item.bendahari);
          }
        });

      const lejar = s3pReportCurrentYear?.monthly_info
        ?.toSorted((a, b) => a.month - b.month)
        .map((item) => {
          if (numeral(item.lejar).value() === 0) {
            return null;
          } else {
            return parseFloat(item.lejar);
          }
        });

      setSeries([
        {
          id: 1,
          name: "PERBELANJAAN TERKINI",
          data: [],
        },
        {
          id: 2,
          name: "PERBELANJAAN S3P",
          data: bendahari,
        },
        {
          id: 3,
          name: "PERBELANJAAN SEBENAR",
          data: lejar,
        },
        {
          id: 4,
          name: "LAPORAN PERBELANJAAN DARI AGENSI",
          data: expensesByMonth,
        },
      ]);
    }
  }, [project]);

  return (
    <React.Fragment>
      <Card className='w-100 flex-fill'>
        <Card.Header>
          <Card.Title className='mb-0'>Prestasi Kewangan Tahun 2024</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className='d-flex justify-content-center align-items-center'>
            <ReactApexChart
              className='w-100 flex-fill'
              options={options}
              series={series}
              height={350}
            />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default LineGraph;
