import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const getProjectBasicInfo = createAsyncThunk(
  "jppnNewProjectListingSlice/getProjectBasicInfo",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(`jppn/project-basic-info/get`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getVirementSource = createAsyncThunk(
  "jppnNewProjectListingSlice/getVirementSource",
  async (props) => {
    try {
      const { params } = props;

      const response = await axios.get(`jppn/virement-source/get`, {
        params,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getNewProjectList = createAsyncThunk(
  "jppnNewProjectListingSlice/getNewProjectList",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(`jppn/new-project`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const createNewProjectListing = createAsyncThunk(
  "jppnNewProjectListingSlice/createNewProjectListing",
  async (values) => {
    try {
      const response = await axios.post(`jppn/new-project`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getNewProjectApplication = createAsyncThunk(
  "jppnNewProjectListingSlice/getNewProjectApplication",
  async (params) => {
    try {
      const { project_id, source } = params;
      const response = await axios.get(`jppn/new-project/${project_id}`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);
