import React, { useEffect, useState } from "react";
import numeral from "numeral";
import { Card, ProgressBar, Tab, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { format } from "date-fns";
import { ms } from "date-fns/locale";
import usePalette from "../../../../../../../hooks/usePalette";

// Icons
import Icon from "@mdi/react";
import { mdiCurrencyUsd, mdiArrowRightBottomBold } from "@mdi/js";

const TransferFund = ({ eventKey }) => {
  const palette = usePalette();

  const [rowData, setRowData] = useState([]);

  const { project, currentRmk } = useSelector((state) => ({
    project: state.projectReducer.project,
    currentRmk: state.rmkReducer.activeRmk,
  }));

  useEffect(() => {
    if (!isEmpty(project)) {
      const { rmk, s3p_data } = project;

      const firstYearData = s3p_data.find(
        (item) => item.year === rmk.first_year,
      );
      const secondYearData = s3p_data.find(
        (item) => item.year === rmk.second_year,
      );
      const thirdYearData = s3p_data.find(
        (item) => item.year === rmk.third_year,
      );
      const fourthYearData = s3p_data.find(
        (item) => item.year === rmk.fourth_year,
      );
      const fifthYearData = s3p_data.find(
        (item) => item.year === rmk.fifth_year,
      );

      const firstYearActivities = firstYearData?.financial_activities.map(
        (item) => {
          return {
            ...item,
            initial_fund: firstYearData.state_fund,
            accumulated_fund: 0,
          };
        },
      );

      const secondYearActivities = secondYearData?.financial_activities.map(
        (item) => {
          return {
            ...item,
            initial_fund: secondYearData.state_fund,
            accumulated_fund: 0,
          };
        },
      );

      const thirdYearActivities = thirdYearData?.financial_activities.map(
        (item) => {
          return {
            ...item,
            initial_fund: thirdYearData.state_fund,
            accumulated_fund: 0,
          };
        },
      );

      const fourthYearActivities = fourthYearData?.financial_activities.map(
        (item) => {
          return {
            ...item,
            initial_fund: fourthYearData.state_fund,
            accumulated_fund: 0,
          };
        },
      );

      const fifthYearActivities = fifthYearData?.financial_activities.map(
        (item) => {
          return {
            ...item,
            initial_fund: fifthYearData.state_fund,
            accumulated_fund: 0,
          };
        },
      );

      const combinedActivities = [
        ...(firstYearActivities || []),
        ...(secondYearActivities || []),
        ...(thirdYearActivities || []),
        ...(fourthYearActivities || []),
        ...(fifthYearActivities || []),
      ];

      setRowData(combinedActivities);
    }
  }, [project]);

  return (
    <Tab.Pane eventKey={eventKey}>
      <div className='mb-3'>
        <h5 className='text-uppercase'>Maklumat Craw / Virement / ASP</h5>
        <div className='table-responsive'>
          <Table bordered size='sm' style={{ width: "100%" }}>
            <thead className='text-uppercase' style={{ fontSize: 11 }}>
              <tr>
                <th rowSpan={2} className='text-center'>
                  <div className=''>
                    <div>Peruntukan Asal</div>
                    <div>(RM)</div>
                  </div>
                </th>
                <th colSpan={2} className='text-center'>
                  Tambahan (ASP / CRAW)
                </th>
                <th colSpan={3} className='text-center'>
                  <div className='text-truncate'>Virement (+ / -)</div>
                </th>
                <th colSpan={2} className='text-center'>
                  Sumber
                </th>
                <th rowSpan={2} className='text-center'>
                  Jumlah Terkumpul
                  <br />
                  (RM)
                </th>
              </tr>
              <tr>
                <th className='text-center'>Rujukan</th>
                <th className='text-center'>
                  Jumlah
                  <br />
                  (RM)
                </th>
                <th className='text-center'>Rujukan</th>
                <th className='text-center'>
                  + <br />
                  (RM)
                </th>
                <th className='text-center'>
                  - <br />
                  (RM)
                </th>
                <th className='text-center'>
                  Daripada (+) <br />
                  (RM)
                </th>
                <th className='text-center'>
                  Kepada (-) <br />
                  (RM)
                </th>
              </tr>
            </thead>
            <tbody>
              {isEmpty(rowData) ? (
                <tr>
                  <td colSpan={9} className='text-center'>
                    Tiada maklumat tersedia
                  </td>
                </tr>
              ) : (
                <>
                  {rowData.map((item, idx) => {
                    let virementIn = null;
                    let virementOut = null;
                    let asp = null;

                    switch (item.type) {
                      case "VO":
                        virementOut = {
                          warrantNo: item.warrant_no,
                          source: item.source,
                          amount: item.amount,
                        };

                        break;

                      case "VI":
                        virementIn = {
                          warrantNo: item.warrant_no,
                          source: item.source,
                          amount: item.amount,
                        };

                        break;

                      case "ASP":
                        asp = {
                          reference_no: item.reference_no,
                          date: item.date,
                          amount: item.amount,
                        };

                        break;
                    }
                    const totalRevisedFund =
                      parseFloat(item.initial_fund) +
                      parseFloat(asp?.amount || 0) +
                      parseFloat(virementIn?.amount || 0) -
                      parseFloat(virementOut?.amount || 0);

                    return (
                      <tr key={idx}>
                        <td className='text-end'>
                          {numeral(item.initial_fund).format("0,0.00")}
                        </td>
                        <td className='text-center'>{asp?.reference_no}</td>
                        <td className='text-end'>
                          {asp ? numeral(asp?.amount).format("0,0.00") : "-"}
                        </td>
                        <td className='text-truncate text-center'>
                          {virementIn?.warrantNo}
                          {virementOut?.warrantNo}
                        </td>
                        <td className='text-end'>
                          {virementIn
                            ? numeral(virementIn?.amount).format("0,0.00")
                            : "-"}
                        </td>
                        <td className='text-end'>
                          {virementOut
                            ? numeral(virementOut?.amount).format("0,0.00")
                            : "-"}
                        </td>
                        <td className='text-center' style={{ fontSize: 12 }}>
                          {virementIn?.source || "-"}
                        </td>
                        <td className='text-center' style={{ fontSize: 12 }}>
                          {virementOut?.source || "-"}
                        </td>
                        <td className='text-end'>
                          {numeral(totalRevisedFund).format("0,0.00")}
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}

              {/* <tr>
                <td className='text-center'>{numeral(0).format("0,0.00")}</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>Daripada(+)</td>
                <td>Kepada(-)</td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={8} className='text-end'>
                  Jumlah Keseluruhan (RM)
                </td>
                <td className='text-end'>-</td>
              </tr> */}
            </tbody>
          </Table>
        </div>
      </div>
    </Tab.Pane>
  );
};

export default TransferFund;
