import React from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmSend = ({ show, hide, confirm }) => {
  return (
    <Modal show={show} onHide={hide} size='sm' centered>
      <Modal.Body>
        <div className='d-flex justify-content-center mb-3'>
          Adakah anda pasti hendak menghantar?
        </div>
        <p>
          Sila pastikan maklumat yang diberikan adalah benar, sila semak jika
          terdapat maklumat yang tidak tepat sebelum menghantar.
        </p>
        <div className='d-flex justify-content-center gap-3'>
          <Button variant='success' onClick={confirm}>
            Hantar
          </Button>
          <Button variant='light' onClick={hide}>
            Batal
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmSend;
