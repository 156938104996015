import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Collapse,
  Badge,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import LoadingState from "../../../../../components/common/LoadingState";
import { format } from "date-fns";
import { useFormik } from "formik";
import * as Yup from "yup";
import CurrencyInput from "react-currency-input-field";
import DatePicker from "react-datepicker";
import numeral from "numeral";
import { isEmpty } from "lodash";
import NotyfContext from "../../../../../contexts/NotyfContext";

// icons
import Icon from "@mdi/react";
import { mdiArrowLeft, mdiMenuDown, mdiMenuRight, mdiTrashCan } from "@mdi/js";

// Images
import SBHLogo from "../../../../../assets/img/photos/sabah-logo.png";

// Redux
import { getProject } from "../../../../../redux/slices/reports/projectReportsSlice";
import {
  showReport,
  updateReport,
  removeReport,
} from "../../../../../redux/slices/reports/projectReportSlice";
import { getProgressStatusOptions } from "../../../../../redux/slices/utilsSlice";

// utils
import { MONTH_OPTIONS } from "../../../../../constants";
import ReactQuill from "react-quill";
import ConfirmSend from "../components/modals/ConfirmSend";

// components
import Attachment from "./Attachment";

const labelStyles = {
  maxWidth: 200,
  width: 200,
  fontWeight: 700,
  textTransform: "uppercase",
  fontSize: "12px",
  backgroundColor: "#f5f5f5",
};

const contentStyles = {
  fontWeight: 500,
};

const MainPage = () => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);

  const { projectId, reportId } = routeParams;

  const [yearOptions, setYearOptions] = useState([]);
  const [showComponents, setShowComponents] = useState(false);
  const [projectScope, setProjectScope] = useState([]);
  const [delayPercentage, setDelayPercentage] = useState(0);
  const [disableItem, setDisableItem] = useState(true);
  const [progressStatusValue, setProgressStatusValue] = useState(null);
  const [showConfirmSend, setShowConfirmSend] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [scopeProgress, setScopeProgress] = useState([]);

  const datepickcerMonthOptions = [
    "Januari",
    "Februari",
    "Mac",
    "April",
    "Mei",
    "Jun",
    "Julai",
    "Ogos",
    "September",
    "Oktober",
    "November",
    "Disember",
  ];

  const toolbar = [
    { header: [1, 2, 3, 4, 5, 6, false] },
    "bold",
    "italic",
    "blockquote",
    "underline",
    { list: "ordered" },
    { list: "bullet" },
  ];

  const {
    user,
    project,
    isLoading,
    report,
    uploadProgress,
    attachments,
    progressStatusOptions,
  } = useSelector((state) => ({
    user: state.userReducer.user,
    project: state.reportReducer.project,
    isLoading: state.reportReducer.isLoading.report,
    report: state.reportReducer.report,
    uploadProgress: state.reportReducer.uploadProgress,
    attachments: state.reportReducer.attachments,
    progressStatusOptions:
      state.utilsReducer.dropdownItems.progressStatusOptions,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      month: report?.month || new Date().getMonth() + 1,
      year: report?.year || new Date().getFullYear(),
      progress_status: report?.current_progress_status || null,
      report_notes: report?.notes || "",
      overall_start_date:
        (report?.project_physical_details?.actual_start_date &&
          new Date(report?.project_physical_details?.actual_start_date)) ||
        "",
      overall_complete_date:
        (report?.project_physical_details?.actual_complete_date &&
          new Date(report?.project_physical_details?.actual_complete_date)) ||
        "",
      contractor_or_consultant: report?.contractor_or_consultant || "",
      overall_expenses_report_by_agency:
        report?.total_expenses_report_by_agency || "",
      overall_progress: report?.progress,
      overall_target_progress: report?.target_progress,
    },
    validationSchema: Yup.object().shape({
      progress_status: Yup.object().required("Sila isi status kemajuan"),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        const newValues = {
          id: Number(reportId),
          project_id: Number(projectId),
          month: values.month,
          year: values.year,
          scope_of_work: scopeProgress,
          is_sent: true,
          report_notes: values.report_notes,
          progress_status: values.progress_status?.code,
          progress: values.overall_progress,
          target_progress: values.overall_target_progress,
          total_expenses_report_by_agency: parseFloat(
            values.overall_expenses_report_by_agency,
          ),
          contractor_or_consultant: values.contractor_or_consultant,
          actual_start_date: values?.overall_start_date || null,
          actual_complete_date: values?.overall_complete_date || null,
        };
        await dispatch(updateReport(newValues));
      } else {
        const newValues = {
          id: Number(reportId),
          project_id: Number(projectId),
          month: values.month,
          year: values.year,
          scope_of_work: scopeProgress,
          is_sent: isSent,
          report_notes: values.report_notes,
          progress_status: values.progress_status?.code,
          progress: values.overall_progress,
          target_progress: values.overall_target_progress,
          total_expenses_report_by_agency: parseFloat(
            values.overall_expenses_report_by_agency,
          ),
          contractor_or_consultant: values.contractor_or_consultant,
          actual_start_date: values?.overall_start_date || null,
          actual_complete_date: values?.overall_complete_date || null,
        };
        await dispatch(updateReport(newValues));
      }
      setShowConfirmSend(false);

      if (isSent) {
        navigate(-1);
      } else {
        handleDraftSavedMsg();
      }

      formik.resetForm();
    },
  });

  const handleChangeProgressStatus = (value) => {
    const num = Number(value);
    const newValue = progressStatusOptions?.find((item) => item.code === num);
    formik.setFieldValue("progress_status", newValue);
  };

  const handleSaveDraft = () => {
    formik.handleSubmit();
  };

  const handleSubmit = () => {
    if (isEmpty(formik.values?.progress_status)) {
      formik.validateForm();
    } else {
      setShowConfirmSend(true);
    }
  };

  const handleConfirmSend = () => {
    setIsSent(true);
    formik.handleSubmit();
  };

  const handleRemoveItem = async () => {
    const params = {
      id: reportId,
      project_id: Number(projectId),
    };
    await dispatch(removeReport(params));
    navigate(`/laporan/kemaskini-kemajuan-projek/${projectId}`);
  };

  const handleDraftSavedMsg = () => {
    notyf.open({
      type: "success",
      message: "Draf laporan telah disimpan.",
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    const percentage =
      formik.values?.overall_target_progress - formik.values?.overall_progress;

    setDelayPercentage(percentage || 0);
  }, [formik.values?.overall_progress, formik.values?.overall_target_progress]);

  useEffect(() => {
    if (isEmpty(project)) return;
    const { rmk, financial_details, reports } = project;
    const { first_year, second_year, third_year, fourth_year, fifth_year } =
      rmk;

    setYearOptions(Array.from({ length: 60 }, (_, index) => 1991 + index));

    const financialDetails = project?.financial_details;

    const components = financialDetails?.map((item) => {
      return {
        id: item.id,
        cost_description: item.cost_description,
        project_scope_details: item.project_scope_details,
        project_scope_progress_reports: item.project_scope_progress_reports,
      };
    });

    setProjectScope(components);

    const scopeReports = financialDetails?.map((item) => {
      const { project_scope_progress_reports, project_scope_details } = item;

      const isCompleted = project_scope_progress_reports.some(
        (obj) => parseFloat(obj.progress) === 100,
      );

      const lastReport = project_scope_progress_reports?.slice(-1)[0];

      return {
        financial_detail_id: item.id,
        progress: "0",
        expenses_report_by_agency: 0,
        actualStartDate: null,
        actualCompletionDate: null,
        errorInput: false,
        disabledInput: true,
        isCompleted: isCompleted,
        errors: {},
      };
    });

    setScopeProgress(scopeReports);
  }, [project]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initialize = async () => {
      const props = {
        id: projectId,
        source: source,
        reportId: reportId,
      };

      await Promise.all([
        dispatch(getProject(props)),
        dispatch(getProgressStatusOptions(props)),
        dispatch(showReport(props)),
      ]);
    };

    initialize();

    return () => {
      source.cancel();
    };
  }, [dispatch, projectId, reportId]);

  if (isLoading) return <LoadingState />;

  return (
    <React.Fragment>
      <ConfirmSend
        show={showConfirmSend}
        hide={() => setShowConfirmSend(false)}
        confirmSend={handleConfirmSend}
      />
      <Container fluid className='p-0'>
        <Card>
          <Card.Header className='position-relative'>
            <div
              className='position-absolute d-flex align-items-center'
              style={{ top: 10, left: 10 }}
            >
              <IconButton
                className='p-0'
                size='small'
                onClick={() =>
                  navigate(`/laporan/kemaskini-kemajuan-projek/${projectId}`)
                }
              >
                <Icon path={mdiArrowLeft} size='30' />
              </IconButton>
            </div>
            <div className='d-flex justify-content-center align-items-center mb-1'>
              <img src={SBHLogo} height='auto' width={60} />
              <div
                className='fs-4 text-uppercase ms-4'
                style={{ maxWidth: 550, fontWeight: 700 }}
              >
                {`Laporan Kemajuan untuk ${project.name}`}
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Form>
              <Table size='sm' borderless className='mb-3'>
                <tbody>
                  <tr>
                    <td style={{ width: 120 }} className='ms-0 ps-0'>
                      Tarikh draft dibuat
                    </td>
                    <td>
                      <span className='me-1'>:</span>
                      {report?.created_at &&
                        format(
                          new Date(report?.created_at),
                          "dd-MM-yyyy, hh:mm a",
                        )}
                    </td>
                  </tr>
                </tbody>
              </Table>

              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <Form.Label>Laporan Bulan</Form.Label>
                    <Form.Select
                      name='month'
                      placeholder='Pilih bulan'
                      value={formik.values?.month}
                      onChange={(e) => {
                        formik.setFieldValue("month", Number(e.target.value));
                      }}
                    >
                      {MONTH_OPTIONS.map((option) => (
                        <option key={option.no} value={option.no}>
                          {option.value}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3'>
                    <Form.Label>Laporan Tahun</Form.Label>
                    <Form.Select
                      name='year'
                      placeholder='Pilih tahun'
                      value={formik.values?.year}
                      onChange={(e) =>
                        formik.setFieldValue("year", Number(e.target.value))
                      }
                    >
                      {yearOptions.map((option) => (
                        <option key={option} value={option.value}>
                          {option}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <div className='mb-3'>
                <Table size='sm' bordered>
                  <tbody>
                    <tr>
                      <td style={labelStyles}>Kod Projek</td>
                      <td style={contentStyles}>
                        {project?.sub_project_full_code}
                      </td>
                    </tr>
                    <tr>
                      <td style={labelStyles}>Nama Projek</td>
                      <td style={contentStyles}>{project?.name}</td>
                    </tr>
                    <tr>
                      <td style={labelStyles}>Agensi Pelaksana</td>
                      <td style={contentStyles}>
                        {project?.executing_agency?.value}
                      </td>
                    </tr>
                    <tr>
                      <td style={labelStyles}>Daerah</td>
                      <td style={contentStyles}>
                        {project?.site_informations?.district?.value || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td style={labelStyles}>Parlimen</td>
                      <td style={contentStyles}>
                        {project?.site_informations?.parliament?.value || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td style={labelStyles}>DUN</td>
                      <td style={contentStyles}>
                        {project?.site_informations?.state_legislative_assembly
                          ?.value || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td style={labelStyles}>Nama Konsultan/Kontraktor</td>
                      <td style={contentStyles}>
                        <Form.Control
                          name='contractor_or_consultant'
                          type='text'
                          value={formik?.values?.contractor_or_consultant}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div className='mb-3'>
                <h5>Laporan Skop Projek</h5>
                <Table size='sm' bordered>
                  <thead
                    className='custom-table-head text-uppercase text-center'
                    style={{ fontSize: 11 }}
                  >
                    <tr>
                      <th rowSpan={2} />
                      <th rowSpan={2} style={{ maxWidth: 300, width: 300 }}>
                        Skop Projek
                      </th>
                      <th rowSpan={2}>
                        Laporan Perbelanjaan Dari Agensi <br /> (RM)
                      </th>
                      <th rowSpan={2}>
                        Kemajuan Skop Sebenar <br /> (%)
                      </th>
                      <th rowSpan={2}>
                        Kemajuan Skop Semasa <br /> (%)
                      </th>
                      <th rowSpan={2} className='text-center'>
                        Peratus Kelewatan <br /> (%)
                      </th>
                      <th colSpan={2} className='text-center'>
                        Tarikh Sebenar
                      </th>
                    </tr>
                    <tr>
                      <th style={{ width: 115 }}>Mula</th>
                      <th style={{ width: 115 }}>Siap</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        className='text-center'
                        style={{ borderRight: "none" }}
                        onClick={() => setShowComponents(!showComponents)}
                        style={{ cursor: "pointer" }}
                      >
                        {showComponents ? (
                          <Icon className='m-0' path={mdiMenuDown} size={1} />
                        ) : (
                          <Icon className='m-0' path={mdiMenuRight} size={1} />
                        )}
                      </td>
                      <td>Laporan Skop Keseluruhan</td>
                      <td>
                        <Form.Group>
                          <Form.Control
                            className={`text-end text-truncate`}
                            as={CurrencyInput}
                            name='overall_expenses_report_by_agency'
                            placeholder='0.00'
                            decimalsLimit={2}
                            value={
                              formik.values.overall_expenses_report_by_agency
                            }
                            onValueChange={(value) =>
                              formik.setFieldValue(
                                "overall_expenses_report_by_agency",
                                value,
                              )
                            }
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control
                            className={`text-end text-truncate`}
                            as={CurrencyInput}
                            min={0}
                            maxLength={2}
                            autoComplete='off'
                            decimalsLimit={5}
                            value={formik.values.overall_target_progress}
                            onChange={(e) => {
                              const numberString = e.target.value;
                              const number =
                                parseFloat(numberString.replace(/,/g, "")) || 0;

                              formik.setFieldValue(
                                "overall_target_progress",
                                number,
                              );
                            }}
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control
                            className={`text-end text-truncate`}
                            as={CurrencyInput}
                            min={0}
                            maxLength={2}
                            autoComplete='off'
                            decimalsLimit={5}
                            value={formik.values.overall_progress}
                            onChange={(e) => {
                              const numberString = e.target.value;
                              const number =
                                parseFloat(numberString.replace(/,/g, "")) || 0;

                              formik.setFieldValue("overall_progress", number);
                            }}
                          />
                        </Form.Group>
                      </td>
                      <td className='text-center'>{delayPercentage}</td>
                      <td>
                        <Form.Group className='px-0' controlId='startDateInput'>
                          <Form.Control
                            as={DatePicker}
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                              decreaseMonth,
                              increaseMonth,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                            }) => (
                              <div className='d-flex justify-content-center mx-1'>
                                <Button
                                  className='me-2'
                                  variant='secondary'
                                  onClick={decreaseMonth}
                                  disabled={prevMonthButtonDisabled}
                                >
                                  {"<"}
                                </Button>

                                <Form.Select
                                  value={
                                    datepickcerMonthOptions[
                                      new Date(date).getMonth()
                                    ]
                                  }
                                  onChange={({ target: { value } }) =>
                                    changeMonth(
                                      datepickcerMonthOptions.indexOf(value),
                                    )
                                  }
                                >
                                  {datepickcerMonthOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </Form.Select>

                                <Form.Select
                                  className='me-2'
                                  value={new Date(date).getFullYear()}
                                  onChange={({ target: { value } }) =>
                                    changeYear(value)
                                  }
                                >
                                  {yearOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </Form.Select>

                                <Button
                                  className='ms-2'
                                  variant='secondary'
                                  onClick={increaseMonth}
                                  disabled={nextMonthButtonDisabled}
                                >
                                  {">"}
                                </Button>
                              </div>
                            )}
                            type='date'
                            dateFormat='dd-MM-yyyy'
                            selected={formik.values?.overall_start_date}
                            onChange={(date) =>
                              formik.setFieldValue("overall_start_date", date)
                            }
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group className='px-0' controlId='startDateInput'>
                          <Form.Control
                            as={DatePicker}
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                              decreaseMonth,
                              increaseMonth,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                            }) => (
                              <div className='d-flex justify-content-center mx-1'>
                                <Button
                                  className='me-2'
                                  variant='secondary'
                                  onClick={decreaseMonth}
                                  disabled={prevMonthButtonDisabled}
                                >
                                  {"<"}
                                </Button>

                                <Form.Select
                                  value={
                                    datepickcerMonthOptions[
                                      new Date(date).getMonth()
                                    ]
                                  }
                                  onChange={({ target: { value } }) =>
                                    changeMonth(
                                      datepickcerMonthOptions.indexOf(value),
                                    )
                                  }
                                >
                                  {datepickcerMonthOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </Form.Select>

                                <Form.Select
                                  className='me-2'
                                  value={new Date(date).getFullYear()}
                                  onChange={({ target: { value } }) =>
                                    changeYear(value)
                                  }
                                >
                                  {yearOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </Form.Select>

                                <Button
                                  className='ms-2'
                                  variant='secondary'
                                  onClick={increaseMonth}
                                  disabled={nextMonthButtonDisabled}
                                >
                                  {">"}
                                </Button>
                              </div>
                            )}
                            type='date'
                            dateFormat='dd-MM-yyyy'
                            selected={formik.values?.overall_complete_date}
                            onChange={(date) =>
                              formik.setFieldValue(
                                "overall_complete_date",
                                date,
                              )
                            }
                          />
                        </Form.Group>
                      </td>
                    </tr>
                    {projectScope?.map((item, idx) => {
                      return (
                        <tr key={idx} className='collapse-containing-div'>
                          <td className='text-center'>
                            <Collapse in={showComponents}>
                              <div>{idx + 1}</div>
                            </Collapse>
                          </td>
                          <td>
                            <Collapse in={showComponents}>
                              <div className='p-1'>{item.cost_description}</div>
                            </Collapse>
                          </td>
                          <td
                            colSpan={6}
                            style={{ backgroundColor: "#e2e8ee" }}
                          ></td>
                          {/* <td>
                            <Collapse in={showComponents}>
                              <div className='p-1'></div>
                            </Collapse>
                          </td>
                          <td>
                            <Collapse in={showComponents}>
                              <div className='p-1'></div>
                            </Collapse>
                          </td>
                          <td>
                            <Collapse in={showComponents}>
                              <div className='p-1'></div>
                            </Collapse>
                          </td>
                          <td>
                            <Collapse in={showComponents}>
                              <div className='p-1'></div>
                            </Collapse>
                          </td>
                          <td>
                            <Collapse in={showComponents}>
                              <div className='p-1'></div>
                            </Collapse>
                          </td>
                          <td>
                            <Collapse in={showComponents}>
                              <div className='p-1'></div>
                            </Collapse>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <div className='mb-3'>
                <Table size='sm' bordered>
                  <tbody>
                    <tr>
                      <td style={labelStyles}>Status Kemajuan</td>
                      <td style={contentStyles}>
                        <InputGroup className='d-flex'>
                          <div
                            className='custom-dropdown px-1'
                            style={{
                              width: 180,
                              borderTopLeftRadius: "0.2rem",
                              borderBottomLeftRadius: "0.2rem",
                            }}
                          >
                            <Badge
                              bg=''
                              className={`container-fluid text-uppercase fw-bolder`}
                              style={{
                                backgroundColor:
                                  formik.values?.progress_status?.color,
                              }}
                            >
                              {formik.values?.progress_status?.label}
                            </Badge>
                          </div>
                          {disableItem && (
                            <Dropdown
                              name='progress_status'
                              className='position-static'
                              onSelect={(value) =>
                                handleChangeProgressStatus(value)
                              }
                            >
                              <Dropdown.Toggle
                                className='text-muted custom-dropdown px-2'
                                as='a'
                                bsPrefix='-'
                                style={{
                                  borderColor: "#ced4da",
                                  borderTopRightRadius: "0.2rem",
                                  borderBottomRightRadius: "0.2rem",
                                }}
                              >
                                <Icon path={mdiMenuDown} size='18px' />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {progressStatusOptions
                                  .toSorted((a, b) => b.code - a.code)
                                  .map((item) => (
                                    <Dropdown.Item
                                      eventKey={item.code}
                                      key={item.code}
                                    >
                                      <Tooltip
                                        title={item.description}
                                        placement='right'
                                        arrow
                                      >
                                        <Badge
                                          className={`container-fluid`}
                                          bg=''
                                          style={{
                                            backgroundColor: item.color,
                                          }}
                                        >
                                          <span
                                            className='text-uppercase fw-bolder'
                                            style={{
                                              fontSize: 11,
                                              fontWeight: 600,
                                            }}
                                          >
                                            {item.label}
                                          </span>
                                        </Badge>
                                      </Tooltip>
                                    </Dropdown.Item>
                                  ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                          {formik.errors.progress_status && (
                            <div className='ms-3 text-error align-self-center'>
                              Sila isi status kemajuan
                            </div>
                          )}
                        </InputGroup>
                      </td>
                    </tr>
                    <tr>
                      <td style={labelStyles}>
                        Kos Diluluskan
                        <span
                          className='mx-1 text-truncate'
                          style={{ textTransform: "none" }}
                        >
                          {project?.rmk?.short_form}
                        </span>
                        (RM)
                      </td>
                      <td style={contentStyles} className='text-truncate'>
                        {numeral(
                          project?.financial_details?.reduce(
                            (acc, item) =>
                              (acc += parseFloat(item.cost_required)),
                            0,
                          ),
                        ).format("0,0.00")}
                      </td>
                    </tr>
                    <tr>
                      <td style={labelStyles}>
                        Siling Diluluskan
                        <span
                          className='mx-1 text-truncate'
                          style={{ textTransform: "none" }}
                        >
                          {project?.rmk?.short_form}
                        </span>
                        (RM)
                      </td>
                      <td style={contentStyles} className='text-truncate'>
                        {numeral(
                          project?.financial_details
                            ?.flatMap((item) => item.ceiling_flow_details)
                            .reduce(
                              (acc, item) => acc + parseFloat(item.amount),
                              0,
                            ),
                        ).format("0,0.00")}
                      </td>
                    </tr>
                    <tr>
                      <td style={labelStyles}>Peruntukan Semasa (RM)</td>
                      <td style={contentStyles} className='text-truncate'>
                        {numeral(
                          project?.s3p_data?.reduce(
                            (acc, item) =>
                              (acc += parseFloat(item.total_allocation)),
                            0,
                          ),
                        ).format("0,0.00")}
                      </td>
                    </tr>
                    <tr>
                      <td style={labelStyles}>
                        Perbelanjaan Dari Agensi Terkumpul (RM)
                      </td>
                      <td style={contentStyles} className='text-truncate'>
                        {numeral(
                          project.reports
                            ?.filter((item) => item.is_sent === true)
                            .reduce(
                              (acc, item) =>
                                (acc += parseFloat(
                                  item.total_expenses_report_by_agency || 0,
                                )),
                              0,
                            ),
                        ).format("0,0.00")}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <Form.Group className='mb-3'>
                <h5>Catatan (Jika perlu)</h5>
                <ReactQuill
                  autoCorrect='off'
                  name='report_notes'
                  modules={{ toolbar: toolbar }}
                  placeholder='Tambah Maklumat'
                  // readOnly={disableItem}
                  value={formik.values.report_notes}
                  onChange={(value) =>
                    formik.setFieldValue("report_notes", value)
                  }
                />
              </Form.Group>
            </Form>

            <Attachment isEdit={isEdit} />

            <div className='d-flex justify-content-end gap-3 align-items-center'>
              <span className='me-3'>
                <Tooltip
                  title='padam laporan'
                  placement='top'
                  arrow
                  followCursor
                >
                  <IconButton
                    className='p-0 m-0'
                    color='error'
                    onClick={handleRemoveItem}
                  >
                    <Icon path={mdiTrashCan} size={1} />
                  </IconButton>
                </Tooltip>
              </span>
              {!report?.is_sent && (
                <Button variant='secondary' onClick={handleSaveDraft}>
                  <span className='text-sm'>{"Draft"}</span>
                </Button>
              )}

              <Button variant='primary' onClick={handleSubmit}>
                <span className='text-sm'>
                  {report?.is_sent ? "Ubah" : "Hantar"}
                </span>
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
