import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Card, Col, Row } from "react-bootstrap";
import usePalette from "../../../../../../hooks/usePalette";
import numeral from "numeral";
import { Divider, Tooltip } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

// redux
import { setSearchProgressStatus } from "../../../../../../redux/slices/dashboard/dashboardSlice";

const PhysicalReport = ({ itemLeft }) => {
  const palette = usePalette();
  const dispatch = useDispatch();

  const [series, setSeries] = useState(null);

  const { selectYear, progressStatus } = useSelector((state) => ({
    progressStatus:
      state.dashboardReducer.overall.charts.projectsProgressStatus,
    selectYear: state.dashboardReducer.selectYear,
  }));

  const options = {
    labels: series?.label,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: series?.colors,
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
          background: "transparent",
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              label: "Jumlah Bilangan Projek",
              fontSize: "14px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#373d3f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
  };

  const handleViewProgressStatus = (item) => {
    console.log(item);
    dispatch(setSearchProgressStatus(Number(item.code)));
  };

  useEffect(() => {
    if (!isEmpty(progressStatus)) {
      const seriesData = {
        label: progressStatus?.map((item) => item.label),
        data: progressStatus?.map((item) => item.count),
        colors: progressStatus?.map((item) => item.color),
      };
      setSeries(seriesData);
    }
  }, [progressStatus]);

  return (
    <React.Fragment>
      <Card as={motion.div} variants={itemLeft} className='flex-fill w-100'>
        <Card.Header className='pb-0'>
          <h4 className='text-uppercase' style={{ fontWeight: 700 }}>
            Status kemajuan projek {selectYear && `Tahun ${selectYear.label}`}
          </h4>
        </Card.Header>
        <Card.Body className='d-flex'>
          <div className='align-self-center w-100'>
            <div className='py-0'>
              {!isEmpty(series) && (
                <Chart
                  options={options}
                  series={series?.data}
                  type='donut'
                  height={280}
                />
              )}
            </div>
          </div>
        </Card.Body>

        {progressStatus?.map((item) => (
          <React.Fragment key={item.code}>
            <Tooltip title={item.description} followCursor>
              <Row
                key={item.label}
                className='py-2 border mx-3 mb-1 shadow-1 rounded'
                style={{
                  textTransform: "uppercase",
                  backgroundColor: item.color,
                  color: palette.white,
                  fontSize: 12,
                  fontWeight: 500,
                  cursor: "pointer",
                }}
                onClick={() => handleViewProgressStatus(item)}
              >
                <Col className='d-flex'>
                  <div className='flex-fill w-100 ps-3 fw-bolder'>
                    {item.label}
                  </div>
                </Col>
                <Col className='d-flex'>
                  <div className='flex-fill w-100 text-center fw-bolder'>
                    {item.count}
                  </div>
                </Col>
              </Row>
            </Tooltip>
          </React.Fragment>
        ))}
      </Card>
    </React.Fragment>
  );
};

export default PhysicalReport;
