import React, { useEffect, useState } from "react";
import {
  Card,
  Accordion,
  Table,
  Tab,
  Nav,
  Row,
  Form,
  Col,
  InputGroup,
  Button,
} from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import numeral from "numeral";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, remove } from "lodash";
import { useFormik } from "formik";
import usePalette from "../../../../../hooks/usePalette";
import CurrencyInput from "react-currency-input-field";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useParams } from "react-router-dom";

// Icons
import Icon from "@mdi/react";
import {
  mdiPencil,
  mdiCheckBold,
  mdiCloseThick,
  mdiPlusBox,
  mdiDelete,
} from "@mdi/js";

// Options
import { fundingSourceOptions } from "../../../../../utils/common/options-data";

// Tab-content
import DataByRmk from "./tab-content/financial/DataByRmk";
import AllocationData from "./tab-content/financial/AllocationData";
import TransferFund from "./tab-content/financial/TransferFund";
import ProjectFinancial from "./tab-content/financial/ProjectFinancial";

// Redux
import { updateProject } from "../../../../../redux/slices/projects/projectSlice";
import {
  getScopeFinancialList,
  createScopeFinancial,
  updateScopeFinancial,
  deleteScopeFinancial,
  getFinancialCeilingFlowList,
  createFinancialCeilingFlow,
  updateFinancialCeilingFlow,
  deleteFinancialCeilingFlow,
} from "../../../../../redux/slices/projects/projectsSlice";
import { getProjectScope } from "../../../../../redux/slices/utilsSlice";
import {
  createProjectScopeFinancial,
  createProjectCeilingFlow,
} from "../../../../../redux/slices/projects/setProjectScopeFinancial";

const FinancialInfo = ({ showNotification }) => {
  const dispatch = useDispatch();
  const palette = usePalette();
  const routeParams = useParams();

  const [totalProjectCostApproved, setTotalProjectCostApproved] = useState(0);
  const [yearOptions, setYearOptions] = useState([]);
  const [yearInputValue, setYearInputValue] = useState("");
  const [fundingSourceValues, setFundingSourceValues] = useState([]);
  const [allocationData, setAllocationData] = useState([]);
  const [ceilingFlowData, setCeilingFlowData] = useState([]);
  const [ceilingFlowScopeOptions, setCeilingFlowScopeOptions] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);

  const [isEdit, setIsEdit] = useState({
    funding_sources: false,
    project_scope_financial: true,
    ceiling_flow: true,
  });

  const {
    project,
    scopeFinancialList,
    projectScopeOptions,
    financialCeilingFlow,
  } = useSelector((state) => ({
    project: state.projectReducer.project,
    scopeFinancialList: state.projectReducer.scopeFinancialList,
    projectScopeOptions: state.utilsReducer.dropdownItems.projectScopeItems,
    financialCeilingFlow: state.projectReducer.financialCeilingFlow,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      funding_sources: null,
    },
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        id: project.id,
        funding_sources: !isEmpty(fundingSourceValues)
          ? fundingSourceValues
          : [],
      };

      await dispatch(updateProject(newValues));

      const hideInput = async (field) => {
        await new Promise((resolve) => {
          setIsEdit((prevIsEdit) => ({
            ...prevIsEdit,
            [field]: false,
          }));
          resolve();
        });
      };

      const keys = Object.keys(values);

      const hideAllInputs = async () => {
        for (const key of keys) {
          await hideInput(key);
        }
      };

      hideAllInputs();

      showNotification();
    },
  });

  const handleEditField = (field) => {
    setIsEdit({
      ...isEdit,
      [field]: true,
    });
  };

  const handleCloseField = (field) => {
    setIsEdit({
      ...isEdit,
      [field]: false,
    });
  };

  const handleEditTable = (field) => {
    setIsEdit({
      ...isEdit,
      [field]: false,
    });
  };
  const handleCloseTable = (field) => {
    setIsEdit({
      ...isEdit,
      [field]: true,
    });

    if (field === "project_scope_financial") {
      const scopeList = project?.financial_details;
      dispatch(getScopeFinancialList(scopeList));
    } else {
      const ceilingFlow = project?.financial_details?.flatMap((obj1) => {
        const ceiling = obj1.ceiling_flow_details?.map((obj2) => {
          const updated = {
            ...obj2,
            cost_description: obj1.cost_description,
          };
          return updated;
        });
        return ceiling;
      });

      dispatch(getFinancialCeilingFlowList(ceilingFlow));
    }
  };

  const handleCheckFundingSources = (value) => {
    const num = Number(value);
    if (fundingSourceValues.includes(num)) {
      const removeItem = fundingSourceValues.filter((item) => item != num);
      setFundingSourceValues(removeItem);
    } else {
      setFundingSourceValues((prev) => [...prev, num]);
    }
  };

  const handleSaveForm = (e) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const handleChangeYear = (e) => {
    setYearInputValue(e.target.value);
  };

  const handleAddAllocationData = () => {
    const newItem = {
      project_id: project.id,
      year: "",
      federal_loan_reimbursement: "",
      federal_loan: "",
      state_fund: "",
    };

    setAllocationData((prev) => [...prev, newItem]);
  };

  const handleRemoveAllocationDraft = (idx) => {
    const removeItem = allocationData.filter((_, i) => i !== idx);

    setAllocationData(removeItem);
  };

  const handleAddScopeProject = () => {
    const tempId = `temp-${uuidv4()}`;
    const newValues = {
      id: tempId,
      project_id: project.id,
      cost_description: "",
      cost_required: "",
      ceiling: "",
      revised_ceiling: "",
      total_amount: "",
      financial_detail_stages: [],
    };

    dispatch(createScopeFinancial(newValues));
  };

  const handleSaveScopeProject = async () => {
    const params = {
      project_id: routeParams.projectId,
      values: scopeFinancialList,
    };
    await dispatch(createProjectScopeFinancial(params));

    setIsEdit({
      ...isEdit,
      ["project_scope_financial"]: true,
    });
  };

  const handleSaveProjectCeilingFlow = async () => {
    const params = {
      financial_detail_id: scopeFinancialList.map((item) => item.id),
      values: financialCeilingFlow,
    };
    await dispatch(createProjectCeilingFlow(params));

    setIsEdit({
      ...isEdit,
      ["ceiling_flow"]: true,
    });
  };

  const handleChangeScope = (e, value) => {
    const newScope = e.target.value;
    const newValues = {
      ...value,
      cost_description: newScope,
    };

    dispatch(updateScopeFinancial(newValues));
  };

  const handleRemoveScopeProject = (id) => {
    dispatch(deleteScopeFinancial(id));
  };

  const handleAddCeilingFlow = (year) => {
    const tempId = `temp-${uuidv4()}`;
    const newValues = {
      id: tempId,
      amount: "",
      revised_amount: "",
      financial_detail_id: "",
      year: Number(year),
      ceiling_flow_detail_stages: [],
    };
    dispatch(createFinancialCeilingFlow(newValues));
  };

  const handleChangeScopeCeilingFlow = (e, value) => {
    const newScope = e.target.value;
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedOptionId = selectedOption.getAttribute("id");

    const newValues = {
      ...value,
      cost_description: newScope,
      financial_detail_id: Number(selectedOptionId),
    };

    dispatch(updateFinancialCeilingFlow(newValues));
  };

  useEffect(() => {
    if (!isEmpty(project)) {
      const totalCostApproved = project?.financial_details?.reduce(
        (acc, item) => acc + parseFloat(item.cost_required),
        0,
      );

      setTotalProjectCostApproved(totalCostApproved);

      setFundingSourceValues(project?.funding_sources);

      const { first_year, second_year, third_year, fourth_year, fifth_year } =
        project?.rmk;

      setYearOptions([
        first_year,
        second_year,
        third_year,
        fourth_year,
        fifth_year,
      ]);

      const currentYear = new Date().getFullYear();

      setYearInputValue(currentYear);

      const scopeList = project?.financial_details;

      dispatch(getScopeFinancialList(scopeList));

      const ceilingFlow = project?.financial_details?.flatMap((obj1) => {
        const ceiling = obj1.ceiling_flow_details?.map((obj2) => {
          const updated = {
            ...obj2,
            cost_description: obj1.cost_description,
          };
          return updated;
        });
        return ceiling;
      });

      dispatch(getFinancialCeilingFlowList(ceilingFlow));
    }
    return () => {};
  }, [project]);

  useEffect(() => {
    const groupedData = yearOptions?.reduce((acc, year) => {
      const itemsForYear = financialCeilingFlow?.filter(
        (item) => item.year === year,
      );
      acc[year] = itemsForYear?.length > 0 ? itemsForYear : [];
      return acc;
    }, {});

    setCeilingFlowData(groupedData);
  }, [yearOptions, financialCeilingFlow]);

  useEffect(() => {
    const newValues = scopeFinancialList.map((item) => ({
      id: item.id,
      cost_description: item.cost_description,
      revised_ceiling: item.revised_ceiling,
    }));

    setCeilingFlowScopeOptions(newValues);

    const isTempIdExist = scopeFinancialList.some((item) => {
      const checkString = typeof item.id;

      return checkString === "string";
    });

    setDisableBtn(isTempIdExist);
  }, [scopeFinancialList]);

  useEffect(() => {
    let source = axios.CancelToken.source();
    const props = {
      source: source,
    };

    dispatch(getProjectScope(props));
    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Accordion.Header>
        <span className='fw-bolder'>MAKLUMAT KEWANGAN</span>
      </Accordion.Header>
      <Accordion.Body className='border-primary border-2 border-start p-0'>
        <div className='tab mb-0'>
          <Tab.Container defaultActiveKey='overview'>
            <Nav variant='tabs'>
              <Nav.Item>
                <Nav.Link eventKey='overview'>
                  <span
                    className='text-uppercase fw-bold'
                    style={{ fontSize: 12, color: "#757575" }}
                  >
                    Ringkasan
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className='text-uppercase'
                  eventKey='projectFinancial'
                >
                  <span
                    className='text-uppercase fw-bold'
                    style={{ fontSize: 12, color: "#757575" }}
                  >
                    Maklumat Kewangan
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className='text-uppercase' eventKey='dataByRmk'>
                  <span
                    className='text-uppercase fw-bold'
                    style={{ fontSize: 12, color: "#757575" }}
                  >
                    Maklumat Siling Rmk
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className='text-uppercase' eventKey='allocation'>
                  <span
                    className='text-uppercase fw-bold'
                    style={{ fontSize: 12, color: "#757575" }}
                  >
                    Maklumat Peruntukan
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className='text-uppercase' eventKey='transferFund'>
                  <span
                    className='text-uppercase fw-bold'
                    style={{ fontSize: 12, color: "#757575" }}
                  >
                    Maklumat Craw / Virement / ASP
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey='overview'>
                <Card.Body className='p-0'>
                  <div className='mb-3'>
                    <Card.Title className='text-sm mb-3'>
                      Maklumat Ringkas Kewangan Projek
                    </Card.Title>

                    <Form.Group className='mb-2'>
                      <Form.Label className='text-sm'>
                        Sumber Pembiayaan
                        <span
                          className='ms-3'
                          style={{ cursor: "pointer" }}
                          onClick={() => handleEditField("funding_sources")}
                        >
                          <Tooltip
                            arrow
                            title='Ubah status geran'
                            placement='right'
                          >
                            <Icon
                              path={mdiPencil}
                              size='18px'
                              color={palette.primary}
                            />
                          </Tooltip>
                        </span>
                      </Form.Label>
                      <div className='custom-controls-stacked'>
                        {fundingSourceOptions.map((item) => {
                          return (
                            <React.Fragment key={item.id}>
                              <Form.Check
                                className='me-2'
                                disabled={!isEdit.funding_sources}
                                key={item.id}
                                inline
                                type='checkbox'
                                id={item.id}
                                name='funding_sources'
                                value={item.id}
                                checked={fundingSourceValues?.includes(item.id)}
                                onChange={(e) =>
                                  handleCheckFundingSources(e.target.value)
                                }
                                isInvalid={
                                  formik.touched.funding_sources &&
                                  formik.errors.funding_sources
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className='me-3'
                                htmlFor={item.id}
                                style={{
                                  userSelect: "none",
                                  ...(isEdit.funding_sources
                                    ? {}
                                    : { color: "#6c757d" }),
                                }}
                              >
                                {item.value}
                              </label>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </Form.Group>
                    {isEdit.funding_sources && (
                      <div className='mb-3 d-flex justify-content-start gap-2'>
                        <Button
                          variant='success'
                          size='sm'
                          onClick={handleSaveForm}
                        >
                          <Icon path={mdiCheckBold} size={0.7} />
                        </Button>
                        <Button
                          variant='light'
                          size='sm'
                          onClick={() => handleCloseField("funding_sources")}
                        >
                          <Icon path={mdiCloseThick} size={0.7} />
                        </Button>
                      </div>
                    )}

                    <Row>
                      <Col md={6}>
                        <Table size='sm' bordered striped>
                          <tbody>
                            <tr>
                              <td
                                className='text-uppercase'
                                style={{ fontSize: 11 }}
                              >
                                Kos Keseluruhan Projek (RM)
                              </td>
                              <td className='text-end'>
                                {numeral(totalProjectCostApproved).format(
                                  "0,0.00",
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                className='text-uppercase'
                                style={{ fontSize: 11 }}
                              >
                                Kos Keseluruhan Projek Disemak (RM)
                              </td>
                              <td className='text-end'>
                                {numeral(totalProjectCostApproved).format(
                                  "0,0.00",
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                      <Col md={6}>
                        <Table size='sm' bordered striped>
                          <tbody>
                            <tr>
                              <td
                                className='text-uppercase'
                                style={{ fontSize: 11 }}
                              >
                                Belanja RMK Lepas (RM)
                              </td>
                              <td className='text-end'>
                                {numeral(0).format("0,0.00")}
                              </td>
                            </tr>
                            <tr>
                              <td
                                className='text-uppercase'
                                style={{ fontSize: 11 }}
                              >
                                Anggaran Baki Kos (RM)
                              </td>
                              <td className='text-end'>
                                {numeral(0).format("0,0.00")}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </div>

                  <div className='mb-3'>
                    <div className='d-flex justify-content-end mb-3'>
                      <Button
                        size='sm'
                        onClick={handleAddScopeProject}
                        disabled={isEdit.project_scope_financial}
                      >
                        + Skop Projek
                      </Button>
                    </div>
                    <div className='table-responsive'>
                      <Table size='sm'>
                        <thead className='custom-table-head text-uppercase'>
                          <tr>
                            <th className='text-center'>Bil.</th>
                            <th>Skop Projek</th>
                            <th>Siling (RM)</th>
                            <th>Siling Disemak (RM)</th>
                            <th className='text-center'>Tindakan</th>
                          </tr>
                        </thead>
                        <tbody>
                          {scopeFinancialList?.map((item, idx) => {
                            return (
                              <tr key={idx}>
                                <td className='text-center'>{idx + 1}</td>
                                <td>
                                  <Form.Select
                                    disabled={isEdit.project_scope_financial}
                                    value={item.cost_description}
                                    onChange={(e) => handleChangeScope(e, item)}
                                  >
                                    <option value=''>Pilih skop</option>
                                    {projectScopeOptions.map((opt, idx) => (
                                      <option key={idx} value={opt.value}>
                                        {opt.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </td>
                                <td>
                                  <Form.Control
                                    placeholder='0.00'
                                    as={CurrencyInput}
                                    min={0}
                                    autoComplete='off'
                                    decimalsLimit={2}
                                    disabled={isEdit.project_scope_financial}
                                    value={item?.cost_required}
                                    onChange={(e) => {
                                      const numberString = e.target.value;
                                      const number =
                                        parseFloat(
                                          numberString.replace(/,/g, ""),
                                        ) || 0;

                                      const newValues = {
                                        ...item,
                                        cost_required: number.toString(),
                                        ceiling: number.toString(),
                                      };
                                      dispatch(updateScopeFinancial(newValues));
                                    }}
                                  />
                                </td>
                                <td>
                                  <Form.Control
                                    placeholder='0.00'
                                    as={CurrencyInput}
                                    min={0}
                                    autoComplete='off'
                                    decimalsLimit={2}
                                    disabled={isEdit.project_scope_financial}
                                    value={item?.revised_ceiling}
                                    onChange={(e) => {
                                      const numberString = e.target.value;
                                      const number =
                                        parseFloat(
                                          numberString.replace(/,/g, ""),
                                        ) || 0;

                                      const newValues = {
                                        ...item,
                                        revised_ceiling: number.toString(),
                                      };
                                      dispatch(updateScopeFinancial(newValues));
                                    }}
                                  />
                                </td>
                                <td>
                                  <div className='d-flex justify-content-center gap-3'>
                                    <IconButton
                                      size='small'
                                      disabled={isEdit.project_scope_financial}
                                      sx={{ color: palette.danger }}
                                      onClick={() =>
                                        handleRemoveScopeProject(item.id)
                                      }
                                    >
                                      <Icon path={mdiDelete} size='18px' />
                                    </IconButton>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                    <div className='d-flex justify-content-end gap-3'>
                      {isEdit.project_scope_financial ? (
                        <>
                          <Tooltip title='Ubah Skop Projek' arrow>
                            <Button
                              size='sm'
                              onClick={() =>
                                handleEditTable("project_scope_financial")
                              }
                            >
                              Ubah
                            </Button>
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <Tooltip title='Simpan skop projek' arrow>
                            <Button
                              size='sm'
                              variant='success'
                              onClick={handleSaveScopeProject}
                            >
                              <Icon path={mdiCheckBold} size={0.7} />
                            </Button>
                          </Tooltip>
                          <Tooltip title='Batal' arrow>
                            <Button
                              size='sm'
                              variant='light'
                              onClick={() =>
                                handleCloseTable("project_scope_financial")
                              }
                            >
                              <Icon path={mdiCloseThick} size={0.7} />
                            </Button>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </div>

                  <div className='mb-3'>
                    <div className='table-responsive'>
                      <Table size='sm'>
                        <thead className='custom-table-head text-uppercase'>
                          <tr>
                            <th className='text-center'>Bil.</th>
                            <th>Skop Projek</th>
                            <th>Siling (RM)</th>
                            <th>Siling Disemak (RM)</th>
                            <th className='text-center'>Tindakan</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(ceilingFlowData).map(
                            ([year, items], idx) => {
                              return (
                                <React.Fragment key={year}>
                                  <tr>
                                    <td />
                                    <td colSpan={2} className='fw-bolder'>
                                      Tahun {year}
                                    </td>
                                    <td className='text-center'>
                                      <Tooltip
                                        title='Tambah aliran siling'
                                        arrow
                                      >
                                        <span>
                                          <IconButton
                                            size='small'
                                            sx={{ color: palette.primary }}
                                            onClick={() =>
                                              handleAddCeilingFlow(year)
                                            }
                                            disabled={isEdit.ceiling_flow}
                                          >
                                            <Icon
                                              path={mdiPlusBox}
                                              size='18px'
                                            />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                    </td>
                                  </tr>
                                  {items.map((item, subIdx) => {
                                    return (
                                      <tr key={subIdx}>
                                        <td className='text-center'>
                                          {subIdx + 1}
                                        </td>
                                        <td>
                                          <Form.Select
                                            value={item.cost_description}
                                            disabled={isEdit.ceiling_flow}
                                            onChange={(e) =>
                                              handleChangeScopeCeilingFlow(
                                                e,
                                                item,
                                              )
                                            }
                                          >
                                            <option value=''>Pilih skop</option>
                                            {ceilingFlowScopeOptions.map(
                                              (opt, idx) => (
                                                <option
                                                  key={idx}
                                                  value={opt.cost_description}
                                                  id={opt.id}
                                                >
                                                  {opt.cost_description}
                                                </option>
                                              ),
                                            )}
                                          </Form.Select>
                                        </td>
                                        <td>
                                          <Form.Control
                                            placeholder='0.00'
                                            as={CurrencyInput}
                                            min={0}
                                            autoComplete='off'
                                            decimalsLimit={2}
                                            disabled={isEdit.ceiling_flow}
                                            value={item?.amount}
                                            onChange={(e) => {
                                              const numberString =
                                                e.target.value;
                                              const number =
                                                parseFloat(
                                                  numberString.replace(
                                                    /,/g,
                                                    "",
                                                  ),
                                                ) || 0;

                                              const newValues = {
                                                ...item,
                                                amount: number.toString(),
                                              };
                                              dispatch(
                                                updateFinancialCeilingFlow(
                                                  newValues,
                                                ),
                                              );
                                            }}
                                          />
                                        </td>
                                        <td>
                                          <Form.Control
                                            placeholder='0.00'
                                            as={CurrencyInput}
                                            min={0}
                                            autoComplete='off'
                                            decimalsLimit={2}
                                            disabled={isEdit.ceiling_flow}
                                            value={item?.revised_amount}
                                            onChange={(e) => {
                                              const numberString =
                                                e.target.value;
                                              const number =
                                                parseFloat(
                                                  numberString.replace(
                                                    /,/g,
                                                    "",
                                                  ),
                                                ) || 0;

                                              const newValues = {
                                                ...item,
                                                revised_amount:
                                                  number.toString(),
                                              };
                                              dispatch(
                                                updateFinancialCeilingFlow(
                                                  newValues,
                                                ),
                                              );
                                            }}
                                          />
                                        </td>
                                        <td>
                                          <div className='d-flex justify-content-center gap-3'>
                                            <IconButton
                                              size='small'
                                              sx={{ color: palette.danger }}
                                              disabled={isEdit.ceiling_flow}
                                              onClick={() =>
                                                dispatch(
                                                  deleteFinancialCeilingFlow(
                                                    item.id,
                                                  ),
                                                )
                                              }
                                            >
                                              <Icon
                                                path={mdiDelete}
                                                size='18px'
                                              />
                                            </IconButton>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </React.Fragment>
                              );
                            },
                          )}
                        </tbody>
                      </Table>
                    </div>

                    <div className='d-flex justify-content-end gap-3'>
                      {isEdit.ceiling_flow ? (
                        <>
                          <Tooltip
                            title={
                              disableBtn
                                ? "Sila simpan/batal maklumat skop projek"
                                : "Ubah aliran siling"
                            }
                            arrow
                          >
                            <span>
                              <Button
                                size='sm'
                                onClick={() => handleEditTable("ceiling_flow")}
                                disabled={disableBtn}
                              >
                                Ubah
                              </Button>
                            </span>
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <Tooltip title='Simpan aliran siling' arrow>
                            <Button
                              size='sm'
                              variant='success'
                              onClick={handleSaveProjectCeilingFlow}
                            >
                              <Icon path={mdiCheckBold} size={0.7} />
                            </Button>
                          </Tooltip>
                          <Tooltip title='Batal' arrow>
                            <Button
                              size='sm'
                              variant='light'
                              onClick={() => handleCloseTable("ceiling_flow")}
                            >
                              <Icon path={mdiCloseThick} size={0.7} />
                            </Button>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Tab.Pane>
              <ProjectFinancial eventKey='projectFinancial' />
              <DataByRmk eventKey='dataByRmk' />
              <AllocationData eventKey='allocation' />
              <TransferFund eventKey='transferFund' />
            </Tab.Content>
          </Tab.Container>
        </div>
      </Accordion.Body>
    </React.Fragment>
  );
};

export default FinancialInfo;
