import { isEmpty } from "lodash";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

const MinistriesPerformance = ({ onClickMinistry }) => {
  const [totalFund, setTotalFund] = useState(0);
  const [totalExpenditure, setTotalExpenditure] = useState(0);
  const [totalCurrentBalance, setTotalCurrentBalance] = useState(0);
  const [overallPercentage, setOverallPercentage] = useState(0);

  const { data } = useSelector((state) => ({
    data: state.dashboardReducer.overall.charts.financialReportByMinistries,
  }));

  useEffect(() => {
    if (isEmpty(data)) return null;

    const calcFund = data?.reduce(
      (acc, item) => (acc += parseFloat(item.fund)),
      0,
    );
    const calcExpenditure = data?.reduce(
      (acc, item) => (acc += parseFloat(item.expenditure)),
      0,
    );
    const calcCurrentBalance = data?.reduce(
      (acc, item) => (acc += parseFloat(item.current_balance)),
      0,
    );
    const calcPercentage = (calcExpenditure / calcFund) * 100;

    setTotalFund(calcFund);
    setTotalExpenditure(calcExpenditure);
    setTotalCurrentBalance(calcCurrentBalance);
    setOverallPercentage(calcPercentage);
  }, [data]);

  return (
    <React.Fragment>
      <Table hover size='sm' style={{ fontSize: "12px", fontWeight: 700 }}>
        <thead className='text-center'>
          <tr className='text-uppercase'>
            <th>Agensi</th>
            <th>
              Peruntukan<div>(RM)</div>
            </th>
            <th>
              Perbelanjaan<div>(RM)</div>
            </th>
            <th>
              Baki Peruntukan<div>(RM)</div>
            </th>
            <th>Peratus Perbelanjaan</th>
          </tr>
        </thead>
        <tbody className='text-end'>
          {data?.map((item, idx) => {
            const expenditurePercentage =
              (item.expenditure / item.fund) * 100 || 0;
            return (
              <tr
                key={idx}
                style={{ cursor: "pointer" }}
                onClick={() => onClickMinistry(item.code)}
              >
                <td className='text-start ps-2'>
                  <span className='me-2'>{item.code}</span>
                  {item.name}
                </td>
                <td
                  className='text-white'
                  style={{ backgroundColor: "#64b5f6" }}
                >
                  {numeral(item.fund).format("0,0.00")}
                </td>
                <td
                  className='text-white'
                  style={{ backgroundColor: "#81c784" }}
                >
                  {numeral(item.expenditure).format("0,0.00")}
                </td>
                <td>{numeral(item.current_balance).format("0,0.00")}</td>
                <td className='text-center'>
                  {expenditurePercentage.toFixed(2)} %
                </td>
              </tr>
            );
          })}
          <tr style={{ borderTop: "double" }}>
            <td className='text-start text-uppercase'>Jumlah</td>
            <td className='text-white' style={{ backgroundColor: "#64b5f6" }}>
              {numeral(totalFund).format("0,0.00")}
            </td>
            <td className='text-white' style={{ backgroundColor: "#81c784" }}>
              {numeral(totalExpenditure).format("0,0.00")}
            </td>
            <td>{numeral(totalCurrentBalance).format("0,0.00")}</td>
            <td className='text-center'> {overallPercentage.toFixed(2)} %</td>
          </tr>
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default MinistriesPerformance;
