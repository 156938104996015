import React from "react";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { isEmpty, isNull } from "lodash";

// components
import InitialAllocation from "./components/cards/InitialAllocation";
import RevisedAllocation from "./components/cards/RevisedAllocation";
import LatestExpenditure from "./components/cards/LatestExpenditure";
import OverallExpenditure from "./components/charts/OverallExpenditure";
import ProjectLocation from "./components/maps/ProjectLocation";
import PhysicalReport from "./components/charts/PhysicalReport";
import Projects from "./components/projects/Projects";
import FinancialPerformance from "./components/financial-performance/FinancialPerformance";

const container = {
  show: {
    transition: {
      staggerChildren: 0.15,
    },
  },
};
const itemTop = {
  hidden: { opacity: 0, y: -20 },
  show: { opacity: 1, y: 0 },
};
const itemLeft = {
  hidden: { opacity: 0, x: -30 },
  show: { opacity: 1, x: 0 },
};

const OverallReport = ({ rmkValue }) => {
  const { navFilter } = useSelector((state) => ({
    navFilter: state.dashboardReducer.navFilter,
  }));

  return (
    <React.Fragment>
      <motion.div variants={container} initial='hidden' animate='show'>
        <Row>
          <Col
            as={motion.div}
            variants={itemTop}
            transition={{ duration: 0.5 }}
            className='d-flex'
          >
            <InitialAllocation />
          </Col>
          <Col
            as={motion.div}
            variants={itemTop}
            transition={{ duration: 0.5 }}
            className='d-flex'
          >
            <RevisedAllocation />
          </Col>
          <Col
            as={motion.div}
            variants={itemTop}
            transition={{ duration: 0.5 }}
            className='d-flex'
          >
            <LatestExpenditure />
          </Col>
        </Row>

        <Row>
          <Col md={6} className='d-flex'>
            <OverallExpenditure itemLeft={itemLeft} />
          </Col>
          <Col md={6} className='d-flex'>
            <FinancialPerformance />
          </Col>
        </Row>

        <Row>
          <Col className='d-flex'>
            <ProjectLocation itemLeft={itemLeft} />
          </Col>
          <Col className='d-flex'>
            <PhysicalReport itemLeft={itemLeft} />
          </Col>
        </Row>

        <Projects itemLeft={itemLeft} />
      </motion.div>
    </React.Fragment>
  );
};

export default OverallReport;
