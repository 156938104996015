import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import numeral from "numeral";
import { Badge, Button } from "react-bootstrap";

import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";

import "./styles.css";

import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";

import { AG_GRID_LOCALE_MS } from "../../../locales/locale";

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  RowGroupingModule,
  CsvExportModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  SetFilterModule,
]);

const AnalysisTable = ({ gridRef, onGridReady, rowData }) => {
  const [years, setYears] = useState([]);

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "85vh", width: "100%" }), []);

  const excelStyles = [
    {
      id: "text-end",
      alignment: {
        horizontal: "Right",
      },
    },
    {
      id: "text-center",
      alignment: {
        horizontal: "Center",
      },
    },
  ];

  const colDefs = useMemo(() => {
    let rowIndexCounter = 0;
    return [
      {
        headerName: "#",
        valueGetter: (params) => {
          // Ensure params.node and its parent exist and that the node is not a group
          if (params.node && !params.node.group && params.node.parent) {
            // Safely retrieve all child nodes of the parent
            const allRowsAtLevel = params.node.parent.childrenAfterGroup;

            // Find the current node's index among its siblings
            const index = allRowsAtLevel.findIndex(
              (node) => node === params.node,
            );

            // Return the 1-based index
            return index + 1;
          }
          return ""; // Return empty for group headers or if parent is not defined
        },
        cellClass: "text-center",
        width: 100,
        suppressSizeToFit: true,
      },
      {
        colId: "reference_no",
        headerName: "NO. RUJUKAN",
        field: "reference_no",
        width: 170,
        suppressSizeToFit: true,
        filter: true,
        filterParams: {
          filterOptions: ["contains"],
          maxNumConditions: 0,
        },
        cellRenderer: ({ data }) => {
          return <div className='text-truncate'>{data?.reference_no}</div>;
        },
      },
      {
        colId: "district_office_reference_no",
        headerName: "NO. RUJUKAN PEJABAT DAERAH",
        field: "district_office_reference_no",
        width: 140,
        suppressSizeToFit: true,
      },
      {
        colId: "main_project",
        headerName: "PROJEK UTAMA",
        field: "main_project.name",
        enableRowGroup: true,
        rowGroup: true,
        width: 400,
        suppressSizeToFit: true,
        filter: true,
        filterParams: {
          filterOptions: ["contains"],
          maxNumConditions: 0,
        },

        valueGetter: ({ data }) => {
          // Return the value to be used for filtering

          return data?.main_project?.name; // or any other logic to get the value
        },
      },
      {
        colId: "project_name",
        headerName: "NAMA PROJEK",
        field: "name",
        width: 400,
        suppressSizeToFit: true,
        filter: true,
        filterParams: {
          filterOptions: ["contains"],
          maxNumConditions: 0,
        },
      },
      {
        colId: "district",
        headerName: "DAERAH",
        field: "district.name",
        enableRowGroup: true,
        width: 200,
        suppressSizeToFit: true,
        filter: true,
        filterParams: {
          filterOptions: ["contains"],
          maxNumConditions: 0,
        },
        // cellRenderer: ({ data }) => {
        //   const district = data?.district;
        //   return (
        //     <div className='d-flex justify-content-center'>
        //       {district?.name}
        //     </div>
        //   );
        // },
        valueGetter: ({ data }) => {
          // Return the value to be used for filtering
          return data?.district?.name; // or any other logic to get the value
        },
      },
      {
        colId: "parliament",
        headerName: "PARLIMEN",
        field: "parliament.name",
        enableRowGroup: true,
        width: 200,
        suppressSizeToFit: true,
        filter: true,
        filterParams: {
          filterOptions: ["contains"],
          maxNumConditions: 0,
        },
        // cellRenderer: ({ data }) => {
        //   const parliament = data?.parliament;
        //   return (
        //     <div className='d-flex justify-content-center'>
        //       {parliament && `${parliament?.code} - ${parliament?.name}`}
        //     </div>
        //   );
        // },
        valueGetter: ({ data }) => {
          // Return the value to be used for filtering
          return data?.parliament?.name; // or any other logic to get the value
        },
      },
      {
        colId: "stateLegaslativeAssembly",
        headerName: "DUN",
        field: "state_legaslative_assembly",
        enableRowGroup: true,
        width: 200,
        suppressSizeToFit: true,
        filter: true,
        filterParams: {
          filterOptions: ["contains"],
          maxNumConditions: 0,
        },
        // cellRenderer: ({ data }) => {
        //   const stateLegaslativeAssembly = data?.state_legaslative_assembly;
        //   return (
        //     <div className='d-flex justify-content-center'>
        //       {stateLegaslativeAssembly &&
        //         `${stateLegaslativeAssembly?.code} - ${stateLegaslativeAssembly?.name}`}
        //     </div>
        //   );
        // },
        valueGetter: ({ data }) => {
          // Return the value to be used for filtering
          return data?.state_legaslative_assembly?.name; // or any other logic to get the value
        },
      },
      {
        colId: "location",
        headerName: "LOKASI",
        field: "location",
        width: 200,
        suppressSizeToFit: true,
        sortable: true,
        cellRenderer: ({ data }) => {
          return <div>{data?.location}</div>;
        },
      },
      {
        colId: "justifications",
        headerName: "JUSTIFIKASI",
        field: "justifications",
        width: 200,
        suppressSizeToFit: true,
        sortable: true,
        cellRenderer: ({ data }) => {
          return <div>{data?.justifications}</div>;
        },
      },
      {
        colId: "site_status",
        headerName: "STATUS TANAH",
        field: "site_status",
        width: 200,
        suppressSizeToFit: true,
        sortable: true,
        cellRenderer: ({ data }) => {
          return <div>{data?.site_status}</div>;
        },
      },
      {
        colId: "justifications",
        headerName: "JUSTIFIKASI",
        field: "justifications",
        width: 200,
        suppressSizeToFit: true,
        sortable: true,
        cellRenderer: ({ data }) => {
          return <div>{data?.justifications}</div>;
        },
      },
      {
        colId: "cost_requirement",
        headerName: "KOS DIPOHON (RM)",
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const costRequirement = parseFloat(data?.cost_requirement);
          return numeral(costRequirement).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        colId: "cost_approved",
        headerName: "KOS DILULUSKAN (RM)",
        suppressSizeToFit: true,
        width: 350,
        cellClass: "ag-right-aligned-cell",
        valueGetter: ({ data }) => {
          const costSuggested = parseFloat(data?.cost_approved);
          return numeral(costSuggested).format("0,0.00");
        },
        aggFunc: "jumlah",
      },
      {
        colId: "application_status",
        headerName: "STATUS PERMOHONAN",
        field: "application_status",
        enableRowGroup: true,
        cellClass: "text-center",
        width: 200,
        suppressSizeToFit: true,
        cellRenderer: ({ data }) => {
          const status = data?.application_status;
          const label = data?.application_status_name;

          let color = "";

          switch (status) {
            case 2:
              color = "warning";
              break;
            case 3:
              color = "primary";
              break;
            case 4:
              color = "success";
              break;
            case 5:
              color = "danger";
              break;
          }
          return (
            <div className='d-flex justify-content-center p-1'>
              <Badge
                bg={color}
                className='text-uppercase w-100'
                style={{ fontSize: "80%" }}
              >
                {label}
              </Badge>
            </div>
          );
        },
        filter: true,
        filterParams: {
          filterOptions: ["contains"],
          maxNumConditions: 0,
        },
        valueGetter: ({ data }) => {
          // Return the value to be used for filtering
          return data?.application_status_name; // or any other logic to get the value
        },
      },
    ];
  }, []);

  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_MS;
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      cellDataType: false,
      minWidth: 100,
      suppressSizeToFit: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      resizable: true,
      cellStyle: {
        lineHeight: "25px",
        wordBreak: "normal",
      },
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
      pinned: "left",
    };
  }, []);

  const aggFuncs = useMemo(() => {
    return {
      // this overrides the grids built in sum function
      jumlah: ({ values }) => {
        const total = values
          .map((val) => numeral(val).value())
          .reduce((acc, val) => (acc += parseFloat(val)), 0);
        return numeral(total).format("0,0.00");
      },
    };
  }, []);

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      // Example of using the API
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [rowData]);

  return (
    <React.Fragment>
      <div style={containerStyle}>
        <div className='grid-wrapper'>
          <div style={gridStyle} className={"ag-theme-alpine"}>
            <AgGridReact
              // localeText={localeText}
              excelStyles={excelStyles}
              ref={gridRef}
              rowData={rowData}
              aggFuncs={aggFuncs}
              columnDefs={colDefs}
              defaultColDef={defaultColDef}
              sideBar={true}
              onGridReady={onGridReady}
              groupTotalRow={"bottom"}
              autoGroupColumnDef={autoGroupColumnDef}
              suppressDragLeaveHidesColumns={true}
              suppressMakeColumnVisibleAfterUnGroup={true}
              suppressRowGroupHidesColumns={true}
              rowGroupPanelShow={"always"}
              groupIncludeFooter={true}
              groupIncludeTotalFooter={true}
              suppressAggFuncInHeader={true}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AnalysisTable;
