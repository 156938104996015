import React, { useEffect, useState } from "react";
import { Badge, Form, Pagination, Table } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import numeral from "numeral";
import { TweleColumns } from "../../../../components/common/tableLoading";
import { isEmpty } from "lodash";

// Icons
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { FaCheck } from "react-icons/fa";

// redux
import {
  deleteMicroProject,
  getApplicationStatistics,
  getProjectsApproval,
} from "../../../../redux/slices/micro-project/microProjectSlice";
import {
  setPage,
  setPerPage,
  setIsSearching,
} from "../../../../redux/slices/micro-project/microProjectSlices";

const Projects = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [item, setItem] = useState(null);
  const [showRemove, setShowRemove] = useState(false);
  const [goToPage, setGoToPage] = useState("");

  const {
    microProjects,
    isLoading,
    isSearching,
    keywords,
    page,
    perPage,
    selectDistrict,
    searchApplicationStatus,
  } = useSelector((state) => ({
    isLoading: state.microProjectReducer.isLoading,
    microProjects: state.microProjectReducer.microProjects,
    page: state.microProjectReducer.page,
    perPage: state.microProjectReducer.perPage,
    isSearching: state.microProjectReducer.isSearching,
    keywords: state.microProjectReducer.keywords,
    selectDistrict: state.microProjectReducer.selectDistrict,
    searchApplicationStatus: state.microProjectReducer.searchApplicationStatus,
  }));

  const { data, meta, links } = microProjects;

  const handleViewProject = (item) => {
    navigate(
      `/projek-mikro/daftar-projek/${item.ministry_id}/kelulusan/${item.id}`,
    );
  };

  const openConfirmRemove = (item) => {
    setItem(item);
    setShowRemove(true);
  };

  const handleConfirmDelete = async (item) => {
    const { id } = item;
    await dispatch(deleteMicroProject(id));
    setShowRemove(false);
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initialize = async () => {
      const props = {
        source: source,
        params: {
          per_page: perPage,
          page: page,
          keywords: keywords,
          district_id: selectDistrict?.id,
          filter_application_status: searchApplicationStatus,
        },
      };

      await dispatch(getApplicationStatistics(props));
      await dispatch(getProjectsApproval(props));
    };

    setGoToPage(page);

    initialize();

    dispatch(setIsSearching(false));

    return () => {
      source.cancel();
    };
  }, [dispatch, page, perPage, isSearching, searchApplicationStatus]);

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table size='sm'>
          <thead
            className='text-uppercase custom-table-head'
            style={{ fontSize: 11 }}
          >
            <tr>
              <th className='text-center'>Bil</th>
              <th>Tarikh Didaftarkan</th>
              <th>No. Rujukan</th>
              <th>No. Rujukan Pejabat Daerah</th>
              <th>Nama Projek</th>
              <th>Lokasi</th>
              <th className='text-center'>Daerah</th>
              <th className='text-center'>
                Kos Dipohon <br />
                (RM)
              </th>
              <th className='text-center'>
                Kos Diluluskan <br />
                (RM)
              </th>
              <th className='text-center'>Status</th>
              {/* <th className='text-center'>Dikemas kini Oleh</th> */}
              <th className='text-center'>Dikemas kini Pada</th>
              <th className='text-center'>Tindakan</th>
            </tr>
          </thead>
          {isLoading && <TweleColumns />}
          {!isLoading && (
            <tbody>
              {isEmpty(data) ? (
                <tr>
                  <td colSpan={12} className='text-center'>
                    Tiada rekod
                  </td>
                </tr>
              ) : (
                <>
                  {data?.map((item, idx) => {
                    const newValues = {
                      ...item,
                      index: idx + 1,
                    };

                    let badgeColor = "";

                    switch (item.application_status) {
                      case 2:
                        badgeColor = "warning";
                        break;
                      case 3:
                        badgeColor = "info";
                        break;
                      case 4:
                        badgeColor = "success";
                        break;
                      case 5:
                        badgeColor = "danger";
                        break;

                      default:
                        badgeColor = "secondary";
                        break;
                    }

                    return (
                      <tr key={idx}>
                        <td className='text-center'>{idx + 1}</td>
                        <td className='text-center'>
                          {item.application_sent_at
                            ? format(
                                new Date(item.application_sent_at),
                                "dd/MM/yyy, hh:mm a",
                              )
                            : "-"}
                        </td>
                        <td>{item.reference_no || "-"}</td>
                        <td>{item.district_office_reference_no || "-"}</td>
                        <td>{item.name || "-"}</td>
                        <td>{item.location || "-"}</td>
                        <td className='text-center'>
                          {item?.district?.name || "-"}
                        </td>
                        <td className='text-end'>
                          {numeral(item.cost_requirement).format("0,0.00")}
                        </td>
                        <td className='text-end'>
                          {numeral(item.cost_approved).format("0,0.00")}
                        </td>
                        <td className='text-center px-4'>
                          <Badge
                            bg={badgeColor}
                            className='w-100 text-uppercase'
                          >
                            {item.application_status_name}
                          </Badge>
                        </td>
                        {/* <td className='text-center'>Pegawai 1</td> */}
                        <td>
                          {format(
                            new Date(item.updated_at),
                            "dd/MM/yyy, hh:mm a",
                          )}
                        </td>
                        <td>
                          <div className='d-flex justify-content-center'>
                            <Tooltip title='Lihat' placement='top' arrow>
                              <span>
                                <IconButton
                                  color='info'
                                  size='small'
                                  aria-label='Edit'
                                  onClick={() => handleViewProject(item)}
                                >
                                  <MdModeEditOutline fontSize='large' />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          )}
        </Table>
        <div className='d-flex justify-content-between'>
          <div>
            <span className=''>
              Halaman{" "}
              <strong>
                {meta?.current_page} daripada {meta?.last_page}
              </strong>
              <span className='ms-3 me-2'>Tunjuk:</span>
              <Form.Select
                className='d-inline-block w-auto'
                value={perPage}
                onChange={(e) => {
                  const value = e.target.value;

                  dispatch(setPerPage(value));
                  dispatch(setPage(1));
                }}
              >
                {[15, 30, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Form.Select>
            </span>
            <span className='ms-3 me-2'>Pergi ke halaman:</span>
            <Form.Control
              className='d-inline-block'
              type='number'
              value={goToPage}
              onChange={(e) => {
                const value = e.target.value;
                setGoToPage(value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(setPage(goToPage));
                }
              }}
              style={{ width: "75px" }}
            />
          </div>
          <Pagination>
            <Pagination.First
              onClick={() => {
                dispatch(setPage(1));
              }}
              disabled={links?.prev === null ? true : false}
            />
            <Pagination.Prev
              onClick={() => {
                dispatch(setPage(meta?.current_page - 1));
              }}
              disabled={links?.prev === null ? true : false}
            />
            <Pagination.Next
              onClick={() => {
                dispatch(setPage(meta?.current_page + 1));
              }}
              disabled={links?.next === null ? true : false}
            />
            <Pagination.Last
              onClick={() => {
                dispatch(setPage(meta?.last_page));
              }}
              disabled={links?.next === null ? true : false}
            />
          </Pagination>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Projects;
