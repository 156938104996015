import React from "react";
import { Button } from "react-bootstrap";

const ReturnButton = ({ returnApplication }) => {
  return (
    <React.Fragment>
      <Button variant='danger' onClick={returnApplication}>
        Tolak Permohonan
      </Button>
    </React.Fragment>
  );
};

export default ReturnButton;
