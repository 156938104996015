import React, { useEffect } from "react";
import Breadcrumbs from "../../../../components/common/Breadcrumb";
import { Container, Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

// Components
import Projects from "./components/tables/Projects";

// Redux
import { fetchProjects } from "../../../../redux/slices/jppn/jppnModuleSlice";

const MainPage = () => {
  const dispatch = useDispatch();

  const { currentRmk, page, perPage, keywords } = useSelector((state) => ({
    currentRmk: state.rmkReducer.activeRmk,
    page: state.jppnReducer.projects.page,
    perPage: state.jppnReducer.projects.perPage,
    keywords: state.jppnReducer.projects.keywords,
  }));

  useEffect(() => {
    let source = axios.CancelToken.source();

    const props = {
      source: source,
      params: {
        rmk_id: currentRmk.number,
        per_page: perPage,
        page: page,
        keywords: keywords,
      },
    };

    const initialize = async () => {
      await dispatch(fetchProjects(props));
    };

    initialize();
    return () => {
      source.cancel();
    };
  }, [dispatch, currentRmk, page, perPage, keywords]);

  return (
    <React.Fragment>
      <Container fluid className='p-0'>
        <Breadcrumbs title='JPPN' breadcrumbItem='Penyenaraian Projek' />

        <Card className='mt-3'>
          <Card.Body>
            <div className='d-flex justify-content-end mb-3'>
              <Button>+ Projek</Button>
            </div>
            <Projects />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
