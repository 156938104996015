import React, { useEffect } from "react";
import { Card, Container, Nav, Tab } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import axios from "axios";

// Components
import Projects from "./components/tables/Projects";
import Filters from "./components/Filters";
import CeilingVirement from "./components/tab-content/CeilingVirement";

// redux
import { getVirementApplications } from "../../../redux/slices/jppn/jppnCeilingVirementSlice";
import { getProjectNameChangesApplications } from "../../../redux/slices/jppn/jppnProjectNameChanges";
import EditProjectName from "./components/tab-content/EditProjectName";
// import { getNewProjectList } from "../../../redux/slices/jppn/jppnNewProjectListingSlice";

const MainPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initialize = async () => {
      const props = {
        source: source,
        params: {
          ministry_comments: !null,
          mof_comments: !null,
          upen_comments: !null,
          closing: !null,
        },
      };
      await dispatch(getVirementApplications(props));
      await dispatch(getProjectNameChangesApplications(props));
    };

    initialize();

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Helmet title='Perakuan JPPN | Senarai Projek' />

      <Container fluid className='p-0'>
        <div className='mb-4'>
          <h4>Senarai permohonan yang sedang menunggu kelulusan.</h4>
        </div>
        <div className='tab'>
          <Tab.Container>
            <Nav variant='tabs' fill>
              <Nav.Item>
                <Nav.Link eventKey='ceiling-virement'>Kenaikan Siling</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey='edit-project-name'>Ubah Tajuk</Nav.Link>
              </Nav.Item> */}
            </Nav>

            <Tab.Content>
              <CeilingVirement eventKey='ceiling-virement' />
              <EditProjectName eventKey='edit-project-name' />
            </Tab.Content>
          </Tab.Container>
        </div>

        {/* <Card>
          <Card.Header className='pb-0'>
            <p className='text-sm'>
              Senarai projek yang sedang menunggu diperaku oleh JPPN.
            </p>
            <Filters />
          </Card.Header>
          <Card.Body>
            <Projects />
          </Card.Body>
        </Card> */}
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
