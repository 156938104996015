import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrumbs from "../../../components/common/Breadcrumb";

// content
import PageContent from "./PageContent";
import NewApplication from "./components/cards/NewApplication";
import ApprovedApplication from "./components/cards/ApprovedApplication";
import RejectedApplication from "./components/cards/RejectedApplication";
import InAttentionApplication from "./components/cards/InAttentionApplication";

const MainPage = () => {
  return (
    <React.Fragment>
      <Container fluid className='p-0'>
        <Breadcrumbs
          title='Projek Mikro'
          breadcrumbItem='Senarai Projek Mikro'
        />
        <Row className='mt-3'>
          <Col className='d-flex'>
            <NewApplication />
          </Col>
          <Col>
            <InAttentionApplication />
          </Col>
          <Col className='d-flex'>
            <ApprovedApplication />
          </Col>
          <Col className='d-flex'>
            <RejectedApplication />
          </Col>
        </Row>

        <PageContent />
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
