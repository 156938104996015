import numeral from "numeral";
import React from "react";
import { Form, Pagination, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FiveColumns } from "../../../../../../components/common/tableLoading";

// icons
import Icon from "@mdi/react";
import { mdiCheckCircle } from "@mdi/js";

// Redux
import {
  setPage,
  setPerPage,
} from "../../../../../../redux/slices/jppn/jppnModuleSlice";

const Projects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, projects, meta, links, perPage, isLoading } = useSelector(
    (state) => ({
      user: state.userReducer.user,
      projects: state.jppnReducer.projects.data,
      meta: state.jppnReducer.projects.meta,
      links: state.jppnReducer.projects.links,
      perPage: state.jppnReducer.projects.perPage,
      isLoading: state.jppnReducer.projects.isLoading,
    }),
  );

  const handleViewProjectProfile = (project) => {
    navigate(`/permohonan/jppn/perubahan-skop/${project.id}`);
  };

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <Table hover size='sm' style={{ width: "100%" }}>
          <thead className='custom-table-head text-uppercase'>
            <tr>
              <th className='text-center'>Bil.</th>
              <th style={{ width: "40%" }}>Nama Sub Projek</th>
              <th className='text-center'>Kementerian</th>
              <th>Jabatan</th>
              <th className='text-center'>Siling Disemak (RM)</th>
            </tr>
          </thead>
          {isLoading ? (
            <FiveColumns />
          ) : (
            <tbody>
              {projects?.map((project, idx) => {
                const projectCeiling = project?.financial_details
                  ?.flatMap((obj) => obj.ceiling_flow_details)
                  ?.reduce((acc, obj) => (acc += parseFloat(obj.amount)), 0);
                return (
                  <tr
                    key={project.id}
                    onClick={() => handleViewProjectProfile(project)}
                    style={{ cursor: "pointer" }}
                  >
                    <td className='text-center'>
                      {(meta?.current_page - 1) * meta?.per_page + idx + 1}
                    </td>
                    <td>{project?.name}</td>
                    <td className='text-center'>
                      {project?.ministry?.short_form}
                    </td>
                    <td>{project?.department?.name}</td>

                    <td className='text-truncate text-end'>
                      {numeral(projectCeiling).format("0,0.00")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
        <div className='d-flex justify-content-between'>
          <div>
            <span className=''>
              Halaman{" "}
              <strong>
                {meta?.current_page} daripada {meta?.last_page}
              </strong>
              <span className='ms-3 me-2'>Tunjuk:</span>
              <Form.Select
                className='d-inline-block w-auto'
                value={perPage}
                onChange={(e) => {
                  const value = e.target.value;

                  dispatch(setPerPage(value));
                }}
              >
                {[15, 30, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Form.Select>
            </span>
            <span className='ms-3 me-2'>Pergi ke halaman:</span>
            <Form.Control
              className='d-inline-block'
              type='number'
              min={1}
              // value={1}
              // onChange={(e) => {
              //   const page = e.target.value ? Number(e.target.value) - 1 : 0;
              //   gotoPage(page);
              // }}
              style={{ width: "75px" }}
            />
          </div>
          <Pagination>
            <Pagination.First
              onClick={() => {
                dispatch(setPage(1));
              }}
              disabled={links?.prev === null ? true : false}
            />
            <Pagination.Prev
              onClick={() => {
                dispatch(setPage(meta?.current_page - 1));
              }}
              disabled={links?.prev === null ? true : false}
            />
            <Pagination.Next
              onClick={() => {
                dispatch(setPage(meta?.current_page + 1));
              }}
              disabled={links?.next === null ? true : false}
            />
            <Pagination.Last
              onClick={() => {
                dispatch(setPage(meta?.last_page));
              }}
              disabled={links?.next === null ? true : false}
            />
          </Pagination>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Projects;
