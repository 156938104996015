import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const getVirementApplications = createAsyncThunk(
  "jppnCeilingVirementSlice/getVirementApplications",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(`jppn/ceiling-virement`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const createVirementApplication = createAsyncThunk(
  "jppnCeilingVirementSlice/createVirementApplication",
  async (values) => {
    try {
      const response = await axios.post(`jppn/ceiling-virement`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getVirementApplication = createAsyncThunk(
  "jppnCeilingVirementSlice/getVirementApplication",
  async (params) => {
    try {
      const { id, source } = params;
      const response = await axios.get(`jppn/ceiling-virement/${id}`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const updateVirementApplication = createAsyncThunk(
  "jppnCeilingVirementSlice/updateVirementApplication",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.put(`jppn/ceiling-virement/${id}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const approveVirementApplication = createAsyncThunk(
  "jppnCeilingVirementSlice/approveVirementApplication",
  async (id) => {
    try {
      const response = await axios.put(`jppn/ceiling-virement/approve/${id}`);

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const deleteVirementApplication = createAsyncThunk(
  "jppnCeilingVirementSlice/deleteVirementApplication",
  async (id) => {
    try {
      const response = await axios.delete(`jppn/ceiling-virement/delete/${id}`);

      const data = await response.data;

      return id;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);
