import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import numeral from "numeral";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

// icons
import Icon from "@mdi/react";
import { mdiArrowExpand } from "@mdi/js";

// modals
import ExpandOverallExpenditure from "../modals/ExpandOverallExpenditure";

const OverallExpenditure = ({ itemLeft }) => {
  const [series, setSeries] = useState([]);
  const [expand, setExpand] = useState(false);

  const { selectYear, overallExpenditure, revisedAllocation } = useSelector(
    (state) => ({
      overallExpenditure:
        state.dashboardReducer.overall.charts.overallExpenditure,
      revisedAllocation: state.dashboardReducer.overall.cards.revisedAllocation,
      selectYear: state.dashboardReducer.selectYear,
    }),
  );

  const options = {
    chart: {
      height: 350,
      type: "line",
      toolbar: { show: false },
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.4,
      },
    },
    legend: {
      position: "top",
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    annotations: {
      yaxis: [
        {
          y: revisedAllocation / 2,
          borderColor: "#009688",
          label: {
            borderColor: "#009688",
            textAnchor: "start",
            position: "left",
            style: {
              color: "#fff",
              background: "#009688",
            },
            text: "50 %",
          },
        },
      ],
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    grid: {
      padding: {
        right: 30,
        left: 20,
      },
    },
    yaxis: {
      tickAmount: 4,
      show: true,
      title: {
        text: "Perbelanjaan (RM)",
        rotate: -90,
        style: {
          cssClass: "apexcharts-yaxis-label text-uppercase",
        },
      },
      min: 0,
      max: revisedAllocation,
      labels: {
        show: true,
        align: "right",
        minWidth: 50,
        maxWidth: 70,
        style: {
          colors: [],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label text-uppercase",
        },
        formatter: (value) => {
          return numeral(value).format("0.0 a");
        },
      },
    },
    // yaxis: {
    //   show: true,
    //   title: {
    //     text: "Perbelanjaan (RM)",
    //     rotate: -90,
    //     style: {
    //       cssClass: "apexcharts-yaxis-label text-uppercase",
    //     },
    //   },
    //   labels: {
    //     show: true,
    //     align: "right",
    //     minWidth: 50,
    //     maxWidth: 70,
    //     style: {
    //       colors: [],
    //       fontSize: "12px",
    //       fontFamily: "Helvetica, Arial, sans-serif",
    //       fontWeight: 400,
    //       cssClass: "apexcharts-yaxis-label text-uppercase",
    //     },
    //     formatter: (value) => {
    //       return numeral(value).format("0.0 a");
    //     },
    //   },
    //   stepSize: revisedAllocation / 4,
    //   min: 0,
    //   max: revisedAllocation,
    // },
    xaxis: {
      categories: Array.from({ length: 12 }, (e, i) => {
        return new Date(null, i + 1, null).toLocaleDateString("ms", {
          month: "short",
        });
      }),
    },
    colors: ["#388e3c", "#303f9f", "#e64a19", "#ffb300"],
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      formatter: (value, opts) => {
        if (value === null || value === undefined) {
          return "";
        }
        const newValue = `RM ${numeral(value).format("0.0 a")}`;
        return newValue;
      },
      offsetY: -10,
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 3,
    },
  };

  useEffect(() => {
    if (isEmpty(overallExpenditure)) return;
    setSeries(overallExpenditure);
  }, [overallExpenditure]);

  return (
    <React.Fragment>
      <ExpandOverallExpenditure show={expand} onHide={() => setExpand(false)} />

      <Card
        as={motion.div}
        variants={itemLeft}
        className='flex-fill position-relative'
      >
        <div
          className='position-absolute'
          style={{ top: 16, right: 16, cursor: "pointer" }}
          onClick={() => setExpand(true)}
        >
          <Icon path={mdiArrowExpand} size='18px' />
        </div>
        <Card.Header className='pb-0'>
          <h4
            className='text-uppercase'
            style={{ fontWeight: 700, width: "95%" }}
          >
            Prestasi Perbelanjaan Keseluruhan{" "}
            {selectYear && `Tahun ${selectYear.label}`}
          </h4>
        </Card.Header>
        <Card.Body className='d-flex'>
          <div className='align-self-center w-100'>
            <div className='chart'>
              <Chart
                options={options}
                series={series}
                type='line'
                height={400}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default OverallExpenditure;
