import React from "react";
import { Button } from "react-bootstrap";

const ActionButton = ({ handleSaveDraft, sendApplication }) => {
  return (
    <React.Fragment>
      <Button variant='secondary' onClick={handleSaveDraft}>
        Draf
      </Button>

      <Button variant='success' onClick={sendApplication}>
        Hantar
      </Button>
    </React.Fragment>
  );
};

export default ActionButton;
