import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import numeral from "numeral";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const OverallFinancialReport = ({ s3pData, yearValue }) => {
  const [series, setSeries] = useState([]);

  const { project } = useSelector((state) => ({
    project: state.reportReducer.project,
  }));

  const options = {
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    labels: series.map((item) => item.label),
    colors: ["#e64a19", "#ffccbc"],
    legend: {
      position: "top",
    },
    tooltip: {
      custom: ({ seriesIndex, series, w }) => {
        return `
          <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 11px; font-weight: 700; text-transform: uppercase">
            ${w.config.labels[seriesIndex]}
          </div>
          <div class="apexcharts-tooltip-series-group" style="order: 1; display: flex; font-weight: 500; font-family: Helvetica, Arial, sans-serif">
            RM ${numeral(series[seriesIndex]).format("0,0.00")}
          </div>
         `;
      },
    },
  };

  useEffect(() => {
    if (!isEmpty(project)) {
      const initialize = () => {
        const findYear = project?.s3p_data?.find(
          (item) => item.year.toString() === yearValue.toString(),
        );

        const bendahari = findYear?.monthly_info?.reduce(
          (acc, item) => (acc += parseFloat(item.bendahari)),
          0,
        );

        if (
          !isNaN(parseFloat(findYear?.current_balance)) &&
          bendahari !== undefined
        ) {
          setSeries([
            {
              label: "PERBELANJAAN SEBENAR",
              amount: bendahari,
            },
            {
              label: "BAKI SEMASA",
              amount: parseFloat(findYear?.current_balance),
            },
          ]);
        } else {
          setSeries([]);
        }
      };

      initialize();
    }
  }, [project, yearValue]);

  return (
    <React.Fragment>
      <div className='py-3'>
        <ReactApexChart
          options={options}
          series={series?.map((item) => item.amount)}
          type='donut'
          height={360}
        />
      </div>
    </React.Fragment>
  );
};

export default OverallFinancialReport;
