import React, { useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Form, Table } from "react-bootstrap";
import numeral from "numeral";
import { useParams } from "react-router-dom";

// icons
import Icon from "@mdi/react";
import { mdiArrowRightBold } from "@mdi/js";

const ProjectComponent = () => {
  const routeParams = useParams();

  const [data, setData] = useState(null);

  const { project } = useSelector((state) => ({
    project: state.jppnReducer.application,
  }));

  useEffect(() => {
    if (routeParams?.category === "perubahan-nama-projek") {
      setData(JSON.parse(project?.project_name));
    }
  }, [project]);

  return (
    <React.Fragment>
      <div>
        <Form.Label className='fw-bold text-uppercase'>
          <span className='me-4'>3.0</span>
          {routeParams?.category !== "perubahan-nama-projek" &&
            "Komponen Projek"}
          {routeParams?.category === "perubahan-nama-projek" &&
            "Perubahan Nama Projek"}
        </Form.Label>

        {routeParams?.category === "pindahan-siling" && (
          <div className='ps-4'>
            <div className='table-responsive'>
              <Table size='sm' bordered>
                <thead className='custom-table-head text-uppercase'>
                  <tr>
                    <th className='text-center'>Bil.</th>
                    <th>Komponen Projek</th>
                    <th className='text-center'>Siling Asal (RM)</th>
                    <th className='text-center'>Siling Disemak (RM)</th>
                  </tr>
                </thead>
                <tbody>
                  {JSON.parse(project?.project_components).map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td className='text-center'>{idx + 1}</td>
                        <td>{item.cost_description}</td>
                        <td className='text-end'>
                          {numeral(item.ceiling).format("0,0.00")}
                        </td>
                        <td className='text-end'>
                          {numeral(item.revised_ceiling).format("0,0.00")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        )}

        {/* Project Name Changes */}
        {routeParams?.category === "perubahan-nama-projek" && (
          <div className='ps-4'>
            <div className='table-responsive'>
              <Table bordered size='sm' style={{ width: "100%" }}>
                <thead className='custom-table-head text-uppercase'>
                  <tr>
                    <th>Nama Projek Asal</th>
                    <th className='text-center'></th>
                    <th className='text-center'>Nama Projek Baharu </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='py-4' style={{ width: "45%" }}>
                      {data?.old}
                    </td>
                    <td className='align-center text-center'>
                      <Icon path={mdiArrowRightBold} size={1} />
                    </td>
                    <td className='py-4' style={{ width: "45%" }}>
                      {data?.new}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProjectComponent;
