import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const fetchMicroProjectUsers = createAsyncThunk(
  "microProjectUsers/fetchMicroProjectUsers",
  async (props) => {
    try {
      const { source, params } = props;
      const response = await axios.get(`/users`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const createUser = createAsyncThunk(
  "microProjectUsers/create",
  async (values) => {
    try {
      const response = await axios.post(`/users`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const getUser = createAsyncThunk(
  "microProjectUsers/getUser",
  async (id) => {
    try {
      const response = await axios.get(`/users/${id}`);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const updateUser = createAsyncThunk(
  "microProjectUsers/updateUser",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.put(`/users/${id}`, values);

      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const removeUser = createAsyncThunk(
  "microProjectUsers/removeUser",
  async (params) => {
    try {
      const { id, is_deleted } = params;
      const response = await axios.delete(`/users/${id}/delete`);

      const data = await response;

      return params;
    } catch (error) {
      console.log(error);
    }
  },
);
