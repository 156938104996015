import { initial, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Card, Container, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CurrencyInput from "react-currency-input-field";
import numeral from "numeral";
import { useParams } from "react-router-dom";
import axios from "axios";
import LoadingState from "../../../../../components/common/LoadingState";
import usePalette from "../../../../../hooks/usePalette";
import Select from "react-select";

// icons
import Icon from "@mdi/react";
import { mdiCloseCircle } from "@mdi/js";

// Redux
import {
  getProjectBasicInfo,
  setProjectComponents,
  setAddComponent,
  setUpdateComponent,
  setDeleteComponent,
} from "../../../../../redux/slices/jppn/jppnModuleSlice";
import { getProjectScope } from "../../../../../redux/slices/utilsSlice";

const ScopeChanges = ({ nextPage }) => {
  const dispatch = useDispatch();
  const palette = usePalette();
  const routeParams = useParams();

  const [options, setOptions] = useState([]);

  const {
    projectIsLoading,
    currentRmk,
    project,
    projectScopeItems,
    isLoading,
    application,
    draft,
    projectScopes,
  } = useSelector((state) => ({
    projectIsLoading: state.projectReducer.isLoading,
    currentRmk: state.rmkReducer.activeRmk,
    project: state.projectReducer.project,
    projectScopeItems: state.utilsReducer.dropdownItems.projectScopeItems,
    isLoading: state.jppnReducer.virement.isLoading,
    application: state.jppnReducer.application,
    draft: state.jppnReducer.draft,
    projectScopes: state.jppnReducer.draft.project_scopes,
  }));

  const handleAddComponent = () => {
    console.log("first");
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const props = {
      source: source,
      params: {},
    };

    const initialize = async () => {
      await dispatch(getProjectScope(props));
      await dispatch(getProjectBasicInfo(props));
    };

    initialize();

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  useEffect(() => {
    if (isEmpty(project)) return false;

    const newComponents = project?.financial_details?.map((item) => {
      const newValues = {
        ...item,
        cost_description: {
          label: item?.cost_description,
          value: item?.cost_description,
        },
      };
      return newValues;
    });

    const components = {
      old: project?.financial_details,
      new: newComponents,
    };

    dispatch(setProjectComponents(components));
  }, [project]);

  if (projectIsLoading) return <LoadingState />;

  return (
    <React.Fragment>
      <div className='mb-3'>
        <h4 className='mb-3'>Perubahan Skop</h4>
        <div className='table-responsive'>
          <Table bordered size='sm' className='mb-3'>
            <tbody>
              <tr>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Projek Kod
                </th>
                <td colSpan={4}>
                  {routeParams.type === "kemas-kini"
                    ? application?.virement_projects[0]?.project
                        ?.sub_project_full_code
                    : project?.sub_project_full_code}
                </td>
              </tr>
              <tr>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Agensi Pelaksana
                </th>
                <td colSpan={4}>
                  {routeParams.type === "kemas-kini"
                    ? `${application?.virement_projects[0]?.project?.executing_agency?.name} (${application?.virement_projects[0]?.project?.executing_agency?.short_form})`
                    : `${project?.executing_agency?.value} (${project?.executing_agency?.short_form})`}
                </td>
              </tr>
              <tr>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Nama Projek
                </th>
                <td colSpan={4}>
                  {routeParams.type === "kemas-kini"
                    ? application?.virement_projects[0]?.project?.name
                    : project?.name}
                </td>
              </tr>
              <tr>
                <th
                  className='border-end-0 align-middle'
                  style={{ width: "25%" }}
                >
                  Kategori Projek
                </th>
                <td colSpan={4}>
                  {routeParams.type === "kemas-kini"
                    ? application?.virement_projects[0]?.project
                        ?.project_category?.name
                    : project?.project_category?.value}
                </td>
              </tr>
            </tbody>
          </Table>
          <Table className='mb-3' bordered size='sm' style={{ width: "100%" }}>
            <tbody>
              <tr style={{ backgroundColor: "#e0e0e0" }}>
                <th colSpan={5}>
                  <div className='d-flex align-items-center text-uppercase'>
                    Komponen Asal
                  </div>
                </th>
              </tr>
              <tr>
                <th>Komponen</th>
                <th className='text-center'>Siling (RM)</th>
                <th className='text-center'>Tindakan</th>
              </tr>
              {projectScopes?.old?.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td style={{ width: "50%" }}>{item.cost_description}</td>
                    <td className='text-end'>
                      {numeral(item?.ceiling).format("0,0.00")}
                    </td>
                    <td />
                  </tr>
                );
              })}
              <tr style={{ backgroundColor: "#e0e0e0" }}>
                <th colSpan={5}>
                  <div className='d-flex align-items-center'>
                    <div className='flex-grow-1 text-uppercase'>
                      Komponen Baharu
                    </div>

                    <Button
                      variant='secondary'
                      onClick={() => {
                        dispatch(
                          setAddComponent({
                            cost_description: null,
                            ceiling: 0,
                            cost_required: 0,
                            revised_ceiling: 0,
                          }),
                        );
                      }}
                    >
                      + Komponen
                    </Button>
                  </div>
                </th>
              </tr>
              {projectScopes?.new?.map((item, idx) => {
                const handleSelectComponent = (obj) => {
                  const newValue = {
                    rowIdx: idx,
                    name: "cost_description",
                    value: obj,
                  };
                  dispatch(setUpdateComponent(newValue));
                };

                return (
                  <tr key={idx}>
                    <td style={{ width: "50%" }}>
                      <Select
                        name='scope-project'
                        placeholder='Pilih...'
                        className='react-select-container'
                        classNamePrefix='react-select'
                        options={projectScopeItems}
                        value={item?.cost_description}
                        onChange={handleSelectComponent}
                        noOptionsMessage={() => "Tiada Pilihan"}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </td>
                    <td className='text-end'>
                      <Form.Group>
                        <Form.Control
                          className={`text-end text-truncate`}
                          as={CurrencyInput}
                          placeholder='0.00'
                          decimalsLimit={2}
                          decimalScale={2}
                          value={item?.ceiling}
                          onValueChange={(value) => {
                            const newValue = {
                              rowIdx: idx,
                              name: "ceiling",
                              value: value || "0",
                            };
                            dispatch(setUpdateComponent(newValue));
                          }}
                        />
                      </Form.Group>
                    </td>
                    <td align='middle'>
                      <div
                        className='text-center'
                        onClick={() => dispatch(setDeleteComponent(idx))}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon
                          path={mdiCloseCircle}
                          size='22px'
                          color={palette.danger}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className='d-flex justify-content-end mt-3'>
          <Button onClick={nextPage}>Seterusnya</Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ScopeChanges;
