import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";
import { setUploadProgress } from "./microProjectSlices";

export const fetchMicroProjects = createAsyncThunk(
  "microProjects/fetchMicroProjects",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(`/micro-projects`, {
        params,
        cancelToken: source.token,
      });
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const createMicroProject = createAsyncThunk(
  "microProjects/createMicroProject",
  async (values) => {
    const response = await axios.post(`/micro-projects`, values);

    const data = await response.data;

    return data;
  },
);

export const getMicroProject = createAsyncThunk(
  "microProjects/getMicroProject",
  async (id) => {
    const response = await axios.get(`/micro-projects/${id}`);

    const data = await response.data;

    return data;
  },
);

export const updateMicroProject = createAsyncThunk(
  "microProjects/updateMicroProject",
  async (values) => {
    const { id } = values;

    const response = await axios.put(`/micro-projects/${id}`, values);

    const data = await response.data;

    return data;
  },
);

export const deleteMicroProject = createAsyncThunk(
  "microProjects/deleteMicroProject",
  async (id) => {
    const response = await axios.delete(`/micro-projects/${id}`);

    const data = await response.data;

    return id;
  },
);

export const returnApplication = createAsyncThunk(
  "microProjects/returnApplication",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.put(
        `/micro-project/return-application/${id}`,
        values,
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const fetchMicroProjectAnalysisTable = createAsyncThunk(
  "microProjects/fetchMicroProjectAnalysisTable",
  async (params) => {
    try {
      const response = await axios.get(`/micro-projects/analysis-table`, {
        params,
      });
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const storeMicroProjectAttachment = createAsyncThunk(
  "reports/storeMicroProjectAttachment",
  async (values, { dispatch }) => {
    const { id, temp_id } = values;
    try {
      const response = await axios.post(
        `/micro-project/store-attachment/${id}`,
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            dispatch(setUploadProgress({ fileId: temp_id, progress: percent }));
          },
        },
      );

      const data = await response.data.data;

      const result = {
        ...data,
        temp_id: temp_id,
        uploading: false,
      };

      return result;
    } catch (error) {
      console.error(error);
    }
  },
);

export const previewMicroProjectAttachments = createAsyncThunk(
  "reports/previewMicroProjectAttachments",
  async (props) => {
    try {
      const { source, id } = props;

      const response = await axios.get(
        `/micro-project/preview-attachments/${id}`,
        { cancelToken: source.token },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const removeMicroProjectAttachment = createAsyncThunk(
  "reports/removeMicroProjectAttachment",
  async (id) => {
    try {
      const response = await axios.delete(
        `/micro-project/remove-attachment/${id}`,
      );

      const data = await response.data;

      return id;
    } catch (error) {
      console.log(error);
    }
  },
);

export const getProjectsApproval = createAsyncThunk(
  "microProjects/getProjectsApproval",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(`/micro-project/get-projects-approval`, {
        params,
        cancelToken: source.token,
      });
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getApplicationStatistics = createAsyncThunk(
  "microProjects/getApplicationStatistics",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(
        `/micro-project/application-statistics`,
        {
          params,
          cancelToken: source.token,
        },
      );
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getApprovedProjects = createAsyncThunk(
  "microProjects/dashboard/getApprovedProjects",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(
        `/micro-project/dashboard/get-approved-projects`,
        {
          params,
          cancelToken: source.token,
        },
      );
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getApprovedProjectsByDistricts = createAsyncThunk(
  "microProjects/dashboard/getApprovedProjectsByDistricts",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(
        `/micro-project/dashboard/get-approved-projects-by-districts`,
        {
          params,
          cancelToken: source.token,
        },
      );
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getApprovedProjectsByDun = createAsyncThunk(
  "microProjects/dashboard/getApprovedProjectsByDun",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(
        `/micro-project/dashboard/get-approved-projects-by-dun`,
        {
          params,
          cancelToken: source.token,
        },
      );
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getApprovedProjectsByParliaments = createAsyncThunk(
  "microProjects/dashboard/getApprovedProjectsByParliaments",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(
        `/micro-project/dashboard/get-approved-projects-by-parliaments`,
        {
          params,
          cancelToken: source.token,
        },
      );
      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);
