import React, { useEffect, useRef, useState } from "react";
import {
  useFormik,
  FieldArray,
  Form as FormikForm,
  Formik,
  Field,
  ErrorMessage,
  useFormikContext,
} from "formik";
import { Button, Form } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import usePalette from "../../../../../hooks/usePalette";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import numeral from "numeral";

// Icons
import Icon from "@mdi/react";
import { MdCancel } from "react-icons/md";

const CustomInputComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => (
  <Form.Control
    {...field}
    {...props}
    type='text'
    as='textarea'
    style={{ minHeight: 60, maxHeight: 85 }}
  />
);

const Purpose = () => {
  const formRef = useRef();
  const palette = usePalette();
  const routeParams = useParams();

  const { values, submitForm } = useFormikContext();

  const [additionalCeiling, setAdditionalCeiling] = useState(0);
  const [initialCeilingRmk, setInitialCeilingRmk] = useState(0);
  const [revisedCeilingRmk, setRevisedCeilingRmk] = useState(0);

  const {
    project,
    currentRmk,

    draft,
  } = useSelector((state) => ({
    currentRmk: state.rmkReducer.activeRmk,
    project: state.projectReducer.project,

    draft: state.jppnReducer.draft,
  }));

  useEffect(() => {
    if (isEmpty(draft)) return null;

    const sumAdditionalCeiling = draft?.project_components
      ?.flatMap((item) => item.ceiling_flow_details)
      .reduce((acc, item) => (acc += parseFloat(item.additional_ceiling)), 0);

    setAdditionalCeiling(sumAdditionalCeiling);

    const sumInitialCeilingRmk = draft?.project_components?.reduce(
      (acc, item) => (acc += parseFloat(item.ceiling)),
      0,
    );
    const sumRevisedCeilingRmk = draft?.project_components?.reduce(
      (acc, item) => (acc += parseFloat(item.revised_ceiling)),
      0,
    );

    setInitialCeilingRmk(sumInitialCeilingRmk);
    setRevisedCeilingRmk(
      parseFloat(sumInitialCeilingRmk) + parseFloat(sumAdditionalCeiling),
    );
  }, [draft]);

  return (
    <React.Fragment>
      <div>
        <Form.Label className='fw-bold text-uppercase'>
          <span className='me-4'>1.0</span>Tujuan
        </Form.Label>

        {routeParams.category === "penyenaraian-projek-baharu" && (
          <div className='d-flex flex-row ps-4'>
            <div className='me-3'>1.1</div>
            <p>
              {`Kertas Kerja ini bertujuan untuk mendapatkan pertimbangan dan kelulusan daripada Jawatankuasa Perancang Pembangunan Negeri (JPPN) ke atas permohonan untuk menyenaraikan projek baharu yang bertajuk ${project?.name} dibawah 
           ${currentRmk?.name} (${currentRmk?.short_form})`}
            </p>
          </div>
        )}

        {routeParams.category === "pindahan-siling" && (
          <div className='d-flex flex-row ps-4'>
            <div className='me-3'>1.1</div>
            <p>
              {`Kertas Kerja ini bertujuan untuk mendapatkan pertimbangan dan kelulusan daripada Jawatankuasa Perancang Pembangunan Negeri (JPPN) ke atas permohonan untuk menambah siling peruntukan 
           ${currentRmk?.name} (${currentRmk?.short_form}) sebanyak `}
              {additionalCeiling !== 0 ? (
                <span>RM{numeral(additionalCeiling).format("0,0")}</span>
              ) : (
                <span className='fst-italic' style={{ fontWeight: 700 }}>
                  sila masukkan nilai tambahan siling
                </span>
              )}
              {` iaitu daripada RM${numeral(0).format(
                "0,0",
              )} kepada RM${numeral(revisedCeilingRmk).format("0,0")} bagi ${
                project?.name
              } (${project?.program?.prefix}${project?.ministry?.code} ${
                project?.program?.code
              } ${project?.main_project?.code}${project?.code}) dibawah ${
                project?.ministry?.value
              } (${project?.ministry?.short_form}).`}
            </p>
          </div>
        )}

        {/* <FieldArray name='purpose'>
        {({ insert, remove, push }) => (
          <React.Fragment>
            {values.purpose.map((item, index) => (
              <div key={index} className='d-flex flex-row ps-4'>
                <div className='me-3'>1.{index + 1}</div>
                <p>
                  {`Kertas Kerja ini bertujuan untuk mendapatkan pertimbangan dan kelulusan daripada Jawatankuasa Perancang Pembangunan Negeri (JPPN) ke atas permohonan untuk menambah siling peruntukan 
                    ${currentRmk?.name}
                   (${currentRmk?.short_form}) sebanyak `}
                  {ceilingIncrement !== 0 ? (
                    <span>RM{numeral(ceilingIncrement).format("0,0.00")}</span>
                  ) : (
                    <span className='fst-italic' style={{ fontWeight: 700 }}>
                      sila masukkan nilai tambahan siling
                    </span>
                  )}
                  {` iaitu daripada SILING_ASAL kepada JUMLAH_SILING_BAHARU bagi ${project?.name} (${project?.program?.prefix}${project?.ministry?.code} ${project?.program?.code} ${project?.main_project?.code}${project?.code}) dibawah ${project?.ministry?.value} (${project?.ministry?.short_form}).`}
                </p>
              </div>
            ))}
            <div className='d-flex justify-content-center'>
              <Button
                size='sm'
                variant='secondary'
                onClick={() => push({ description: "" })}
              >
                + Tujuan
              </Button>
            </div>
          </React.Fragment>
        )}
      </FieldArray> */}
      </div>
    </React.Fragment>
  );
};

export default Purpose;
