import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import numeral from "numeral";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";

// Components
import SourceOfCeiling from "./tables/SourceOfCeiling";

const SourceOfFinancial = () => {
  const routeParams = useParams();

  const { category } = routeParams;

  const [data, setData] = useState(null);
  const [parentIndex, setParentIndex] = useState(0);

  const { application } = useSelector((state) => ({
    application: state.jppnReducer.application,
  }));

  useEffect(() => {
    const parsedData = JSON.parse(application.source_of_financial);
    const regex = /RM(\d+(\.\d+)?)/g;
    const formatTextWithCurrency = parsedData?.description_one?.replace(
      regex,
      (match, p1) => `RM ${numeral(p1).format("0,0.00")}`,
    );

    const newValues = {
      ...parsedData,
      description_one: formatTextWithCurrency,
    };

    setData(newValues);
  }, [application]);

  useEffect(() => {
    if (category === "pindahan-siling") {
      setParentIndex(5);
    } else {
      setParentIndex(4);
    }
  }, [category]);

  return (
    <React.Fragment>
      <div className='mb-4'>
        <div className='mb-4'>
          <Form.Label className='fw-bold text-uppercase'>
            <span className='me-4'>{parentIndex}.0</span>
            Cadangan Sumber Kewangan
          </Form.Label>

          <div className='d-flex flex-row ps-4'>
            <div className='me-3'>{parentIndex}.1</div>
            <p>{data?.description_one}</p>
          </div>

          <div className='d-flex ps-4'>
            <div className='me-3'>{parentIndex}.2</div>
            <p className='mb-0'>{data?.description_two}</p>
          </div>
        </div>
        <SourceOfCeiling />

        <div className='d-flex ps-4 mt-3'>
          <div className='me-3'>{parentIndex}.3</div>
          <p className='mb-0'>{data?.description_three}</p>
        </div>
        <div className='d-flex ps-4 mt-3'>
          <div className='me-3'>{parentIndex}.4</div>
          <p className='mb-0'>
            <p className='mb-0'>{data?.description_four}</p>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SourceOfFinancial;
