import React, { useEffect, useState } from "react";
import { Badge, Tab, Table } from "react-bootstrap";
import numeral from "numeral";
import usePalette from "../../../../../hooks/usePalette";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

// icons
import Icon from "@mdi/react";
import { mdiCheckCircle, mdiPencil } from "@mdi/js";

// Pagination
import TablePagination from "./TablePagination";

const EditProjectName = ({ eventKey }) => {
  const navigate = useNavigate();
  const palette = usePalette();

  const { applications } = useSelector((state) => ({
    applications: state.jppnReducer.projectNameChangesApplications,
  }));

  const handleViewApplication = (item) => {
    const { id } = item;

    navigate(`/perakuan/jppn/perubahan-nama-projek/${id}`);
  };
  return (
    <React.Fragment>
      <Tab.Pane eventKey={eventKey}>
        <h4 className='mb-3'>Senarai Permohonan Untuk Mengubah Nama Projek.</h4>
        <div className='table-responsive'>
          <Table size='sm' bordered>
            <thead className='custom-table-head text-uppercase'>
              <tr>
                <th className='text-center'>Bil.</th>
                <th className='text-center'>No. Rujukan</th>
                <th style={{ width: 350 }}>Nama Projek</th>
                <th>Dikomen Oleh UPEN</th>
                <th className='text-center' style={{ width: 150 }}>
                  Status Kelulusan
                </th>
                <th className='text-center'>Tindakan</th>
              </tr>
            </thead>
            <tbody>
              {isEmpty(applications) && (
                <tr>
                  <td colSpan={7} className='text-center'>
                    Tiada maklumat tersedia
                  </td>
                </tr>
              )}
              {!isEmpty(applications) && (
                <>
                  {applications?.map((item, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <tr>
                          <td className='text-center'>{idx + 1}</td>
                          <td
                            className='text-center'
                            style={{ fontWeight: 700 }}
                          >
                            {item.reference_no}
                          </td>
                          <td>
                            {item.project?.name ||
                              "Projek terlibat adalah berikut:"}
                          </td>
                          <td className='text-center'>
                            {!isEmpty(item?.upen_comments) && (
                              <Icon
                                path={mdiCheckCircle}
                                size='18px'
                                color={palette.success}
                              />
                            )}
                          </td>
                          <td className='text-center px-4'>
                            {item?.is_approved && (
                              <Icon
                                path={mdiCheckCircle}
                                size='18px'
                                color={palette.success}
                              />
                            )}
                          </td>
                          <td className='text-center'>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => handleViewApplication(item)}
                            >
                              <Icon
                                path={mdiPencil}
                                color={palette.primary}
                                size='20px'
                              />
                            </div>
                          </td>
                        </tr>
                        {item.virement_projects && (
                          <>
                            {item.virement_projects.map((item, subIdx) => {
                              let operator = "";

                              if (item.virement_type === "VI") {
                                operator = "(+)";
                              } else {
                                operator = "(-)";
                              }
                              return (
                                <tr key={subIdx}>
                                  <td className='text-center'>
                                    {idx + 1}.{subIdx + 1}
                                  </td>
                                  <td />
                                  <td>{item?.project?.name}</td>
                                  <td className='text-center'>
                                    {item?.virement_type}
                                  </td>
                                  <td className='text-end'>
                                    {`${operator} ${numeral(
                                      item?.total_virement_amount,
                                    ).format("0,0.00")}`}
                                  </td>
                                  <td colSpan={4}></td>
                                </tr>
                              );
                            })}
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                </>
              )}
            </tbody>
          </Table>
          <TablePagination />
        </div>
      </Tab.Pane>
    </React.Fragment>
  );
};

export default EditProjectName;
