import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingState from "../../../../components/common/LoadingState";
import numeral from "numeral";
import axios from "axios";

// components
import VirementInformation from "./components/VirementInformation";
import PageContent from "./PageContent";
import ScopeChanges from "./components/ScopeChanges";
import ProjectNameChanges from "./components/ProjectNameChanges";

// redux
import { fetchProjectDetails } from "../../../../redux/slices/projects/projectSlice";
import {
  setResetForm,
  setPurpose,
} from "../../../../redux/slices/jppn/jppnModuleSlice";
import { getVirementApplication } from "../../../../redux/slices/jppn/jppnCeilingVirementSlice";
import { getProjectNameChangesApplication } from "../../../../redux/slices/jppn/jppnProjectNameChanges";

const MainPage = () => {
  const dispatch = useDispatch();
  const routeParams = useParams();

  const [page, setPage] = useState(1);

  const { currentRmk, projectIsLoading, project, draft } = useSelector(
    (state) => ({
      currentRmk: state.rmkReducer.activeRmk,
      projectIsLoading: state.projectReducer.isLoading,
      project: state.projectReducer.project,

      draft: state.jppnReducer.draft,
    }),
  );

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePrevPage = () => {
    setPage(page - 1);
  };

  const handleResetForm = async () => {
    await dispatch(setResetForm());
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    handleResetForm();

    const { id, type } = routeParams;

    if (type === "kemas-kini") {
      const props = {
        source: source,
        id: id,
      };

      if (routeParams.category === "pindahan-siling") {
        dispatch(getVirementApplication(props));
      }
      if (routeParams.category === "perubahan-nama-projek") {
        dispatch(getProjectNameChangesApplication(props));
      }
    } else {
      const props = {
        source: source,
        project_id: id,
      };
      dispatch(fetchProjectDetails(props));
    }

    if (routeParams.category === "pindahan-siling") {
      const additionalCeiling = draft?.project_components
        ?.flatMap((item) => item.ceiling_flow_details)
        .reduce(
          (acc, item) => (acc += parseFloat(item?.additional_ceiling)),
          0,
        );

      dispatch(
        setPurpose(
          `Kertas Kerja ini bertujuan untuk mendapatkan pertimbangan dan kelulusan daripada Jawatankuasa Perancang Pembangunan Negeri (JPPN) ke atas permohonan untuk menambah siling peruntukan ${
            currentRmk?.name
          } (${currentRmk?.short_form}) sebanyak RM${numeral(
            additionalCeiling,
          ).format("0,0")} iaitu daripada RM${numeral(0).format(
            "0,0",
          )} kepada RM${numeral(0).format("0,0")} bagi ${
            project?.name
          } dibawah ${project?.ministry?.value} (${
            project?.ministry?.short_form
          }).`,
        ),
      );
    }
    if (routeParams.category === "penyenaraian-projek-baharu") {
      dispatch(
        setPurpose(
          `Kertas Kerja ini bertujuan untuk mendapatkan pertimbangan dan kelulusan daripada Jawatankuasa Perancang Pembangunan Negeri (JPPN) ke atas permohonan untuk menyenaraikan projek baharu ${project?.name} dibawah ${currentRmk?.name} (${currentRmk?.short_form})`,
        ),
      );
    }
    if (routeParams.category === "perubahan-skop") {
      dispatch(
        setPurpose(
          `Kertas Kerja ini bertujuan untuk mendapatkan pertimbangan dan kelulusan daripada Jawatankuasa Perancang Pembangunan Negeri (JPPN) ke atas permohonan untuk perubahan skop ${project?.name} dibawah ${currentRmk?.name} (${currentRmk?.short_form})`,
        ),
      );
    }

    return () => {
      source.cancel();
    };
  }, [dispatch, routeParams]);

  // if (projectIsLoading) return <LoadingState />;

  return (
    <React.Fragment>
      <Container fluid className='p-0'>
        <Card>
          <Card.Header className='d-flex justify-content-center pb-2'>
            <Card.Title className='fs-4 w-50 text-center text-uppercase'>
              Kerajaan Negeri Sabah Jawatan Kuasa Perancang Pembangunan Negeri
              <br />
              (Kertas Kerja BIL._/{new Date().getFullYear()})
            </Card.Title>
          </Card.Header>
          <Card.Header className='d-flex justify-content-center pb-3 pt-0'>
            {routeParams.category === "penyenaraian-projek-baharu" && (
              <Card.Title className='fs-4 w-50 text-center'>
                <span className='text-uppercase me-1'>
                  Permohonan Penyenaraian Projek Baharu
                </span>
                <span className='me-1 text-uppercase'>{currentRmk.name}</span>
                <span className='me-1 text-truncate'>
                  ({currentRmk.short_form})
                </span>
              </Card.Title>
            )}
            {routeParams.category === "pindahan-siling" && (
              <Card.Title className='fs-4 w-50 text-center'>
                <span className='text-uppercase me-1'>
                  Permohonan Menambah Siling Peruntukan Dibawah
                </span>
                <span className='me-1 text-uppercase'>{currentRmk.name}</span>
                <span className='me-1 text-truncate'>
                  ({currentRmk.short_form})
                </span>
              </Card.Title>
            )}
          </Card.Header>
          <Card.Body className='px-5'>
            {page === 1 && (
              <>
                {routeParams.category === "pindahan-siling" && (
                  <VirementInformation nextPage={handleNextPage} />
                )}
                {routeParams.category === "perubahan-skop" && (
                  <ScopeChanges nextPage={handleNextPage} />
                )}
                {routeParams.category === "perubahan-nama-projek" && (
                  <ProjectNameChanges nextPage={handleNextPage} />
                )}
              </>
            )}
            {page === 2 && <PageContent prevPage={handlePrevPage} />}
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
