import React, { useRef } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import numeral from "numeral";
import CurrencyInput from "react-currency-input-field";
import { useParams } from "react-router-dom";

// icons
import Icon from "@mdi/react";
import { mdiArrowRightBold } from "@mdi/js";

// redux
import { setRevisedCeiling } from "../../../../../redux/slices/jppn/jppnModuleSlice";

const ProjectComponent = () => {
  const dispatch = useDispatch();
  const routeParams = useParams();

  const { projectComponents, projectNameChanges, projectScopes } = useSelector(
    (state) => ({
      projectComponents: state.jppnReducer.draft.project_components,
      projectNameChanges: state.jppnReducer.draft.project_name,
      projectScopes: state.jppnReducer.draft.project_scopes,
    }),
  );

  return (
    <React.Fragment>
      <div className='mb-3'>
        <Form.Label className='fw-bold text-uppercase'>
          <span className='me-4'>3.0</span>
          {routeParams?.category !== "perubahan-nama-projek" &&
            "Komponen Projek"}
          {routeParams?.category === "perubahan-nama-projek" &&
            "Perubahan Nama Projek"}
        </Form.Label>

        {/* Ceiling Virement */}
        {routeParams?.category === "pindahan-siling" && (
          <div className='ps-4'>
            <div className='table-responsive'>
              <Table bordered size='sm'>
                <thead className='custom-table-head text-uppercase'>
                  <tr>
                    <th className='text-center'>Bil.</th>
                    <th>Komponen Projek</th>
                    <th className='text-center'>Siling Asal (RM)</th>
                    <th className='text-center'>Siling Disemak (RM)</th>
                    <th className='text-center'>Penambahan Siling (RM)</th>
                  </tr>
                </thead>
                <tbody>
                  {projectComponents?.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td className='text-center'>{idx + 1}.</td>
                        <td>{item.cost_description}</td>
                        <td className='text-end'>
                          {numeral(item?.ceiling).format("0,0.00")}
                        </td>
                        <td className='text-end'>
                          {numeral(item?.revised_ceiling).format("0,0.00")}
                        </td>
                        <td className='text-end'>
                          {numeral(item?.additional_ceiling).format("0,0.00")}
                          {/* <Form.Group>
                            <Form.Control
                              className={`text-end text-truncate`}
                              as={CurrencyInput}
                              placeholder='0.00'
                              decimalsLimit={2}
                              decimalScale={2}
                              value={
                                item?.additional_ceiling
                              }
                              onValueChange={(value) => {
                                const newValues = {
                                  rowId: item.id,
                                  additional_ceiling: value,
                                };
                                dispatch(setRevisedCeiling(newValues));
                              }}
                            />
                          </Form.Group> */}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td />
                    <td>Jumlah</td>
                    <td className='text-end'>
                      {numeral(
                        projectComponents?.reduce(
                          (acc, item) => (acc += parseFloat(item?.ceiling)),
                          0,
                        ),
                      ).format("0,0.00")}
                    </td>
                    <td className='text-end'>
                      {numeral(
                        projectComponents?.reduce(
                          (acc, item) =>
                            (acc += parseFloat(item?.revised_ceiling)),
                          0,
                        ),
                      ).format("0,0.00")}
                    </td>
                    <td className='text-end'>
                      {numeral(
                        projectComponents?.reduce(
                          (acc, item) =>
                            (acc += parseFloat(item?.additional_ceiling)),
                          0,
                        ),
                      ).format("0,0.00")}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        )}

        {/* Project Scope Changes */}
        {routeParams?.category === "perubahan-skop" && (
          <div style={{ paddingLeft: 36, paddingRight: 14 }}>
            <Row>
              <Col className=' p-0'>
                <div className='table-responsive'>
                  <Table size='sm' bordered style={{ width: "100%" }}>
                    <thead className='text-uppercase'>
                      <tr>
                        <th className='text-center'>Bil.</th>
                        <th style={{ width: "60%" }}>Komponen Asal</th>
                        <th className='text-center' style={{ width: "35%" }}>
                          Siling (RM)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className='text-center'>1</td>
                        <td></td>
                        <td className='text-end pe-2'>11</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col className=' p-0'>
                <div className='table-responsive'>
                  <Table size='sm' bordered style={{ width: "100%" }}>
                    <thead className='text-uppercase'>
                      <tr>
                        <th className='text-center'>Bil.</th>
                        <th style={{ width: "60%" }}>Komponen Asal</th>
                        <th className='text-center' style={{ width: "35%" }}>
                          Siling (RM)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className='text-center'>1</td>
                        <td></td>
                        <td className='text-end pe-2'>11</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
        )}

        {/* Project Name Changes */}
        {routeParams?.category === "perubahan-nama-projek" && (
          <div className='ps-4'>
            <div className='table-responsive'>
              <Table bordered size='sm'>
                <thead className='custom-table-head text-uppercase'>
                  <tr>
                    <th>Nama Projek Asal</th>
                    <th className='text-center'></th>
                    <th className='text-center'>Nama Projek Baharu </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='py-4'>{projectNameChanges?.old}</td>
                    <td className='align-center text-center'>
                      <Icon path={mdiArrowRightBold} size={1} />
                    </td>
                    <td className='py-4'>{projectNameChanges?.new}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProjectComponent;
