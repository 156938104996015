import React, { useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";

const MOFComments = () => {
  const routeParams = useParams();

  const { category } = routeParams;

  const [parentIndex, setParentIndex] = useState(0);

  const { application } = useSelector((state) => ({
    application: state.jppnReducer.application,
  }));

  useEffect(() => {
    if (category === "pindahan-siling") {
      setParentIndex(7);
    } else {
      setParentIndex(6);
    }
  }, [category]);

  return (
    <React.Fragment>
      <div className='mb-4'>
        <Form.Label className='fw-bold text-uppercase'>
          <span className='me-4'>{parentIndex}.0</span>
          Komen Kementerian Kewangan Negeri Sabah
        </Form.Label>
        {JSON.parse(application?.mof_comments)?.map((item, idx) => {
          return (
            <div key={idx} className='d-flex flex-row ps-4'>
              <div className='me-3'>
                {parentIndex}.{idx + 1}
              </div>
              <p>{item?.comment}</p>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default MOFComments;
