import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const ApprovalButton = ({
  project,
  returnApplication,
  user,
  hideSendButton,
  hideApproveButton,
}) => {
  return (
    <React.Fragment>
      {/* <Button variant='danger' onClick={returnApplication}>
        Kembalikan Permohonan
      </Button> */}

      {!isEmpty(user?.user_access_to_district) && hideSendButton && (
        <Button variant='success' type='submit'>
          Hantar
        </Button>
      )}
      {!isEmpty(user?.user_access_to_ministries) && hideApproveButton && (
        <Button variant='success' type='submit'>
          Lulus
        </Button>
      )}
    </React.Fragment>
  );
};

export default ApprovalButton;
