import React, { useEffect } from "react";
import { Card, Container, Nav, Tab } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import axios from "axios";

// Components
import Filters from "./components/Filters";
// tab pane
import CeilingVirement from "./components/tab-content/CeilingVirement";
import EditProjectName from "./components/tab-content/EditProjectName";

// redux
import { getVirementApplications } from "../../../redux/slices/jppn/jppnCeilingVirementSlice";
import { getNewProjectList } from "../../../redux/slices/jppn/jppnNewProjectListingSlice";
import { getProjectNameChangesApplications } from "../../../redux/slices/jppn/jppnProjectNameChanges";

const MainPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let source = axios.CancelToken.source();

    const initialize = async () => {
      const props = {
        source: source,
      };
      dispatch(getVirementApplications(props));
      dispatch(getNewProjectList(props));
      dispatch(getProjectNameChangesApplications(props));
    };

    initialize();

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Helmet title='Perakuan JPPN | Senarai Projek' />

      <Container fluid className='p-0'>
        <div className='mb-4'>
          <h4>Senarai permohonan JPPN</h4>
        </div>
        <div className='tab'>
          <Tab.Container defaultActiveKey='ceiling-virement'>
            <Nav variant='tabs' fill>
              <Nav.Item>
                <Nav.Link eventKey='ceiling-virement'>Kenaikan Siling</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='edit-project-name'>Ubah Tajuk</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <CeilingVirement eventKey='ceiling-virement' />
              <EditProjectName eventKey='edit-project-name' />
            </Tab.Content>
          </Tab.Container>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
