import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import LoadingState from "../../components/common/LoadingState";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { isEmpty } from "lodash";

// Content
import PageContent from "./PageContent";

// Redux
import {
  setSelectYear,
  setIsLoading,
} from "../../redux/slices/dashboard/dashboardSlice";
import {
  getFinancialReport,
  getFinancialReportByMinistries,
  getFinancialReportByDepartments,
  getOverallExpenditure,
  getProjectsProgressStatus,
  getProjects,
  getFinancialReportByPrograms,
  getFinancialReportByMainProjects,
} from "../../redux/slices/dashboard/overallReportSlice";
import {
  getCombineReportProjectsProgressStatus,
  getCombineReportProgressStatusByMinistries,
  getCombineReportProgressStatusByDepartments,
  getCombineReportProgressStatusByPrograms,
  getCombineReportProgressStatusByMainProjects,
} from "../../redux/slices/dashboard/combineReportSlice";
import { getRmkItems } from "../../redux/slices/utilsSlice";

const MainPage = () => {
  const dispatch = useDispatch();

  const [yearOptions, setYearOptions] = useState([]);

  const [rmkValue, setRmkValue] = useState(null);

  const {
    isLoading,
    user,
    currentRmk,
    selectYear,
    selectMinistry,
    selectDepartment,
    selectProgram,
    navFilter,
  } = useSelector((state) => ({
    isLoading: state.rmkReducer.isLoading,
    currentRmk: state.rmkReducer.activeRmk,
    user: state.userReducer.user,
    selectYear: state.dashboardReducer.selectYear,
    selectMinistry: state.dashboardReducer.selectMinistry,
    selectDepartment: state.dashboardReducer.selectDepartment,
    selectProgram: state.dashboardReducer.selectProgram,
    navFilter: state.dashboardReducer.navFilter,
  }));

  const handleChangeRmk = (value) => {
    setYearOptions(null);
    dispatch(setSelectYear(null));

    const { first_year, second_year, third_year, fourth_year, fifth_year } =
      value;

    const newArray = [
      {
        label: fifth_year,
        value: fifth_year,
      },
      {
        label: fourth_year,
        value: fourth_year,
      },
      {
        label: third_year,
        value: third_year,
      },
      {
        label: second_year,
        value: second_year,
      },
      {
        label: first_year,
        value: first_year,
      },
    ];

    setYearOptions(newArray);
    setRmkValue(value);
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const props = {
      source: source,
      params: {
        rmk_id: rmkValue?.number,
        year: selectYear?.value,
        ministry_id: selectMinistry,
        department_id: selectDepartment,
        program_id: selectProgram,
      },
    };

    const initialize = async () => {
      dispatch(setIsLoading(true));
      dispatch(getFinancialReport(props));
      dispatch(getOverallExpenditure(props));

      if (navFilter === "ministry") {
        dispatch(getFinancialReportByMinistries(props));
      }
      if (navFilter === "department") {
        dispatch(getFinancialReportByDepartments(props));
      }
      if (navFilter === "program") {
        dispatch(getFinancialReportByPrograms(props));
      }
      if (navFilter === "main-project") {
        dispatch(getFinancialReportByMainProjects(props));
      }

      await dispatch(getProjectsProgressStatus(props));
      await dispatch(getProjects(props));
      await dispatch(getCombineReportProjectsProgressStatus(props));
      await dispatch(getCombineReportProgressStatusByMinistries(props));
      await dispatch(getCombineReportProgressStatusByDepartments(props));
      await dispatch(getCombineReportProgressStatusByPrograms(props));
      await dispatch(getCombineReportProgressStatusByMainProjects(props));

      dispatch(setIsLoading(false));
    };

    if (!isEmpty(rmkValue)) {
      initialize();
    }

    return () => {
      source.cancel();
    };
  }, [dispatch, rmkValue, selectYear, navFilter]);

  useEffect(() => {
    if (!isEmpty(currentRmk)) {
      setRmkValue(currentRmk);
      const { first_year, second_year, third_year, fourth_year, fifth_year } =
        currentRmk;

      const newArray = [
        {
          label: fifth_year,
          value: fifth_year,
        },
        {
          label: fourth_year,
          value: fourth_year,
        },
        {
          label: third_year,
          value: third_year,
        },
        {
          label: second_year,
          value: second_year,
        },
        {
          label: first_year,
          value: first_year,
        },
      ];

      setYearOptions(newArray);
    }
  }, [currentRmk]);

  useEffect(() => {
    let source = axios.CancelToken.source();

    const props = {
      source: source,
    };

    dispatch(getRmkItems(props));

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Helmet title='Dashboard' />

      <Container fluid className='p-0'>
        {isLoading ? (
          <LoadingState />
        ) : (
          <PageContent
            changeRmk={handleChangeRmk}
            rmkValue={rmkValue}
            yearOptions={yearOptions}
          />
        )}
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
