import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";

// redux
import { returnApplication } from "../../../../redux/slices/micro-project/microProjectSlice";

const ReturnApplicationForm = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const navigate = useNavigate();
  const { id } = routeParams;

  const handleSubmit = (e) => {
    e.preventDefault();
    formik.handleSubmit();
    return false;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      return_application_remarks: "",
    },
    onSubmit: async (values) => {
      const newValues = {
        id: id,
        return_application_remarks: values?.return_application_remarks,
      };
      await dispatch(returnApplication(newValues));
      navigate("/projek-mikro/status-senarai-projek");
    },
  });

  return (
    <Modal show={show} onHide={onHide} size='sm' centered>
      <Modal.Body>
        <Form className='mb-3'>
          <h5>Adakah anda pasti untuk menolak permohonan ini?</h5>
          <p>Sila nyatakan sebab-sebab permohonan ini ditolak.</p>
          <Form.Group>
            <Form.Control
              name='return_application_remarks'
              type='text'
              as='textarea'
              value={formik.values?.return_application_remarks}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{ height: 80, maxHeight: 120 }}
            />
          </Form.Group>
        </Form>
        <div className='d-flex justify-content-end gap-3'>
          <Button variant='light' onClick={onHide}>
            Batal
          </Button>
          <Button variant='danger' onClick={handleSubmit}>
            Tolak
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReturnApplicationForm;
