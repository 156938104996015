import React, { useRef } from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";

const Purpose = () => {
  const routeParams = useParams();

  const { application } = useSelector((state) => ({
    application: state.jppnReducer.application,
  }));

  return (
    <React.Fragment>
      <Form.Label className='fw-bold text-uppercase'>
        <span className='me-4'>1.0</span>
        Tujuan
      </Form.Label>

      {routeParams.category === "penyenaraian-projek-baharu" && (
        <div className='d-flex flex-row ps-4'>
          <div className='me-3'>1.1</div>
          <p>{JSON.parse(application?.purpose)}</p>
        </div>
      )}

      {routeParams.category === "pindahan-siling" && (
        <div className='d-flex flex-row ps-4'>
          <div className='me-3'>1.1</div>
          <p>{JSON.parse(application?.purpose)}</p>
        </div>
      )}
    </React.Fragment>
  );
};

export default Purpose;
