import React, { useEffect, useState } from "react";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { Card } from "react-bootstrap";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import ms from "date-fns/locale/ms";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { format } from "date-fns";

const ProjectTimeline = () => {
  const [components, setComponents] = useState([]);
  const [progressPercentage, setProgressPercentage] = useState([]);

  const { project } = useSelector((state) => ({
    project: state.reportReducer.project,
  }));

  const options = {
    chart: {
      type: "rangeBar",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          hideOverflowingLabels: false,
        },
        barHeight: "60%",
      },
    },
    progressPercentage: progressPercentage,
    dataLabels: {
      enabled: true,
      formatter: (val, opts) => {
        const label = opts.w.globals.labels[opts.dataPointIndex];
        const scopeProgress =
          opts.w.config.progressPercentage[opts.dataPointIndex];

        const result = formatDistanceStrict(val[0], val[1], {
          locale: ms,
          roundingMethod: "floor",
          unit: "month",
        });

        if (opts.seriesIndex === 1) {
          return `${result} (${scopeProgress} %)`;
        } else {
          return result;
        }
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        minWidth: 140,
        maxWidth: 260,
        style: {
          fontSize: "9.5px",
          cssClass: "apexcharts-yaxis-label text-uppercase",
        },
      },
    },
    grid: {
      row: {
        opacity: 1,
      },
    },
    legend: {
      position: "top",
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    colors: ["#607D8B", "#039BE5"],
    tooltip: {
      custom: ({ seriesIndex, dataPointIndex, w }) => {
        const { x, y } = w.config.series[seriesIndex].data[dataPointIndex];
        const startDate = format(new Date(y[0]), "dd/MM/yyyy");
        const endDate = format(new Date(y[1]), "dd/MM/yyyy");
        return `
          <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 11px; font-weight: 700; text-transform: uppercase">
            ${w.config.series[seriesIndex].name}
          </div>
          <div class="apexcharts-tooltip-series-group" style="order: 1; display: flex; font-weight: 500; font-family: Helvetica, Arial, sans-serif">
            Mula: ${startDate} - : ${endDate}
          </div>
         `;
      },
    },
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted && !isEmpty(project)) {
      const { financial_details } = project;

      const expectedDate = financial_details.every((item) =>
        isEmpty(item.project_scope_details),
      )
        ? []
        : financial_details.map((item) => {
            if (isEmpty(item.project_scope_details)) {
              return null;
            }

            return {
              x: item.cost_description,
              y: [
                new Date(
                  item.project_scope_details?.expected_start_date,
                ).getTime(),
                new Date(
                  item.project_scope_details?.expected_completion_date,
                ).getTime(),
              ],
            };
          });

      const actualDate = financial_details
        .map((item) => {
          const { project_scope_details, project_scope_progress_reports } =
            item;
          let startDate = project_scope_details?.actual_start_date;
          let completionDate = project_scope_details?.actual_completion_date;

          const latestReport =
            project_scope_progress_reports[
              project_scope_progress_reports?.length - 1
            ];

          if (isEmpty(project_scope_details?.actual_completion_date)) {
            completionDate = latestReport?.sent_at || null;
          } else {
            completionDate = null;
          }

          const result = {
            x: item.cost_description,
            y: [
              new Date(item.project_scope_details?.actual_start_date).getTime(),
              new Date(completionDate).getTime(),
            ],
          };

          if (!isEmpty(project_scope_details?.actual_start_date)) {
            return result;
          }
          return null;
        })
        .filter((item) => !isEmpty(item));

      const data = [
        {
          name: "Tempoh Dijangka",
          data: expectedDate,
        },
        {
          name: "Tempoh Sebenar",
          data: actualDate,
        },
      ];

      if (data.every((item) => isEmpty(item.data))) {
        setComponents([]);
      } else {
        setComponents(data);
      }

      const latestProgress = financial_details.map((item) => {
        const { project_scope_progress_reports } = item;
        const latestReport =
          project_scope_progress_reports[
            project_scope_progress_reports?.length - 1
          ];
        return parseFloat(latestReport?.progress).toFixed(2);
      });

      setProgressPercentage(latestProgress);
    }

    return () => {
      isMounted = false;
    };
  }, [project]);

  return (
    <Card className='flex-fill w-100'>
      <Card.Header className='text-center'>
        <h4 className='mb-0 text-uppercase'>Tempoh Pelaksanaan Projek</h4>
      </Card.Header>
      <Card.Body className='px-2 pt-0'>
        {isEmpty(components) ? (
          <div className='d-flex flex-column mb-3 align-items-center'>
            <div className='text-center'>
              Maklumat tidak tersedia
              <ul className='ps-1 mt-2'>
                <li>Maklumat tarikh perlaksanaan skop projek tidak diisi.</li>
              </ul>
            </div>
          </div>
        ) : (
          <div id='chart'>
            <ReactApexChart
              series={components}
              options={options}
              type='rangeBar'
              height={350}
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default ProjectTimeline;
