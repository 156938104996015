import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";

const ReturnApplication = () => {
  const [content, setContent] = useState("");

  const { project } = useSelector((state) => ({
    project: state.microProjectReducer.microProject,
  }));

  useEffect(() => {
    if (isEmpty(project)) return false;

    const remarks = JSON.parse(project?.return_application_remarks);

    setContent(remarks);
  }, [project]);

  return (
    <React.Fragment>
      <div className='mb-3'>
        <h5 style={{ color: "red" }}>Sebab-sebab permohonan di tolak.</h5>
        <Form.Group>
          <Form.Control
            type='text'
            as='textarea'
            defaultValue={content}
            style={{ height: 80, maxHeight: 120 }}
            disabled
          />
        </Form.Group>
      </div>
    </React.Fragment>
  );
};

export default ReturnApplication;
