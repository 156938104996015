import React, { useEffect, useState } from "react";
import {
  View,
  Page,
  Text,
  Image,
  Document,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import { Table, TR, TH, TD } from "@ag-media/react-pdf-table";
import { Button } from "react-bootstrap";

// Styles
import { styles } from "./pdfStyles";
import { useSelector } from "react-redux";
import numeral from "numeral";

const MyDocument = ({
  project,
  virementSource,
  currentRmk,
  ceilingIncrement,
  projectCode,
  content,
  initialCeiling,
  currentCeiling,
  revisedCeiling,
}) => {
  const currentYear = new Date().getFullYear();

  return (
    <Document>
      <Page size='A4' style={styles.body}>
        <Text style={styles.confidential} fixed>
          Sulit
        </Text>
        <View style={styles.headerSection}>
          <Text style={styles.header}>KERAJAAN NEGERI SABAH</Text>
          <Text style={styles.header}>
            JAWATANKUASA PERANCANG PEMBANGUNAN NEGERI
          </Text>
          <Text style={styles.header}>(KERTAS KERJA BIL.__/{currentYear})</Text>
        </View>
        <View style={styles.mainTitleSection}>
          <Text style={styles.header}>
            PERMOHONAN PENYENARAIAN PROJEK BAHARU{" "}
            <Text style={{ textTransform: "uppercase" }}>
              {currentRmk?.name}
            </Text>{" "}
            ({currentRmk?.short_form})
          </Text>
        </View>
        <View style={styles.titleSection}>
          <Text style={styles.indexing}>1.0</Text>
          <Text style={styles.title}>Tujuan</Text>
        </View>
        {content?.purpose && (
          <View style={styles.contentSection}>
            <Text style={styles.contentIndexing}>{`1.1`}</Text>
            <Text style={styles.text}>{content?.purpose}</Text>
          </View>
        )}

        <View style={styles.titleSection}>
          <Text style={styles.indexing}>2.0</Text>
          <Text style={styles.title}>Latar Belakang</Text>
        </View>
        {content?.background?.map((item, idx) => (
          <View style={styles.contentSection}>
            <Text style={styles.contentIndexing}>{`2.${idx + 1}`}</Text>
            <Text style={styles.text}>{item?.description}</Text>
          </View>
        ))}

        <View style={styles.titleSection}>
          <Text style={styles.indexing}>3.0</Text>
          <Text style={styles.title}>Komponen Projek</Text>
        </View>
        {content?.project_component?.map((item, idx) => (
          <View style={styles.contentSection}>
            <Text style={styles.contentIndexing}>{`3.${idx + 1}`}</Text>
            <Text style={styles.text}>{item?.description}</Text>
          </View>
        ))}

        <View style={styles.titleSection}>
          <Text style={styles.indexing}>4.0</Text>
          <Text style={styles.title}>Justifikasi</Text>
        </View>
        {content?.justification?.map((item, idx) => (
          <React.Fragment>
            <View style={styles.contentSection}>
              <Text style={styles.contentIndexing}>{`4.${idx + 1}`}</Text>
              <Text style={styles.text}>{item?.description}</Text>
            </View>
            {item?.children.map((child, subIdx) => (
              <View style={styles.contentSubSection}>
                <Text style={styles.contentIndexing}>
                  {`5.${idx + 1}.${idx + 1}`}
                </Text>
                <Text style={styles.text}>{item?.description}</Text>
              </View>
            ))}
          </React.Fragment>
        ))}

        <Text style={styles.confidentialBottom}>Sulit</Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber}`}
          fixed
        />
      </Page>
    </Document>
  );
};

const ViewPaperWork = () => {
  const [ceilingRequested, setCeilingRequested] = useState(0);
  const [projectCode, setProjectCode] = useState("");

  const {
    project,
    currentRmk,
    ceilingIncrement,
    virementSource,
    draft,

    initialCeiling,
    currentCeiling,
    revisedCeiling,
  } = useSelector((state) => ({
    project: state.projectReducer.project,
    currentRmk: state.rmkReducer.activeRmk,
    virementSource: state.jppnCeilingVirementReducer.virement.financialSource,
    ceilingIncrement:
      state.jppnCeilingVirementReducer.virement.ceilingIncrement,
    draft: state.jppnCeilingVirementReducer.virement.draft,

    initialCeiling: state.jppnCeilingVirementReducer.virement.to.initialCeiling,
    currentCeiling: state.jppnCeilingVirementReducer.virement.to.currentCeiling,
    revisedCeiling: state.jppnCeilingVirementReducer.virement.to.revisedCeiling,
  }));

  useEffect(() => {
    const value = numeral(ceilingIncrement).format("0,0.00");
    setCeilingRequested(value);

    const code = `(${project?.program?.prefix}${project?.ministry_id} ${project?.program?.code} ${project?.main_project?.code}${project?.code})`;

    setProjectCode(code);
  }, [ceilingIncrement, project]);

  return (
    <React.Fragment>
      {/* <PDFDownloadLink document={<PDFFile />} fileName={`test.pdf`}>
        {({ blob, url, loading, error }) => (
          <Button variant='secondary' disabled={loading}>
            Test
          </Button>
        )}
      </PDFDownloadLink> */}
      <PDFViewer width='100%' height='100%'>
        <MyDocument
          project={project}
          currentRmk={currentRmk}
          ceilingIncrement={ceilingRequested}
          projectCode={projectCode}
          virementSource={virementSource}
          content={draft}
          initialCeiling={initialCeiling}
          currentCeiling={currentCeiling}
          revisedCeiling={revisedCeiling}
        />
      </PDFViewer>
    </React.Fragment>
  );
};

export default ViewPaperWork;
