import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import MapView from "@arcgis/core/views/MapView";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import WebMap from "@arcgis/core/WebMap";
import Search from "@arcgis/core/widgets/Search";

import IdentityManager from "@arcgis/core/identity/IdentityManager";
import OAuthInfo from "@arcgis/core/identity//OAuthInfo";

const WebMapArcgis = () => {
  const mapRef = useRef(null);
  let view = useRef(null);

  const {
    searchProgressStatus,
    user,
    selectMinistry,
    selectDepartment,
    selectProgram,
  } = useSelector((state) => ({
    user: state.userReducer.user,
    searchProgressStatus: state.dashboardReducer.searchProgressStatus,
    selectMinistry: state.dashboardReducer.selectMinistry,
    selectDepartment: state.dashboardReducer.selectDepartment,
    selectProgram: state.dashboardReducer.selectProgram,
  }));

  useEffect(() => {
    const info = new OAuthInfo({
      appId: "TEvYHd3nhPCZbW5Q", // Replace with your client ID
      portalUrl: "https://smepps-geo.sabah.gov.my/arcgis",
      popup: false,
    });

    IdentityManager.registerOAuthInfos([info]);

    const clusterConfig = {
      type: "cluster",
      clusterRadius: "100px",
      clusterMinSize: "24px",
      clusterMaxSize: "40px",
      popupTemplate: {
        title: "Ringkasan Kluster",
        content: "Kluster ini mempunyai {cluster_count} projek.",
        fieldInfos: [
          {
            fieldName: "cluster_count",
            format: {
              places: 0,
              digitSeparator: true,
            },
          },
        ],
      },
      labelingInfo: [
        {
          deconflictionStrategy: "none",
          labelExpressionInfo: {
            expression: "Text($feature.cluster_count, '#,###')",
          },
          symbol: {
            type: "text",
            color: "#fafafa",
            font: {
              weight: "bold",
              family: "Noto Sans",
              size: "12px",
            },
          },
          labelPlacement: "center-center",
        },
      ],
    };

    const popupProjectInfo = {
      title: "{name}",
      content: [
        {
          type: "fields",
          fieldInfos: [
            {
              fieldName: "name",
              label: "Nama Projek",
              visible: true,
            },
          ],
        },
      ],
    };

    const rendererPoint = {
      type: "simple",
      symbol: {
        type: "picture-marker",
        url: "http://static.arcgis.com/images/Symbols/Basic/BlackStickpin.png",
        width: "20px",
        height: "20px",
      },
    };

    IdentityManager.getCredential(info.portalUrl + "/sharing")
      .then((credential) => {
        const districtLayer = new FeatureLayer({
          url: "https://services7.arcgis.com/imUjhC2BWfjR1bCA/arcgis/rest/services/SbhDistrictBdy/FeatureServer/0",
        });

        // Create a FeatureLayer with the authenticated token
        const pointLayer = new FeatureLayer({
          url: "https://smepps-geo.sabah.gov.my/arcmanager/rest/services/vw_projects/FeatureServer",
          // renderer: rendererPoint,
          popupTemplate: popupProjectInfo,
          featureReduction: clusterConfig,
          outFields: ["name"],
          credential: credential,
        });

        const accessToMinistries = user.user_access_to_ministries;
        const accessToDepartments = user.user_access_to_executing_agencies;

        const getDefinitionExpression = (ministryId, progressStatus) => {
          let expression = "";

          if (!isEmpty(accessToDepartments)) {
            const departments = accessToMinistries
              .map((item) => `department_id = ${item.id}`)
              .join(" OR ");
            expression += `${departments}`;
          }
          if (!isEmpty(accessToMinistries)) {
            const ministries = accessToMinistries
              .map((item) => `ministry_id = ${item.code}`)
              .join(" OR ");
            expression += `${ministries}`;
          }

          if (searchProgressStatus) {
            if (expression) {
              expression += " AND ";
            }
            expression += `progress_status = ${searchProgressStatus}`;
          }

          if (selectMinistry) {
            if (expression) {
              expression += " AND ";
            }
            expression += `ministry_id = ${selectMinistry}`;
          }
          if (selectDepartment) {
            if (expression) {
              expression += " AND ";
            }
            expression += `department_id = ${selectDepartment}`;
          }
          if (selectProgram) {
            if (expression) {
              expression += " AND ";
            }
            expression += `program_id = ${selectProgram}`;
          }

          return expression;
        };

        const expression = getDefinitionExpression();

        pointLayer.definitionExpression = expression;

        const webMap = new WebMap({
          basemap: "hybrid", // Replace with your WebMap ID
          layers: [districtLayer, pointLayer],
        });

        const view = new MapView({
          container: mapRef.current,
          map: webMap,
          center: [117.017156, 5.489928],
          scale: 10000000,
          zoom: 6,
        });

        const searchWidget = new Search({
          view: view,
          allPlaceholder: "Daerah",
          includeDefaultSources: false,
          sources: [
            {
              layer: districtLayer,
              searchFields: ["Dist_Name"],
              displayField: "Dist_Name",
              exactMatch: false,
              placeholder: "cth: Kota Kinabalu",
            },
          ],
        });

        view.ui.add(searchWidget, {
          position: "top-right",
          index: 2,
        });
      })
      .catch((error) => {
        console.error("Authentication failed: ", error);
      });

    return () => {
      if (view.current) {
        view.current.container = null;
      }
    };
  }, [searchProgressStatus, selectMinistry, selectDepartment, selectProgram]);

  return (
    <div
      ref={mapRef}
      style={{ height: "100%", width: "100%", padding: 0, margin: 0 }}
    />
  );
};

export default WebMapArcgis;
