import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { current } from "immer";
import axios from "../../../utils/axios";

import {
  getVirementApplications,
  getVirementApplication,
  deleteVirementApplication,
} from "./jppnCeilingVirementSlice";
// import { getNewProjectApplication } from "./jppnNewProjectListingSlice";
import {
  getProjectNameChangesApplications,
  getProjectNameChangesApplication,
  deleteEditNameApplication,
} from "./jppnProjectNameChanges";

export const fetchProjects = createAsyncThunk(
  "jppn/fetchProjects",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(`jppn/projects/get`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getProjectBasicInfo = createAsyncThunk(
  "jppn/getProjectBasicInfo",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(`jppn/project-basic-info/get`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getVirementSource = createAsyncThunk(
  "jppn/getVirementSource",
  async (props) => {
    try {
      const { params, index } = props;

      const response = await axios.get(`jppn/virement-source/get`, {
        params,
      });

      const data = await response.data;

      const contexts = {
        data: data,
        index: index,
      };
      return contexts;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

const initialState = {
  virement: {
    isLoading: true,
    virementOutProjects: [],
    inputField: {
      notes: "",
    },
  },
  newProjectListing: {
    applications: [],
    projects: [],
  },
  draft: {
    purpose: "",
    background: [
      {
        description: "",
      },
    ],
    project_components: [],
    project_name: {
      old: "",
      new: "",
    },
    project_scopes: {
      old: [],
      new: [],
    },
    ceiling_requirement: {
      initial_ceiling: 0,
      additional_ceiling: 0,
      total_new_ceiling: 0,
      notes: "",
    },
    justification: [
      {
        description: "",
        children: [],
      },
    ],
    source_of_financial: {
      from: {
        project_name: "",
        initial_ceiling: 0,
        current_ceiling: 0,
        ceiling_changes: 0,
        revised_ceiling: 0,
      },
      to: {
        project_name: "",
        initial_ceiling: 0,
        current_ceiling: 0,
        ceiling_changes: 0,
        revised_ceiling: 0,
      },
    },
    ministry_comments: null,
    mof_comments: null,
    upen_comments: null,
    closing: null,
  },
  virementApplications: [],
  projectNameChangesApplications: [],
  application: null,
  projects: {
    data: [],
    meta: null,
    links: null,
    isLoading: false,
    page: 1,
    perPage: 30,
    keywords: "",
  },
};

export const jppnModuleSlice = createSlice({
  name: "jppn",
  initialState,
  reducers: {
    setCreateDraft: (state, action) => {
      state.draft = action.payload;
    },

    setPurpose: (state, action) => {
      state.draft.purpose = action.payload;
    },
    setProjectComponents: (state, action) => {
      // state.draft.project_scopes = action.payload;
      state.draft.project_components = action.payload;
    },
    setAddComponent: (state, action) => {
      state.draft.project_scopes.new = [
        ...state.draft.project_scopes.new,
        action.payload,
      ];
    },
    setUpdateComponent: (state, action) => {
      const { rowIdx, name, value } = action.payload;
      state.draft.project_scopes.new = state.draft.project_scopes.new.map(
        (item, idx) => {
          if (idx === rowIdx) {
            let newItem = null;
            if (name === "ceiling") {
              newItem = {
                ...item,
                ceiling: value,
                cost_required: value,
                revised_ceiling: value,
              };
            } else {
              newItem = {
                ...item,
                [name]: value,
              };
            }

            return newItem;
          }
          return item;
        },
      );
    },
    setDeleteComponent: (state, action) => {
      const rowIdx = action.payload;
      state.draft.project_scopes.new = state.draft.project_scopes.new?.filter(
        (item, idx) => idx !== rowIdx,
      );
    },
    setNewProjectName: (state, action) => {
      const { name, value } = action.payload;

      state.draft.project_name[name] = value;
    },
    setRevisedCeiling: (state, action) => {
      const { rowId, additional_ceiling } = action.payload;

      state.draft.project_components = state.draft.project_components.map(
        (item) => {
          if (item.id === rowId) {
            const additionalCeiling = parseFloat(additional_ceiling) || 0;
            const currentRevisedCeiling =
              parseFloat(item.current_revised_ceiling) || 0;

            const newRevisedCeiling = currentRevisedCeiling + additionalCeiling;

            return {
              ...item,
              revised_ceiling: newRevisedCeiling,
              additional_ceiling: additional_ceiling,
            };
          }
          return item;
        },
      );
    },
    setNotes: (state, action) => {
      state.virement.inputField.notes = action.payload;
    },
    setPage: (state, action) => {
      state.projects.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.projects.perPage = action.payload;
    },
    setKeywords: (state, action) => {
      state.projects.keywords = action.payload;
    },
    setResetForm: (state) => initialState,
    setVirementOutProjects: (state, action) => {
      const project = action.payload;

      state.virement.virementOutProjects = [
        ...state.virement.virementOutProjects,
        project,
      ];
    },
    changeVirementOutAmount: (state, action) => {
      const { parentIdx, childIdx, virement_out_amount } = action.payload;

      state.virement.virementOutProjects =
        state.virement.virementOutProjects.map((item, idx) => {
          if (idx === parentIdx) {
            const updatedFinancialDetails = item.financial_details.map(
              (subItem, subIdx) => {
                if (subIdx === childIdx) {
                  return {
                    ...subItem,
                    virement_out_amount: virement_out_amount,
                  };
                }
                return subItem;
              },
            );

            return {
              ...item,
              financial_details: updatedFinancialDetails,
            };
          }
          return item;
        });
    },
    removeVirementOutProject: (state, action) => {
      state.virement.virementOutProjects =
        state.virement.virementOutProjects.filter(
          (item, idx) => idx !== action.payload,
        );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjects.pending, (state) => {
      state.projects = {
        ...state.projects,
        isLoading: true,
      };
    });
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      const { data, meta, links } = action.payload;

      state.projects = {
        ...state.projects,
        data: data,
        meta: meta,
        links: links,
      };

      state.projects.isLoading = false;
    });

    builder.addCase(getProjectBasicInfo.fulfilled, (state, action) => {
      state.projects.data = action.payload;
    });

    builder.addCase(getVirementSource.pending, (state, action) => {
      state.virement.isLoading = true;
    });
    builder.addCase(getVirementSource.fulfilled, (state, action) => {
      const { data, index } = action.payload;
      state.virement.isLoading = false;

      state.virement.virementOutProjects =
        state.virement.virementOutProjects.map((project, idx) => {
          if (idx === index) {
            const newItem = {
              ...data,
              financial_details: data?.financial_details?.map((scope) => {
                return {
                  ...scope,
                  virement_out_amount: 0,
                };
              }),
            };

            return newItem;
          }
          return project;
        });
    });

    // Virement Ceiling
    builder.addCase(getVirementApplications.fulfilled, (state, action) => {
      state.virementApplications = action.payload;
    });

    builder.addCase(getVirementApplication.fulfilled, (state, action) => {
      state.application = action.payload;

      const {
        background,
        project_components,
        ceiling_requirement,
        justification,
        source_of_financial,
        purpose,
        ministry_comments,
        mof_comments,
        upen_comments,
        closing,
        virement_projects,
      } = action.payload;

      state.draft = {
        project_components: JSON.parse(project_components),
        background: JSON.parse(background),
        ceiling_requirement: JSON.parse(ceiling_requirement),
        justification: JSON.parse(justification),
        source_of_financial: JSON.parse(source_of_financial),
        purpose: JSON.parse(purpose),
        ministry_comments: JSON.parse(ministry_comments),
        mof_comments: JSON.parse(mof_comments),
        upen_comments: JSON.parse(upen_comments),
        closing: JSON.parse(closing),
      };

      const virementOutList = virement_projects?.filter(
        (item) => item.virement_type === "VO",
      );

      const virementOutProjects = virementOutList?.map((item) => {
        const virementDetails = item.virement_details;

        const project = item.project;

        const newItem = {
          ...project,
          financial_details: project?.financial_details?.map((obj) => {
            const matchRow = virementDetails.find(
              (vire) => vire.financial_detail_id === obj?.id,
            );

            return {
              ...obj,
              virement_out_amount: matchRow?.virement_amount,
            };
          }),
        };

        return newItem;
      });

      state.virement.virementOutProjects = virementOutProjects;
    });

    builder.addCase(deleteVirementApplication.fulfilled, (state, action) => {
      const id = action.payload;
      state.virementApplications = state.virementApplications.filter(
        (item) => item.id.toString() !== id.toString(),
      );
    });

    // Project Name Changes
    builder.addCase(
      getProjectNameChangesApplications.fulfilled,
      (state, action) => {
        state.projectNameChangesApplications = action.payload;
      },
    );

    builder.addCase(
      getProjectNameChangesApplication.fulfilled,
      (state, action) => {
        state.application = action.payload;

        const {
          background,
          project_name,
          justification,
          purpose,
          ministry_comments,
          upen_comments,
          closing,
        } = action.payload;

        state.draft = {
          project_name: JSON.parse(project_name),
          background: JSON.parse(background),
          justification: JSON.parse(justification),
          purpose: JSON.parse(purpose),
          ministry_comments: JSON.parse(ministry_comments),
          upen_comments: JSON.parse(upen_comments),
          closing: JSON.parse(closing),
        };
      },
    );

    builder.addCase(deleteEditNameApplication.fulfilled, (state, action) => {
      const id = action.payload;
      state.projectNameChangesApplications =
        state.projectNameChangesApplications.filter(
          (item) => item.id.toString() !== id.toString(),
        );
    });
  },
});

export const {
  setVirementOutProjects,
  changeVirementOutAmount,
  removeVirementOutProject,
  setCreateDraft,
  setPurpose,
  setNewProjectName,
  setProjectComponents,
  setAddComponent,
  setUpdateComponent,
  setDeleteComponent,
  setRevisedCeiling,
  setNotes,
  setKeywords,
  setPage,
  setPerPage,
  setResetForm,
} = jppnModuleSlice.actions;

export default jppnModuleSlice.reducer;
