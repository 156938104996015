import React, { useEffect, useState } from "react";
import numeral from "numeral";
import { Card, ProgressBar, Tab, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { format } from "date-fns";
import { ms } from "date-fns/locale";

// Icons
import Icon from "@mdi/react";
import { mdiCurrencyUsd, mdiArrowRightBottomBold } from "@mdi/js";

const ProjectFinancial = ({ eventKey }) => {
  const [monthlyData, setMonthlyData] = useState([]);
  const [financialSummartPreviousRmk, setFinancialSummartPreviousRmk] =
    useState([]);
  const [financialSummaryByYears, setFinancialSummaryByYears] = useState([]);
  const [monthlyTransaction, setMonthlyTransaction] = useState({
    jumlah_bendahari: 0,
    jumlah_terkini: 0,
    jumlah_lejar: 0,
  });

  const { project, currentRmk } = useSelector((state) => ({
    project: state.projectReducer.project,
    currentRmk: state.rmkReducer.activeRmk,
  }));

  useEffect(() => {
    if (isEmpty(project)) return;
    const data = project?.s3p_data?.find(
      (item) => item.year === new Date().getFullYear(),
    );
    const reports = project.reports;
    const overallFinancialByYears = project?.s3p_data;

    setMonthlyData(data?.monthly_info);

    const bendahari = data?.monthly_info?.reduce(
      (acc, item) => (acc += parseFloat(item.bendahari)),
      0,
    );
    const terkini = data?.monthly_info?.reduce(
      (acc, item) => (acc += parseFloat(item.terkini)),
      0,
    );
    const lejar = data?.monthly_info?.reduce(
      (acc, item) => (acc += parseFloat(item.lejar)),
      0,
    );

    const total = {
      jumlah_bendahari: bendahari,
      jumlah_terkini: terkini,
      jumlah_lejar: lejar,
    };

    setMonthlyTransaction(total);

    const { first_year, second_year, third_year, fourth_year, fifth_year } =
      project?.rmk;

    const years = [
      first_year,
      second_year,
      third_year,
      fourth_year,
      fifth_year,
    ];

    const sentReports = reports.filter((item) => item.is_sent === true);

    const getOverallFinancialByYears = (years) => {
      return years.map((year) => {
        const matchingData = overallFinancialByYears.find(
          (item) => item.year === year,
        );
        const agencyExpenditure = sentReports
          .filter((item) => item.year === year)
          .reduce(
            (acc, obj) =>
              (acc += parseFloat(obj.total_expenses_report_by_agency || 0)),
            0,
          );
        return {
          year: matchingData?.year || year,
          data: matchingData ? matchingData : null,
          agencyExpenditure: agencyExpenditure,
        };
      });
    };

    const matchedData = getOverallFinancialByYears(years);

    setFinancialSummaryByYears(matchedData);
  }, [project.s3p_data]);

  return (
    <Tab.Pane eventKey={eventKey}>
      <div className='mb-3'>
        <h5 className='text-uppercase'>Maklumat Kewangan RMK Lepas</h5>
        <div className='table-responsive'>
          <Table bordered size='sm' style={{ width: "100%" }}>
            <thead
              className='custom-table-head text-uppercase'
              style={{ fontSize: 11 }}
            >
              <tr>
                <th className='text-center' style={{ width: "5%" }}>
                  Bil.
                </th>
                <th className='text-center' style={{ width: "10%" }}>
                  RMK
                </th>
                <th className='text-center'>
                  <div>Peruntukan Disemak</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>Perbelanjaan Sebenar (Elai)</div>
                  <div>(RM)</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isEmpty(financialSummartPreviousRmk) ? (
                <tr>
                  <td colSpan={4} className='text-center'>
                    Tiada maklumat tersedia
                  </td>
                </tr>
              ) : (
                <tr>
                  <td className='text-center'>-</td>
                  <td className='text-center'>-</td>
                  <td className='text-end'>{numeral(0).format("0,0.00")}</td>
                  <td className='text-end'>{numeral(0).format("0,0.00")}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>

      <div className='mb-3'>
        <h5 className='text-uppercase'>
          Ringkasan Kewangan Tahunan RMK Semasa (RMK
          <span className='text-lowercase'>e</span>-{project?.rmk?.number})
        </h5>
        <div className='table-responsive'>
          <Table bordered size='sm' style={{ width: "100%" }}>
            <thead
              className='custom-table-head text-uppercase'
              style={{ fontSize: 11 }}
            >
              <tr>
                <th className='text-center' style={{ width: "5%" }}>
                  Bil.
                </th>
                <th className='text-center' style={{ width: "10%" }}>
                  Tahun
                </th>
                <th className='text-center'>
                  <div>Peruntukan Asal MOF</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>Peruntukan Disemak MOF</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>Laporan Jabatan</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>Laporan S3P</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>Laporan Terkini</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>
                    Laporan E<span className='text-lowercase'>lai</span>
                  </div>
                  <div>(RM)</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {financialSummaryByYears?.map((item, idx) => {
                const { data } = item;

                const sumBendahari =
                  data?.monthly_info?.reduce(
                    (acc, month) => (acc += parseFloat(month.bendahari)),
                    0,
                  ) || 0;
                const sumTerkini =
                  data?.monthly_info?.reduce(
                    (acc, month) => (acc += parseFloat(month.terkini)),
                    0,
                  ) || 0;
                const sumLejar =
                  data?.monthly_info?.reduce(
                    (acc, month) => (acc += parseFloat(month.lejar)),
                    0,
                  ) || 0;

                return (
                  <tr key={item.year}>
                    <td className='text-center'>{idx + 1}</td>
                    <td className='text-center'>{item.year}</td>
                    <td className='text-end'>
                      {numeral(data?.state_fund || 0).format("0,0.00")}
                    </td>
                    <td className='text-end'>{numeral(0).format("0,0.00")}</td>
                    <td className='text-end'>
                      {numeral(item.agencyExpenditure).format("0,0.00")}
                    </td>
                    <td className='text-end'>
                      {numeral(sumBendahari).format("0,0.00")}
                    </td>
                    <td className='text-end'>
                      {numeral(sumTerkini).format("0,0.00")}
                    </td>
                    <td className='text-end'>
                      {numeral(sumLejar).format("0,0.00")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>

      <div className='mb-3'>
        <h5 className='text-uppercase'>Transaksi Perbelanjaan Bulanan</h5>
        <div className='table-responsive'>
          <Table bordered size='sm' style={{ width: "100%" }}>
            <thead
              className='custom-table-head text-uppercase'
              style={{ fontSize: 11 }}
            >
              <tr>
                <th className='text-center' style={{ width: "5%" }}>
                  Bulan
                </th>
                <th className='text-center'>
                  <div>Laporam Perbelanjaan : Agensi</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>Perbelanjaan : S3P</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>Perbelanjaan : Terkini</div>
                  <div>(RM)</div>
                </th>
                <th className='text-center'>
                  <div>Perbelanjaan Sebenar : E-Lejar</div>
                  <div>(RM)</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {monthlyData
                ?.toSorted((a, b) => a.month - b.month)
                ?.map((item, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <tr>
                        <td className='text-center text-uppercase'>
                          {item.month}
                        </td>
                        <td className='text-end'>
                          {numeral(0).format("0,0.00")}
                        </td>
                        <td className='text-end'>
                          {numeral(item.bendahari).format("0,0.00")}
                        </td>
                        <td className='text-end'>
                          {numeral(item.terkini).format("0,0.00")}
                        </td>
                        <td className='text-end'>
                          {numeral(item.lejar).format("0,0.00")}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              <tr>
                <td className='text-center text-truncate text-uppercase'>
                  Jumlah (RM)
                </td>
                <td className='text-end'>{numeral(0).format("0,0.00")}</td>
                <td className='text-end'>
                  {numeral(monthlyTransaction.jumlah_bendahari).format(
                    "0,0.00",
                  )}
                </td>
                <td className='text-end'>
                  {numeral(monthlyTransaction.jumlah_terkini).format("0,0.00")}
                </td>
                <td className='text-end'>
                  {numeral(monthlyTransaction.jumlah_lejar).format("0,0.00")}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </Tab.Pane>
  );
};

export default ProjectFinancial;
