import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Badge, Card, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import HTMLReactParser from "html-react-parser";
import { isEmpty } from "lodash";
import usePalette from "../../../hooks/usePalette";
import { useParams } from "react-router-dom";
import * as DOMPurify from "dompurify";

import MaintenanceImg from "../../../assets/img/illustrations/system_maintenance.webp";
import AnnoucementImg from "../../../assets/img/illustrations/annoucement.webp";
import ModalDelete from "../../../components/common/ModalDelete";
import LoadingState from "../../../components/common/LoadingState";

// Icons
import Icon from "@mdi/react";
import { mdiDotsVertical, mdiTrashCanOutline, mdiPencil } from "@mdi/js";

// Redux
import { removeAnnouncement } from "../../../redux/slices/admin/announcementsSlice";

const Content = ({ handleEditAnnouncement }) => {
  const palette = usePalette();
  const dispatch = useDispatch();

  const [showRemove, setShowRemove] = useState(false);
  const [data, setData] = useState(null);

  const { announcements, btnLoading, isLoading } = useSelector((state) => ({
    announcements: state.announcementsReducer.announcements,
    btnLoading: state.announcementsReducer.btnLoading,
    isLoading: state.announcementsReducer.isLoading,
  }));

  const handleRemoveAnnouncement = (item) => {
    setData(item);
    setShowRemove(true);
  };

  const handleConfirmRemove = async (item) => {
    const { uuid } = item;
    await dispatch(removeAnnouncement(uuid));
    setShowRemove(false);
  };

  return (
    <React.Fragment>
      <ModalDelete
        showRemove={showRemove}
        closeRemove={() => {
          setData(null);
          setShowRemove(false);
        }}
        confirmRemove={handleConfirmRemove}
        title={data?.title || ""}
        data={data}
        message='Andakah anda pasti untuk padam?'
        btnLoading={btnLoading}
      />
      {isLoading ? (
        <LoadingState />
      ) : (
        <>
          {isEmpty(announcements) ? (
            <Card className='bg-secondary text-white'>
              <Card.Body>
                <Card.Text>Tiada pengumuman setakat ini.</Card.Text>
              </Card.Body>
            </Card>
          ) : (
            <>
              {announcements.map((item, idx) => {
                const badgeType =
                  format(new Date(item.showed_at), "yyyy-MM-dd") ===
                  format(new Date(), "yyyy-MM-dd")
                    ? "primary"
                    : "secondary";
                const badgeText =
                  format(new Date(item.showed_at), "yyyy-MM-dd") ===
                  format(new Date(), "yyyy-MM-dd")
                    ? "baru"
                    : "tamat";

                let bgColor = "bg-info";

                switch (item.type) {
                  case 1:
                    bgColor = "bg-secondary";
                    break;

                  case 2:
                    bgColor = "bg-warning";
                    break;

                  default:
                    break;
                }

                const body = DOMPurify.sanitize(item.content);

                return (
                  <Card key={idx} style={{ maxWidth: 600, width: 600 }}>
                    <Card.Header className={`${bgColor} position-relative`}>
                      <Card.Title className='text-uppercase text-white'>
                        {item.title}
                      </Card.Title>
                      <div
                        className='position-absolute'
                        style={{ top: 15, right: 15 }}
                      >
                        <Dropdown align='start'>
                          <Dropdown.Toggle as='a' bsPrefix='-'>
                            <Icon
                              path={mdiDotsVertical}
                              size={1}
                              color='#FFF'
                              style={{ cursor: "pointer" }}
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => handleEditAnnouncement(item)}
                            >
                              <Icon path={mdiPencil} size='20px' />
                              <span className='ms-2'>Ubah</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              style={{ color: palette.danger }}
                              onClick={() => handleRemoveAnnouncement(item)}
                            >
                              <Icon path={mdiTrashCanOutline} size='20px' />
                              <span className='ms-2'>Padam</span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Card.Header>
                    <Card.Body
                      className='px-5'
                      style={{ backgroundColor: "#eceff1" }}
                    >
                      <Card style={{ backgroundColor: "#b3e5fc" }}>
                        <Card.Body>
                          <div className='d-flex justify-content-end mb-3'>
                            <Badge bg={badgeType}>
                              <span className='text-uppercase mx-4'>
                                {badgeText}
                              </span>
                            </Badge>
                          </div>
                          <div className='mb-3'>
                            <div className='mb-4'>
                              {!isEmpty(item.content)
                                ? parse(body)
                                : "Tiada maklumat tersedia"}
                            </div>
                          </div>
                          <div className='d-flex  justify-content-end text-sm'>
                            <div>
                              <span>Tarikh :</span>
                              <span className='ms-1'>
                                {format(new Date(item.showed_at), "dd-MM-yyyy")}
                              </span>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Card.Body>
                  </Card>
                );
              })}
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default Content;
